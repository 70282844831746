import React, { memo } from "react";
import { useTheme } from "@mui/material/styles";
import { BoxProps } from "@mui/material/Box";
//
import { Player } from "@lottiefiles/react-lottie-player";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

function OrderCompleteIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const playerStyle = {
    width: "40vw",
    height: "40vh",
    marginTop: -20,
    marginBottom: -20,
    ...(isMdDown && {
      width: "60vw",
      height: "60vh",
      marginTop: -100,
      marginBottom: -100,
    }),
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <Player
        src={
          isLight
            ? "/assets/lottie/order_complete_animation.json"
            : "/assets/lottie/order_complete_dark_animation.json"
        }
        className="player"
        loop
        style={{
          width: playerStyle.width,
          height: playerStyle.height,
          marginTop: playerStyle.marginTop,
          marginBottom: playerStyle.marginBottom,
        }}
        autoplay
      />
    </Stack>
  );
}

export default memo(OrderCompleteIllustration);
