import axios, { AxiosRequestConfig } from "axios";
// config
import { HOST_API } from "../config";

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 10000,
  baseURL: HOST_API,
  timeoutErrorMessage:
    "İstek zaman aşımına uğradı. Daha sonra tekrar deneyiniz",
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const { code, response } = error;
    if (response && response.status) {
      const { status } = response;

      switch (status) {
        case 403:
          document.location.href = "/403";
          return;
        case 404:
          document.location.href = "/404";
          return;
        case 307:
        case 302:
          window.location.href = response.data;
          return;
        /*case 401:
                                  localStorage.removeItem('accessToken')
                                  delete axios.defaults.headers.common.Authorization
                                  return*/
        case 503:
          document.location.href = "/bakim";
          return;
      }
    } else if (code) {
      switch (code) {
        case "ERR_DEPRECATED":
          alert("Bu özellik artık desteklenmemektedir");
          return;
        case "ERR_NOT_SUPPORT":
          alert("Bu özellik desteklenmiyor");
          return;
        case "ERR_INVALID_URL":
          alert("Geçersiz URL");
          return;
        case "ERR_CANCELED":
          alert("İstek iptal edildi");
          return;
        case "ECONNABORTED":
          alert("Bağlantı süresi doldu");
          return;
        case "ETIMEDOUT":
          alert("İstek zaman aşımına uğradı");
          return;
        case "ERR_NETWORK":
          document.location.href = "/bakim";
          return;
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export function getErrorMessageFromUseSWR(error: any | undefined) {
  if (error)
    if (error?.response && error?.response.data)
      return error.response.data.error;
    else return error.message;
}

export const endpoints = {
  chat: {
    markAsSeen: "/conversation/mark-as-seen",
    conversations: "/conversation/conversations",
    conversation: "/conversation/conversation",
    joinConversation: "/conversation/join-conversation",
    conversationWithUser: "/conversation/conversation-with-user",
    contacts: "/conversation/contacts",
    message: "/conversation/message",
    deleteMessage: "/conversation/message/",
    deleteMessagePersistent: "/conversation/message-persistent/",
    deleteAllMessage: "/conversation/conversation/",
  },
  analytics: {
    analyticsSite: "/analytics/analyticsSite",
    mainSearch: "/analytics/search",
    navMenuData: "/analytics/nav-menu-data",
  },
  kanban: "/kanban",
  calendar: "/calendar",
  auth: {
    me: "/auth/me",
    sendInvite: "/auth/send-invite",
    login: "/auth/login",
    logout: "/auth/logout",
    googleLogin: "/auth/auth/google",
    googleCallback: "/auth/auth/google/callback",
    googleLoginCallback: "/auth/auth/login-with-callback",
    register: "/auth/register",
    validateAccount: "/auth/validate-account",
    resendValidateAccountCode: "/auth/resend-validate-account-code",
    resendForgotPasswordCode: "/auth/resend-forgot-password-code",
    createNewPassword: "/auth/create-new-password",
    changePassword: "/auth/change-password",
    validateNewPassword: "/auth/validate-new-password",
    update: "/auth/update",
  },
  mail: {
    list: "/mail/list",
    details: "/mail/details",
    labels: "/mail/labels",
  },
  post: {
    create: "/post/create",
    createBlog: "/post/blog-create",
    delete: "/post/",
    update: "/post/",
    lock: "/post/lock/",
    updateCategory: "/post/category",
    list: "/post/list",
    tab: "/post/tab",
    homeTab: "/post/home-tab",
    getByUserId: "/post/user/",
    getByUserIdWithNoAuth: "/post/user-visit/",
    details: "/post/details",
    blogDetails: "/post/blog-details",
    blogs: "/post/blogs",
    recent: "/post/recent/",
    blogDetailRecent: "/post/blog-details-recent/",
    blogPopular: "/post/blog-popular",
    blogPopularTag: "/post/blog-popular-tags",
    blogRecent: "/post/blog-recent",
    search: "/post/list",
  },
  sss: {
    create: "/sss/create",
    response: "/sss/response",
    all: "/sss/all",
  },
  postTradeOffer: {
    create: "/postTradeOffer/create",
    createDirect: "/postTradeOffer/create-direct",
    accept: "/postTradeOffer/accept",
    update: "/postTradeOffer/",
    updateOfferStatus: "/postTradeOffer/status/",
    list: "/postTradeOffer/list",
    userTradeOffers: "/postTradeOffer/my-trade-offers",
    userTradePosts: "/postTradeOffer/my-trade-posts",
    userTradePostOffers: "/postTradeOffer/my-trade-post-offers/",
    userTradePostOfferList: "/postTradeOffer/my-trade-post-offer-list",
    tradeOfferProgressPayment: "/postTradeOffer/trade-offer-progress-payment/",
    tradeOfferDetailsWithId: "/postTradeOffer/trade-offer-details/",
    tradeOfferContent: "/postTradeOffer/trade-offer-content/",
    tradeOfferAllTime: "/postTradeOffer/trade-offer-all-time/",
    tradeOfferLatestYear: "/postTradeOffer/trade-offer-latest-year/",
    id: "/postTradeOffer/",
    getByPostId: "/postTradeOffer/post/",
    getByUserId: "/postTradeOffer/user/",
  },
  postComment: {
    create: "/postComment/create",
    delete: "/postComment/",
    postCommentWithChild: "/postComment/postCommentWithChild/",
    postId: "/postComment/postId/",
  },
  skill: {
    me: "/skill/me",
    getByUserId: "/skill/getByUserId/",
    create: "/skill/create",
    delete: "/skill/delete/",
  },
  postLike: {
    create: "/postLike/create",
    delete: "/postLike/delete",
  },
  postFollow: {
    isUserFollowed: "/postFollow/isUserFollowed/",
    create: "/postFollow/create",
    delete: "/postFollow/delete",
  },
  systemNotify: {
    getAll: "/systemNotify/getAll",
  },
  friend: {
    getAllByUserId: "/friend/getAllByUserId/",
    create: "/friend/create",
    delete: "/friend/delete/",
  },
  follower: {
    getAllByUserId: "/follower/getAllByUserId/",
    getAllByFollowedUserId: "/follower/getAllByFollowedUserId/",
    getByUserId: "/follower/getByUserId/",
    create: "/follower/create",
    delete: "/follower/delete/",
  },
  blockedUser: {
    getByUserId: "/blockedUser/getByUserId/",
    create: "/blockedUser/create",
    delete: "/blockedUser/delete/",
  },
  category: {
    homeList: "/category/home",
    subCategories: "/category/sub/",
    group: "/category/group",
    getById: "/category/",
    create: "/category/create",
    update: "/category/update",
  },
  tags: {
    filter: "/tag/filter",
  },
  raffle: {
    userRaffles: "/raffle/user-raffles",
    prizes: "/raffle/prizes",
    history: "/raffle/prize-history",
    popularUser: "/raffle/popular-users",
  },
  report: {
    id: "/report/",
    createUser: "/report/create/user",
    createPost: "/report/create/post",
    createTrade: "/report/create/trade",
    createPostComment: "/report/create/post-comment",
    createMessage: "/report/create/message",

    getReportList: "/report/list",
    getReportDetails: "/report/details/",
    getReportedUserList: "/report/list/user-reported",
    getReportUserList: "/report/list/user-report",
    getReportAllTimeAdmin: "/report/all-time",
    getYearlyStatsForAdminSql: "/report/yearly-stats",

    getReportReasonList: "/report/list/report-reason",
    getReportStatusList: "/report/list/report-status",
    getReportItemCategoryList: "/report/list/report-item-category",

    update: "/report/update",
    complete: "/report/complete",
    cancel: "/report/cancel/",

    getAllPost: "/report/getAllPost",
  },
  punishment: {
    id: "/punishment/",
    createPunishment: "/punishment/create",
    updatePunishment: "/punishment/update",
    getLatestSixMonthPunishmentByUserIdAndPunishmentType:
      "/punishment/latest-six-month",
    deletePunishment: "/punishment/delete/:id",
    getAllPunishments: "/punishment/getAll",

    getPunishmentTypeList: "/punishment/list/punishment-type",
    getPunishmentTypes: "/punishment/punishment-types",
    getPunishmentUser: "/punishment/punished-user/",
    getPunishmentMe: "/punishment/me",
    getObjectToPunishmentStatusList:
      "/punishment/list/object-to-punishment-status",
  },
  lookupData: {
    getAll: "/lookupData/getAll?lookupId=",
  },
  memberBadge: {
    getAllByUserId: "/memberBadge/getAllByUserId/",
  },
  badges: {
    getAll: "/badge/getAll",
    byId: "/badge/",
    create: "/badge/create",
    update: "/badge/update",
  },
  badgeGroup: {
    getAll: "/badgeGroup/getAll",
    getAllCategory: "/badgeGroup/getAllCategory",
    byId: "/badgeGroup/",
    create: "/badgeGroup/create",
    update: "/badgeGroup/",
  },
  memberNotification: {
    getByUserId: "/notification/getByUserId",
    markAsAllRead: "/notification/markAsReadAll",
  },
  user: {
    profile: "/user/profile",
    profileVisit: "/user/profile-visit",
    popularUser: "/user/popular-users",
    popularUsersWithNoAuth: "/user/popular-users-with-no-auth",
    moderasyon: "/user/moderasyon",
    moderation: "/user/moderation-user",
    mentionUsers: "/user/mention-user",
    socialMedia: "/socialMedia/getByUserId",
    generalStats: "/user/general-stats",
    blockStats: "/user/block-stats",
    followerStats: "/user/follower-stats",
    tradeStats: "/user/trade-stats",
    badgeStats: "/user/badges-stats",
    socialMediaCreate: "/socialMedia/create",
    latest: "/post/latest",
    search: "/post/search",
    inviteFriend: "/user/invite-friend",
    privacy: "/user/privacy",
  },
  userSetting: {
    getByUserId: "/setting/getByUserId",
    details: "/post/details",
    latest: "/post/latest",
  },
  product: {
    list: "/product/list",
    details: "/product/details",
    search: "/product/search",
  },
  invitation: {
    invitationTypeList: "/invitation/list/invitation-types",
    userInvitationCodes: "/invitation/invitation-codes",
    userInvitationLinkClick: "/invitation/create-link/",
    userInvitationEarnHistory: "/invitation/invitation-earn-history",
    userInvitationReceipt: "/invitation/user-invitation-receipt",
    userInvitationStats: "/invitation/user-invitation-stats",
    userInvitationIncomeStats: "/invitation/user-invitation-income-stats",
    userInvitationIncomeSummary: "/invitation/user-invitation-income-summary",
    adminInvitationSummary: "/invitation/admin-invitation-summary",
    popularInvitationUser: "/invitation/popular-users",
    adminInvitationHistory: "/invitation/admin-invitation-history",
    yearlyInvitationUserStats: "/invitation/yearly-user-stats",
    delete: "/invitation/delete/",
  },
};
