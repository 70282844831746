import { _mock } from "./_mock";
import { useMemo } from "react";

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  role: _mock.role(index),
  avatarUrl: _mock.image.portrait(index),
}));

export const _skeletonMembers = (count: number) =>
  [...Array(count)].map((_, index) => ({
    id: _mock.id(index),
  }));

export const _faqsBizifestPlatform = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_bizifest_platform_heading(index),
  detail: _mock.faqs_bizifest_platform_description(index),
}));

export const _faqsTradingPosts = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_trading_posts_heading(index),
  detail: _mock.faqs_trading_posts_description(index),
}));

export const _faqsReputation = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_reputation_headings(index),
  detail: _mock.faqs_reputation_descriptions(index),
}));

export const _faqsBadges = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_badges_headings(index),
  detail: _mock.faqs_badges_descriptions(index),
}));

export const _faqsGeneral = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_general_headings(index),
  detail: _mock.faqs_general_descriptions(index),
}));
export const _faqsSystem = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: _mock.faqs_system_headings(index),
  detail: _mock.faqs_system_descriptions(index),
}));

export function useFaqsData() {
  const data = useMemo(
    () => [
      ..._faqsSystem,
      ..._faqsTradingPosts,
      ..._faqsReputation,
      ..._faqsBizifestPlatform,
      ..._faqsGeneral,
      ..._faqsBadges,
    ],
    [],
  );

  return data;
}

export const _addressBooks = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  primary: index === 0,
  name: _mock.fullName(index),
  email: _mock.email(index + 1),
  fullAddress: _mock.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  company: _mock.companyName(index + 1),
  addressType: index === 0 ? "Home" : "Office",
}));

export const _contacts = [...Array(20)].map((_, index) => {
  const status =
    (index % 2 && "online") ||
    (index % 3 && "offline") ||
    (index % 4 && "alway") ||
    "busy";

  return {
    id: _mock.id(index),
    status,
    role: _mock.role(index),
    email: _mock.email(index),
    name: _mock.fullName(index),
    phoneNumber: _mock.phoneNumber(index),
    lastActivity: _mock.time(index),
    avatarUrl: _mock.image.avatar(index),
    address: _mock.fullAddress(index),
  };
});

export const _notifications = [...Array(9)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: [
    _mock.image.avatar(1),
    _mock.image.avatar(2),
    _mock.image.avatar(3),
    _mock.image.avatar(4),
    _mock.image.avatar(5),
    null,
    null,
    null,
    null,
    null,
  ][index],
  type: [
    "friend",
    "project",
    "file",
    "tags",
    "payment",
    "order",
    "chat",
    "mail",
    "delivery",
  ][index],
  category: [
    "Communication",
    "Project UI",
    "File Manager",
    "File Manager",
    "File Manager",
    "Order",
    "Order",
    "Communication",
    "Communication",
  ][index],
  isUnRead: _mock.boolean(index),
  createdAt: _mock.time(index),
  title:
    (index === 0 &&
      `<p><strong>Selen Özer</strong> sana bir arkadaşlık isteği gönderdi.</p>`) ||
    (index === 1 &&
      `<p><strong>Selim Sever</strong> bu konuda senden bahsetti : <strong><a href='#'>Minimal UI</a></strong></p>`) ||
    (index === 2 &&
      `<p><strong>Osman Bakıcı</strong> şuraya bir dosya yükledi : <strong><a href='#'>File Manager</a></strong></p>`) ||
    (index === 3 &&
      `<p><strong>Ayşe Gül</strong> şuraya yeni bir etiket ekledi : <strong><a href='#'>File Manager<a/></strong></p>`) ||
    (index === 4 &&
      `<p><strong>Özge Gürgen</strong> sana şu kadarlık ödeme isteği gönderdi : <strong>TL200</strong></p>`) ||
    (index === 5 && `<p>Siparişiniz kargoya verilmeyi bekliyor</p>`) ||
    (index === 6 && `<p>Teslimat işlemi siparişiniz gönderiliyor</p>`) ||
    (index === 7 && `<p>Okunmamış 5 yeni mesajın var</p>`) ||
    (index === 8 && `<p>Yeni 1 mesajın var`) ||
    "",
}));

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];

export const _socials = [
  {
    value: "facebook",
    name: "Facebook",
    icon: "eva:facebook-fill",
    color: "#1877F2",
    path: "https://www.facebook.com/profile.php?id=100089086266664",
  },
  {
    value: "instagram",
    name: "Instagram",
    icon: "ant-design:instagram-filled",
    color: "#E02D69",
    path: "https://www.instagram.com/bizifest/",
  },
  {
    value: "linkedin",
    name: "Linkedin",
    icon: "eva:linkedin-fill",
    color: "#007EBB",
    path: "https://www.linkedin.com/company/codemakerz",
  },
  {
    value: "twitter",
    name: "Twitter",
    icon: "ri:twitter-x-fill",
    color: "#559fa6",
    path: "https://twitter.com/bizifest",
  },
];

export const _postIndicators = [
  {
    value: "interactions",
    name: "Etkileşim",
    icon: "mdi:gesture-double-tap",
    number: 54688,
  },
  {
    value: "views",
    name: "Görüntülenme",
    icon: "carbon:view-filled",
    number: 52365,
  },
  {
    value: "comments",
    name: "Yorumlar",
    icon: "material-symbols:comment",
    number: 578,
  },
  {
    value: "shares",
    name: "Paylaşımlar",
    icon: "material-symbols:share",
    number: 1745,
  },
];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: ["Standard", "Standard Plus", "Extended"][index],
  commons: ["One end products", "12 months updates", "6 months of support"],
  options: [
    "JavaScript version",
    "TypeScript version",
    "Design Resources",
    "Commercial applications",
  ],
  icons: [
    "/assets/icons/platforms/ic_figma.svg",
    "/assets/icons/platforms/ic_js.svg",
    "/assets/icons/platforms/ic_ts.svg",
  ],
}));

export const _pricingPlans = [
  {
    subscription: "basic",
    price: 0,
    caption: "Forever",
    lists: ["3 Prototypes", "3 Boards", "Up To 5 Team Members"],
    labelAction: "Current Plan",
  },
  {
    subscription: "starter",
    price: 4.99,
    caption: "Saving TL24 a year",
    lists: [
      "3 Prototypes",
      "3 Boards",
      "Up To 5 Team Members",
      "Advanced Security",
      "Issue Escalation",
    ],
    labelAction: "Choose Starter",
  },
  {
    subscription: "premium",
    price: 9.99,
    caption: "Saving TL124 a year",
    lists: [
      "3 Prototypes",
      "3 Boards",
      "Up To 5 Team Members",
      "Advanced Security",
      "Issue Escalation",
      "Issue Development license",
      "Permissions & workflows",
    ],
    labelAction: "Choose Premium",
  },
];

export const _testimonials = [
  {
    name: _mock.fullName(1),
    postedDate: _mock.time(1),
    ratingNumber: _mock.number.rating(1),
    avatarUrl: _mock.image.avatar(1),
    content: `Excellent Work! Thanks a lot!`,
  },
  {
    name: _mock.fullName(2),
    postedDate: _mock.time(2),
    ratingNumber: _mock.number.rating(2),
    avatarUrl: _mock.image.avatar(2),
    content: `It's a very good dashboard and we are really liking the product . We've done some things, like migrate to TS and implementing a react useContext api, to fit our job methodology but the product is one of the best in terms of design and application architecture. The team did a really good job.`,
  },
  {
    name: _mock.fullName(3),
    postedDate: _mock.time(3),
    ratingNumber: _mock.number.rating(3),
    avatarUrl: _mock.image.avatar(3),
    content: `Customer support is realy fast and helpful the desgin of this theme is looks amazing also the code is very clean and readble realy good job !`,
  },
  {
    name: _mock.fullName(4),
    postedDate: _mock.time(4),
    ratingNumber: _mock.number.rating(4),
    avatarUrl: _mock.image.avatar(4),
    content: `Amazing, really good code quality and gives you a lot of examples for implementations.`,
  },
  {
    name: _mock.fullName(5),
    postedDate: _mock.time(5),
    ratingNumber: _mock.number.rating(5),
    avatarUrl: _mock.image.avatar(5),
    content: `Got a few questions after purchasing the product. The owner responded very fast and very helpfull. Overall the code is excellent and works very good. 5/5 stars!`,
  },
  {
    name: _mock.fullName(6),
    postedDate: _mock.time(6),
    ratingNumber: _mock.number.rating(6),
    avatarUrl: _mock.image.avatar(6),
    content: `CEO of Codealy.io here. We’ve built a developer assessment platform that makes sense - tasks are based on git repositories and run in virtual machines. We automate the pain points - storing candidates code, running it and sharing test results with the whole team, remotely. Bought this template as we need to provide an awesome dashboard for our early customers. I am super happy with purchase. The code is just as good as the design. Thanks!`,
  },
];
