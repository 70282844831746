// FORUM CONSTANTS

// New Post Constants
import { IAddressItem } from "../../types/address";

export const NEW_POST_TITLE_CHARACTER_MIN = 3;
export const NEW_POST_TITLE_CHARACTER_MAX = 90;
export const NEW_POST_COMMENT_CHARACTER_MIN = 10;
export const NEW_POST_COMMENT_CHARACTER_MAX = 5000;
export const NEW_POST_DESCRIPTION_CHARACTER_MIN = 20;
export const NEW_POST_DESCRIPTION_CHARACTER_MAX = 250;
export const NEW_POST_CONTENT_CHARACTER_MIN = 20;
export const NEW_POST_TAGS_COUNT_MIN = 2;
export const NEW_POST_TAGS_COUNT_MAX = 4;
export const NEW_POST_TAGS_CHARACTER_MAX = 30;
export const NEW_POST_TAGS_CHARACTER_MIN = 1;
export const NEW_POST_TRADE_BUDGET_MIN = 1;
export const NEW_POST_TRADE_BUDGET_MAX = 100000;
export const NEW_POST_TRADE_ADVANCE_PAYMENT_MIN = 1;
export const NEW_POST_TRADE_ADVANCE_PAYMENT_MAX = 100000;
export const NEW_POST_TRADE_WARRANTY_PERIOD_MIN = 3;
export const NEW_POST_TRADE_WARRANTY_PERIOD_MAX = 500;
export const NEW_POST_TRADE_WARRANTY_DESCRIPTION_MIN = 10;
export const NEW_POST_TRADE_WARRANTY_DESCRIPTION_MAX = 500;
export const ErrPleaseValidateYourAccount =
  "Lütfen Mail adresinize gönderilen kodu kullanarak hesabını doğrula!";

export enum ProfileWidgetTypeDescEnum {
  INTERACTION = " Etkileşim",
  FOLLOWED = " Takip Edilen",
  FOLLOWER = " Takipçi",
  REPUTATION = " Bizifest Puanı",
  GOLD_BADGE = " Altın Rozet",
  SILVER_BADGE = " Gümüş Rozet",
  BRONZE_BADGE = " Bronz Rozet",
  POST = " Konu",
  COMMENT = " Yorum",
  LIKED_POST = " Beğendiği Konu",
  LIKED_COMMENT = " Beğendiği Yorum",
  LIKED_TAKEN_POST = " Beğenilen Konu",
  LIKED_TAKEN_COMMENT = " Beğenilen Yorum",
  FAVORITE_POSTS = " Favorilediği Konu",
  FAVORITE_TAKEN_POSTS = " Favorilenen Konu",
  TAGGED_POSTS = " Etiketlediği Konu",
  TAGGED_TAKEN_POSTS = " Etiketlendiği Konu",
}

// New Offer Constants
export const NEW_OFFER_DESCRIPTION_CHARACTER_MIN = 5;
export const NEW_OFFER_DESCRIPTION_CHARACTER_MAX = 250;

// Member Register Constants
export const USERNAME_MIN_CHARACTERS = 4;
export const USERNAME_MAX_CHARACTERS = 16;
export const USER_FIRST_NAME_MIN_CHARACTERS = 3;
export const USER_FIRST_NAME_MAX_CHARACTERS = 16;
export const USER_OCCUPATION_MAX_CHARACTERS = 32;
export const USER_LAST_NAME_MIN_CHARACTERS = 2;
export const USER_LAST_NAME_MAX_CHARACTERS = 16;
export const USER_PASSWORD_MIN_CHARACTERS = 6;
export const USER_PASSWORD_MAX_CHARACTERS = 64;
export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;

// sadece harf veya sayı
export const USERNAME_CHARACTER_REGEX = /^[a-zA-Z0-9]+$/;

// boşluk (whitepace) kullanılamaz
export const USERNAME_WITHOUT_SPACE_REGEX = /^[^\s]+$/;
// sadece harf girisi
export const USER_NAME_ONLY_CHARACTERS = /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]+$/;

export const USER_EMAIL_MAX_CHARACTERS = 32;
export const USER_NAME_OR_EMAIL_LOGIN_MAX_CHARACTERS = 32;
// en az 10, en fazla 15 karakterli telefon numarası
export const USER_PHONE_NUMBER_REGEX = /^(?:[0-9]{11}|)$/;

export const USER_ADDRESS_MIN_CHARACTER = 10;
export const USER_ADDRESS_MAX_CHARACTER = 300;
export const USER_POSTCODE_REGEX = /^(?:\d{5}|)$/;

export const USER_CITY_MAX = 30;
export const USER_ABOUT_MAX = 300;

// profile update values
export const USER_PROFILE_COVER_UPLOAD_SIZE_MAX = 3145728;
export const USER_PROFILE_PHOTO_UPLOAD_SIZE_MAX = 1000000;

export const USER_HELP_REQUEST_DESCRIPTION_MIN = 5;
export const USER_HELP_REQUEST_DESCRIPTION_MAX = 250;
export const USER_HELP_REQUEST_TITLE_MIN = 5;
export const USER_HELP_REQUEST_TITLE_MAX = 150;
export const USER_HELP_REQUEST_NAME_MIN = 3;
export const USER_HELP_REQUEST_NAME_MAX = 64;

export const REPORT_FORUM_DESC_MIN = 10;
export const REPORT_FORUM_DESC_MAX = 5000;

export const FORUM_POPULAR_SECTION_MAX_POST_LOAD = 100;

// REPORT STATUS
export const REPORT_STATUS_CANCEL = -1;
export const REPORT_STATUS_NEW = 1;
export const REPORT_STATUS_CHAT = 2;
export const REPORT_STATUS_UNSUCCESSFUL = 3;
export const REPORT_STATUS_SUCCESSFUL = 4;

export const FOOTER_BTK_CERTIFICATE_URL =
  "https://www.btk.gov.tr/kendi-bunyesinde-hizmet-verenler-yer-saglayici-listesi?page=1&q=codemakerz";

// forum main page tabs names
export const MAIN_PAGE_TABS_STATUS_OPTIONS = [
  { query: "latestPosts", label: "lastestOpenPosts" },
  { query: "popular", label: "popularPosts" },
  { query: "latestCommentedPost", label: "latestCommentedPost" },
  { query: "hotPost", label: "hotPost" },
  { query: "commercialPosts", label: "commercialPosts" },
];

export const LANDING_PAGE_CARDS = [
  {
    icon: "/assets/illustrations/pre_register/freelancer.svg",
    title: "İşlerini paylaş, yeni müşteriler bul",
    description: "İşlerini ve hizmetlerini etkili bir şekilde tanıt!",
  },
  {
    icon: "/assets/illustrations/pre_register/best_quality.svg",
    title: "İşini en kaliteli uzmanlara yaptır",
    description: "İşlerini profesyonel ve uzman kişilere emanet et!",
  },
  {
    icon: "/assets/illustrations/pre_register/low_commission.svg",
    title: "Yüksek komisyonlardan uzak dur",
    description: "Ürün veya hizmet alımlarında yüksek komisyon verme!",
  },
];

{
  /* todo GIVEAWAY_PROMOTION_PAGE_CARDS veritabanına işlenecek */
}
export const GIVEAWAY_PROMOTION_PAGE_CARDS = [
  {
    icon: "/assets/giveaway/graphic_designer.png",
    title: "Genişletilmiş Dijital Tasarım Eğitimi",
    description:
      "Grafik tasarımın temellerini öğrenerek dijital sanat alanında uzmanlaşın.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/genisletilmis-dijital-tasarim-egitimi",
  },
  {
    icon: "/assets/giveaway/software_web_designer.png",
    title: "Yazılım ve Web Uzmanlığı Eğitimi",
    description:
      "Yazılım geliştirme ve web teknolojileri konusunda derinlemesine bilgi edinin.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/yazilim-ve-web-uzmanligi-egitimi",
  },
  {
    icon: "/assets/giveaway/social_media.png",
    title: "Sosyal Medya Yönetimi Eğitimi",
    description:
      "Marka yönetimi, içerik stratejileri ve etkileşim konularında uzmanlaşın.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/sosyal-medya-yonetimi-egitimi",
  },
  {
    icon: "/assets/giveaway/cyber_security.png",
    title: "Siber Güvenlik Uzmanlığı Eğitimi",
    description:
      "Bilgisayar sistemlerini koruma, güvenlik tehditlerini analiz etme ve önleme becerilerini geliştirin.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/siber-guvenlik-uzmanligi-egitimi",
  },
  {
    icon: "/assets/giveaway/metaverse_engineering.png",
    title: "Metaverse Mühendisliği Eğitimi",
    description:
      "Sanal dünyalara yönelik gelişmiş mühendislik becerileri kazanın.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/metaverse-muhendisligi-egitimi",
  },
  {
    icon: "/assets/giveaway/ai_prompt_engineering.png",
    title: "Yapay Zekâ Kullanımı ve Prompt Mühendisliği",
    description:
      "Yapay zekâ ve prompt tekniklerini kullanarak öğrenme modelleri oluşturun.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/genisletilmis-dijital-tasarim-egitimi",
  },
  {
    icon: "/assets/giveaway/artificial_intelligence.png",
    title: "Yapay Zeka (AI) Eğitimi",
    description:
      "Temel yapay zeka prensiplerini anlayın ve uygulamalı projelerde deneyim kazanın.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/yapay-zeka-prompt-egitimi",
  },
  {
    icon: "/assets/giveaway/e_commerce_expertise.png",
    title: "E-Ticaret Uzmanlığı Eğitimi",
    description:
      "Online ticaret stratejileri, pazarlama ve işletme yönetimi konularında uzmanlaşın.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/eticaret-uzmanligi-egitimi",
  },
  {
    icon: "/assets/giveaway/robotics_coding.png",
    title: "Robotik Kodlama Eğitimi",
    description:
      "Robotik programlama ve kodlama konusundaki temel yetenekleri öğrenin.",
    link: "https://www.altinkariyerakademi.com.tr/egitimler/robotik-kodlama-egitimi",
  },
];

{
  /* todo GIVEAWAY_CARD_DETAILS veritabanına işlenecek */
}

export const GIVEAWAY_CARD_DETAILS = [
  {
    id: 1,
    title: "Her Gün Giriş Yap",
    description: "Her gün burada bir sürü olay oluyor, kaçırmak istemezsin!",
    tooltipInfo: "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir",
    progressType: "Günlük ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 2,
    title: "Bizifest Ol",
    description: "Buralar senle daha güzel!",
    tooltipInfo:
      "Tüm zamanlarda en fazla 1 Çekiliş Bileti kazanılabilir. Bizifest'e üye olan herkes tek seferlik 3 Çekiliş Bileti kazanılabilir",
    progressType: "Tüm zamanlar ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 3,
    title: "3 Yeni Konu Aç",
    description: "Konularınla Foruma renk katmaya ne dersin?",
    tooltipInfo:
      "8 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Her 3 konuda 1 Çekiliş Bileti kazanılabilir",
    progressType: "Günlük ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },

  {
    id: 4,
    title: "2 Yeni Ticari Konu Aç",
    description: "Festival havasında ticaret başlasın",
    tooltipInfo:
      "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Her 2 ticari konuda 1 Çekiliş Bileti kazanılabilir",
    progressType: "8 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 5,
    title: "5 Konu/Yorum Beğen",
    description: "Beğeniler havada uçuşsun!",
    tooltipInfo:
      "8 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Her 5 beğenide 1 Çekiliş Bileti kazanılabilir",
    progressType: "8 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 6,
    title: "3 Farklı Konuya Yorum Yap",
    description: "Fikirlerimiz çok önemli!",
    tooltipInfo:
      "8 saatte en fazla 1 Çekiliş Bileti kazanılabilir. 3 farklı konuya yapılan yorumda 1 Çekiliş Bileti kazanılabilir",
    progressType: "8 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 7,
    title: "3 Farklı Konuyu Favorile",
    description: "En sevdiğim sensin!",
    tooltipInfo:
      "8 saatte en fazla 1 Çekiliş Bileti kazanılabilir. 3 farklı konuyu favoriye eklemede 1 Çekiliş Bileti kazanılabilir",
    progressType: "8 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 8,
    title: "Arkadaşını Davet Et",
    description:
      "Emin ol burası arkadaşlarınla çok daha eğlenceli bir yer olacak!",
    tooltipInfo: "24 saatte en fazla 10 Çekiliş Bileti kazanılabilir",
    progressType: "Günlük ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 9,
    title: "3 Üyeyi Takip Et",
    description: "Takibe takip!",
    tooltipInfo:
      "24 saatte en fazla 2 Çekiliş Bileti kazanılabilir. 3 farklı kişiyi takip etmede 1 Çekiliş Bileti kazanılabilir",
    progressType: "24 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 10,
    title: "5 Beğeni Topla",
    description: "Seviliyorum ne dersin?",
    tooltipInfo:
      "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Konularına/yorumlarına toplamda 5 beğeni toplayan üye 1 Çekiliş Bileti kazanılabilir",
    progressType: "24 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 11,
    title: "3 Yorum Topla",
    description: "Konularım ilgi görüyor, ha?",
    tooltipInfo:
      "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Konularına toplamda 3 yorum toplayan üye 1 Çekiliş Bileti kazanılabilir",
    progressType: "24 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 12,
    title: "3 Favori Topla",
    description: "Bizifest'in favorisi benim!",
    tooltipInfo:
      "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir. Konularının 3 farklı üye tarafından favoriye alınmasıyla 1 Çekiliş Bileti kazanılabilir",
    progressType: "24 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
  {
    id: 13,
    title: "3 Takipçi Topla",
    description: "Herkes benim peşimde!",
    tooltipInfo:
      "24 saatte en fazla 1 Çekiliş Bileti kazanılabilir. 3 farklı üye tarafından takibe alınma ile 1 Çekiliş Bileti kazanılabilir",
    progressType: "24 saatlik ilerleme",
    earnedCount: 1,
    remainCount: 21,
    ticketCount: 22,
  },
];

export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";

export const GIVEAWAY_DATE = "08/01/2024 20:00";

export const PROFILE_ACTIVITY_SECTION_NAMES = [
  {
    id: 1,
    icon: "ic:round-all-inclusive",
    title: "Özet",
  },
  {
    id: 2,
    icon: "mdi:badge",
    title: "FestRozet",
  },
  {
    id: 3,
    icon: "ic:round-article",
    title: "Konular",
  },

  {
    id: 4,
    icon: "ic:round-how-to-reg",
    title: "Takipçiler",
  },
  {
    id: 5,
    icon: "ic:round-workspaces",
    title: "Yetenekler",
  },
  {
    id: 6,
    icon: "mdi:briefcase",
    title: "Ticaret",
  },
  {
    id: 7,
    icon: "ic:round-gavel",
    title: "Ceza",
  },
  /*  {
     id: 8,
     icon: 'material-symbols:stylus-laser-pointer',
     title: 'FestPuan',
    },
       {
      id: 9,
      icon: 'material-symbols:comment-outline',
      title: 'Yorumlar',
    },
       {
         id: 10,
         icon: 'mdi:tags',
         title: 'Etiketler',
       },*/
];

// Badge section constants
export const BADGE_GOLD = "Gold";
export const BADGE_GOLD_IMAGE = "/assets/illustrations/badges/badge_gold.png";
export const BADGE_SILVER = "Silver";
export const BADGE_SILVER_IMAGE =
  "/assets/illustrations/badges/badge_silver.png";
export const BADGE_BRONZE = "Bronz";
export const BADGE_BRONZE_IMAGE =
  "/assets/illustrations/badges/badge_bronze.png";
export const BADGE_DEFAULT_IMAGE = "/assets/images/badge0.jpg";

// If the category description that comes with this control is above a certain number of characters, we increase the cardview height value.
export const CATEGORY_MOBILE_TOP_CARD_VIEW_HEIGHT_CONTROL = 100;

// The number of pages in the section where data such as popular topics on the Home Page and recently commented topics come from. How many posts will be drawn at one time?
export const HOMEPAGE_TOP_SECTION_POST_PAGE_SIZE = 5;

export const MODERATOR_REPORT_DESCRIPTION_MIN_VALUE = 10;

// Report steps for moderation. 1. step controls evaluateReport, 2. step controls finalizeReport
export const REPORT_STEPS = ["evaluateReport", "finalizeReport"];
//export const REPORT_STEPS = ['evaluateReport', 'punishMember', 'finalizeReport']

// auth section form names
export enum JWT_DIALOG_VIEW_FORM_NAMES {
  LOGIN = "login",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgotPassword",
  VERIFY_EMAIL = "verifyMail",
}

// report status
export enum REPORT_STATUS {
  CANCELLED = 0, // İptal Edildi
  NEW = 1, // Yeni
  CONCILIATION = 2, // Mesaj Üzerinden Akış
  UNSUCCESSFULLY_REPORT = 3, // Başarısız Raporlama
  SUCCESSFULLY_REPORT = 4, // Başarılı Raporlama
}

export const INVITE_STATISTIC_FILTER_WEEK = "weeklyInvite";
export const INVITE_STATISTIC_FILTER_MONTH = "monthlyInvite";
export const INVITE_STATISTIC_FILTER_YEAR = "monthlyYearly";

export const RECEIPT_CODEMAKERZ_YAZILIM_AS_ADDRESS: IAddressItem = {
  id: "1",
  name: "CodeMakerz Yazılım A.Ş.",
  company: "CodeMakerz Yazılım A.Ş.",
  primary: true,
  fullAddress:
    "Meşruti̇yet Mh, Konur Sk, Özsoy İş Hanı Blok No: 25, İç Kapı No: 13 Çankaya/ANKARA",
  phoneNumber: "+905313722484",
  addressType: "Work",
};

export const RECEIPT_YEAR: number = new Date().getFullYear();

export const RECEIPT_INVITE_STATUS = [
  "paidReceipt",
  "pendingReceipt",
  "overdueReceipt",
  "draftReceipt",
];

type LabelColor =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | undefined;

export const RECEIPT_INVITE_TABS = [
  { value: "all", label: "all", color: "default" as LabelColor },
  {
    value: "paidReceipt",
    label: "receiptPaid",
    color: "success" as LabelColor,
  },
  {
    value: "pendingReceipt",
    label: "receiptPending",
    color: "warning" as LabelColor,
  },
  {
    value: "overdueReceipt",
    label: "receiptOverdue",
    color: "error" as LabelColor,
  },
  {
    value: "draftReceipt",
    label: "draft",
    color: "default" as LabelColor,
  },
];

export const RECEIPT_INVITE_TABLE_HEAD = [
  { id: "invoiceNumber", label: "Üye" },
  { id: "createDate", label: "Oluşturulma" },
  { id: "dueDate", label: "Son Ödeme" },
  { id: "price", label: "Miktar" },
  { id: "sent", label: "Sayı", align: "center" },
  { id: "status", label: "Durum" },
  { id: "" },
];

/*
export const RECEIPT_INVITE_TABLE_HEAD = [
    {id: 'receiptMember', label: 'Üye'},
    {id: 'receiptCreateDate', label: 'Oluşturulma'},
    {id: 'receiptDueDate', label: 'Son Ödeme'},
    {id: 'receiptAmount', label: 'Miktar'},
    {id: 'receiptSent', label: 'Sayı', align: 'center'},
    {id: 'receiptStatus', label: 'Durum'},
    {id: ''},
];*/

export const INVITE_STATISTIC_TABS = [
  { value: "all", label: "all", color: "default" as LabelColor },
  {
    value: "verified",
    label: "verified",
    color: "success" as LabelColor,
  },
  {
    value: "not_verified",
    label: "not_verified",
    color: "warning" as LabelColor,
  },
];

export const INVITE_STATISTIC_TABLE_HEAD = [
  { id: "inviting", label: "Davet Eden" },
  { id: "inviteCode", label: "Davetiye Kodu", align: "center" },
  { id: "invited", label: "Davet Edilen" },
  { id: "membershipDate", label: "Üyelik Tarihi", align: "left" },
  { id: "invitedStatus", label: "Doğrulama Durumu" },
  { id: "" },
];

/* add new analytic tags here */
export enum GOOGLE_ANALYTIC_EVENT_NAME {
  CATEGORY_VIEW = "category_view",
  LOGIN = "login",
  SIGNUP = "sign_up",
  BLOG_FOLLOW = "blog_follow",
  BLOG_LIKE = "blog_like",
}
