import { format, formatDistanceToNow, getTime } from "date-fns";
import { tr } from "date-fns/locale";

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm, { locale: tr }) : "";
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm, { locale: tr }) : "";
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : "";
}

export function isBetween(
  inputDate: Date | string | number,
  startDate: Date,
  endDate: Date,
) {
  const date = new Date(inputDate);

  const results =
    new Date(date.toDateString()) >= new Date(startDate.toDateString()) &&
    new Date(date.toDateString()) <= new Date(endDate.toDateString());

  return results;
}

export function isAfter(startDate: Date | null, endDate: Date | null) {
  const results =
    startDate && endDate
      ? new Date(startDate).getTime() > new Date(endDate).getTime()
      : false;

  return results;
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: tr,
      })
    : "";
}

export function fToNowStr(date: string) {
  const date1 = strToDate(date);
  if (date1) return fToNow(date1);
  return "";
}

export function strToDate(dtStr: string) {
  if (!dtStr) return null;
  let dateParts = dtStr.split("/");
  let timeParts = dateParts[2].split(" ")[1].split(":");
  dateParts[2] = dateParts[2].split(" ")[0];

  // Ay ifadesini number tipine dönüştür
  const month = Number(dateParts[1]) - 1;

  // Parametre tiplerini belirleyerek tip güvenliği sağla
  return new Date(
    +dateParts[2], // Yıl
    month, // Ay (0-based olduğu için -1)
    +dateParts[0], // Gün
    +timeParts[0], // Saat
    +timeParts[1], // Dakika
    +timeParts[2], // Saniye
  );
}
