import Box from "@mui/material/Box";

import Footer from "./footer";
import Header from "./header";
import HeaderAuth from "../dashboard/header";
import { usePathname } from "../../routes/hook";
import { useAuthContext } from "../../auth/hooks";
import { paths } from "../../routes/paths";
import { useBoolean } from "../../hooks/use-boolean";
import { fUserRoleAdminOrModerator } from "../../utils/authorization";
import { useResponsive } from "../../hooks/use-responsive";
import NavMini from "../dashboard/nav-mini";
import NavVertical from "../dashboard/nav-vertical";

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();
  const nav = useBoolean();
  const homePage = pathname === paths.whatIsBizifest;
  const { user } = useAuthContext();
  const lgUp = useResponsive("up", "lg");
  const renderNavMini = <NavMini />;
  const renderNavVertical = (
    <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      {user ? <HeaderAuth onOpenNav={nav.onTrue} /> : <Header />}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!homePage && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {fUserRoleAdminOrModerator(user) &&
          (lgUp ? renderNavMini : renderNavVertical)}

        {children}
      </Box>

      <Footer />
    </Box>
  );
}
