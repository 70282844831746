import { useMemo } from "react";
import keyBy from "lodash/keyBy";
import useSWR, { mutate } from "swr";
import axios, { endpoints, fetcher } from "../utils/axios";
import {
  IChatConversation,
  IChatConversations,
  IChatConversationTrade,
  IChatMessage,
  IChatParticipant,
} from "../types/chat";
import uuidv4 from "../utils/uuidv4";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetContacts() {
  const URL = [endpoints.chat.contacts];

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  return useMemo(
    () => ({
      contacts: (data?.contacts as IChatParticipant[]) || [],
      contactsLoading: isLoading,
      contactsError: error,
      contactsValidating: isValidating,
      contactsEmpty: !isLoading && !data?.contacts.length,
    }),
    [data?.contacts, error, isLoading, isValidating],
  );
}

export function useGetConversations() {
  const URL = [endpoints.chat.conversations];

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  return useMemo(() => {
    const byId = keyBy(data?.conversations, "id") || {};
    const allIds = Object.keys(byId) || [];
    const latestConversationId = allIds.length > 0 ? allIds[0] : "";
    let count = 0;
    data?.conversations.forEach((item: IChatConversation) => {
      if (item.unreadCount > 0) {
        count++;
      }
    });

    return {
      conversations: {
        byId,
        allIds,
      } as IChatConversations,
      conversationsLoading: isLoading,
      conversationsAllUnRead: count,
      conversationsError: error,
      latestConversationId: latestConversationId,
      conversationsValidating: isValidating,
      conversationsEmpty: !isLoading && !allIds.length,
    };
  }, [data?.conversations, error, isLoading, isValidating]);
}

export function useGetConversation(conversationId: string) {
  const URL = conversationId
    ? [endpoints.chat.conversation, { params: { conversationId } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  return useMemo(
    () => ({
      conversation: data?.conversation as IChatConversation,
      conversationLoading: isLoading,
      conversationError: error,
      conversationValidating: isValidating,
    }),
    [data?.conversation, error, isLoading, isValidating],
  );
}

export function useGetConversationWithUser(userId: string) {
  const URL = userId
    ? [endpoints.chat.conversationWithUser, { params: { userId } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  return useMemo(
    () => ({
      conversation: data as string,
      conversationLoading: isLoading,
      conversationError: error,
      conversationValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
}

export async function sendMessage(
  conversationId: string,
  messageData: IChatMessage,
) {
  const CONVERSATIONS_URL = [endpoints.chat.conversations];

  const CONVERSATION_URL = [
    endpoints.chat.conversation,
    {
      params: { conversationId },
    },
  ];

  /**
   * Work on server
   */
  messageData.conversationId = conversationId;
  const res = await axios.post(endpoints.chat.message, messageData);
  messageData.id = res.data.id;
  /**
   * Work in local
   */
  mutate(
    CONVERSATION_URL,
    (currentData: any) => {
      const { conversation: currentConversation } = currentData;

      const conversation = {
        ...currentConversation,
        messages: [...currentConversation.messages, messageData],
      };

      return {
        conversation,
      };
    },
    false,
  );

  /**
   * Work in local
   */
  mutate(
    CONVERSATIONS_URL,
    (currentData: any) => {
      const { conversations: currentConversations } = currentData;

      const conversations: IChatConversation[] = currentConversations.map(
        (conversation: IChatConversation) =>
          conversation.id === conversationId
            ? {
                ...conversation,
                messages: [...conversation.messages, messageData],
              }
            : conversation,
      );

      return {
        conversations,
      };
    },
    false,
  );
}

export async function deleteChatMessage(
  conversationId: string,
  messageId: string,
) {
  const CONVERSATIONS_URL = [endpoints.chat.conversations];

  const CONVERSATION_URL = [
    endpoints.chat.conversation,
    {
      params: { conversationId },
    },
  ];

  /**
   * Work on server
   */
  await axios.delete(`${endpoints.chat.deleteMessage}${messageId}`);

  /**
   * Work in local
   */
  mutate(
    CONVERSATION_URL,
    (currentData: any) => {
      const { conversation: currentConversation } = currentData;

      const updatedMessages = currentConversation.messages.filter(
        (message: IChatMessage) => message.id !== messageId,
      );

      const conversation = {
        ...currentConversation,
        messages: updatedMessages,
      };

      return {
        conversation,
      };
    },
    false,
  );
}

export async function deleteMessagePersistent(
  conversationId: string,
  messageId: string,
) {
  const CONVERSATION_URL = [
    endpoints.chat.conversation,
    {
      params: { conversationId },
    },
  ];

  /**
   * Work on server
   */
  await axios.delete(`${endpoints.chat.deleteMessagePersistent}${messageId}`);

  /**
   * Work in local
   */
  mutate(
    CONVERSATION_URL,
    (currentData: any) => {
      const { conversation: currentConversation } = currentData;

      const updatedMessages = currentConversation.messages.filter(
        (message: IChatMessage) => message.id !== messageId,
      );

      const conversation = {
        ...currentConversation,
        messages: updatedMessages,
      };

      return {
        conversation,
      };
    },
    false,
  );
}

export async function deleteChatAllMessage(conversationId: string) {
  //  alert(conversationId)
  const CONVERSATIONS_URL = [endpoints.chat.conversations];

  const CONVERSATION_URL = [
    endpoints.chat.conversation,
    {
      params: { conversationId },
    },
  ];
  /**
   * Work on server
   */
  await axios.delete(`${endpoints.chat.deleteAllMessage}${conversationId}`);

  /**
   * Work in local
   */
  mutate(
    CONVERSATION_URL,
    (currentData: any) => {
      const { conversation: currentConversation } = currentData;

      const conversation = {
        ...currentConversation,
        messages: [],
      };

      return {
        conversation,
      };
    },
    false,
  );
}

export async function sendMessageWithFile(
  conversationId: string,
  file: File,
  senderId: string,
) {
  const CONVERSATIONS_URL = [endpoints.chat.conversations];

  const CONVERSATION_URL = [
    endpoints.chat.conversation,
    {
      params: { id: conversationId },
    },
  ];

  /**
   * Work on server
   */
  const messageData: IChatMessage = {
    attachments: [],
    body: file.name,
    contentType: file.type,
    conversationId: conversationId,
    createdAt: new Date(),
    id: uuidv4(),
    senderId: senderId,
  };
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `/conversation/upload-file/${conversationId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  messageData.url = res.data;

  /**
   * Work in local
   */
  mutate(
    CONVERSATIONS_URL,
    (currentData: any) => {
      const { conversations: currentConversations } = currentData;

      const conversations: IChatConversation[] = currentConversations.map(
        (conversation: IChatConversation) =>
          conversation.id === conversationId
            ? {
                ...conversation,
                messages: [...conversation.messages, messageData],
              }
            : conversation,
      );

      return {
        conversations,
      };
    },
    false,
  );
}

export async function createConversation(conversationData: IChatConversation) {
  const URL = [endpoints.chat.conversations];

  const createData = {
    message: conversationData.messages[0].body,
    participants: conversationData.participants.map(
      (particapant) => particapant.id,
    ),
  };
  const res = await axios.post(endpoints.chat.conversation, createData);
  if (res.data && res.data.messages && res.data.messages.length > 1) {
    return res.data;
  }

  mutate(
    URL,
    (currentData: any) => {
      const conversations: IChatConversation[] = [
        ...currentData.conversations,
        conversationData,
      ];
      return {
        ...currentData,
        conversations,
      };
    },
    false,
  );

  return res.data;
}

export async function createConversationForTrade(
  conversationData: IChatConversationTrade,
) {
  const res = await axios.post(endpoints.chat.conversation, conversationData);
  return res.data;
}

export async function clickConversation(conversationId: string) {
  const URL = endpoints.chat.conversations;

  /**
   * Work on server
   */
  await axios.get(endpoints.chat.markAsSeen, {
    params: { conversationId },
  });

  /**
   * Work in local
   */
  mutate(
    [URL],
    (currentData: any) => {
      const conversations: IChatConversations = currentData.conversations.map(
        (conversation: IChatConversation) =>
          conversation.id === conversationId
            ? { ...conversation, unreadCount: 0 }
            : conversation,
      );

      return {
        ...currentData,
        conversations,
      };
    },
    false,
  );
}
