import React, { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Mention, MentionsInput } from "react-mentions";
import defaultStyle from "./defaultStyle";
import axios, { endpoints } from "../../utils/axios";
import { MentionUser } from "../../utils/mention"; // Assuming you have react-mentions installed

interface CustomInputProps {
  name: string;
  onMentionSelect?: (mention: string) => void; // Optional callback for mention selection
}

interface IUser {
  id: string;
  display: string;
}

export default function RHFMentionsInput({
  name,
  onMentionSelect,
  ...otherProps
}: CustomInputProps) {
  const { control } = useFormContext();
  const [users, setUser] = useState<MentionUser[]>([]);

  const fetchSuggestions = async (
    query: string,
    callback: (suggestions: IUser[]) => void,
  ) => {
    // API'nizin URL'si ve sorgu parametresi
    const apiUrl = `${endpoints.user.mentionUsers}?searchText=${query}`;
    try {
      const response = await axios.get(apiUrl);
      const data = await response.data;
      setUser(data);
      // Sunucudan dönen veriyi `react-mentions`'ın beklediği formata çevir
      const formattedSuggestions = data.map((user: any) => ({
        id: user.userName, // Kullanıcı ID'si
        display: user.userName, // Kullanıcı adı veya gösterilecek metin
      }));
      callback(formattedSuggestions);
    } catch (error) {
      console.error("Fetching suggestions failed:", error);
      callback([]);
    }
  };

  const displayTransformHandler = useCallback(
    (id: string, display: string) => {
      const user = users.find((item) => item.id === id);
      return `@${user?.userName || display}`;
    },
    [users],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error: fieldError } }) => (
        <MentionsInput
          {...field}
          value={field.value}
          onChange={({ target: { value } }) => {
            // Destructuring here
            field.onChange(value);
          }}
          style={defaultStyle}
          {...otherProps}
        >
          <Mention
            trigger="@"
            data={fetchSuggestions}
            style={{ backgroundColor: "#daf4fa" }}
            displayTransform={displayTransformHandler}
          />
        </MentionsInput>
      )}
    />
  );
}
