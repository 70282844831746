import { enUS, trTR } from "@mui/material/locale";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.

export const allLangs = [
  {
    label: "Türkçe",
    value: "tr",
    systemValue: trTR,
    icon: "flagpack:tr",
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "flagpack:gb-nir",
  },
];

export const defaultLang = allLangs[0]; // Turkish

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
