import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// assets
import Iconify from "../../../components/iconify";
import FormProvider, { RHFCode } from "../../../components/hook-form";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "../../../routes/hook";
import { useTranslate } from "../../../locales";
import { useAuthContext } from "../../../auth/hooks";
import { useResponsive } from "../../../hooks/use-responsive";
import {
  JWT_DIALOG_VIEW_FORM_NAMES,
  USER_EMAIL_MAX_CHARACTERS,
} from "../../../utils/constants/constants";
import { PATH_AFTER_LOGIN } from "../../../config";
import axios, { endpoints } from "../../../utils/axios";
import { RouterLink } from "../../../routes/components";
import { paths } from "../../../routes/paths";
import { SentIcon } from "../../../assets/icons";
import CountdownWidget from "../../../utils/countdown-widget";
import { CircularProgress } from "@mui/material";
import { JwtDialogViewProps } from "../../../types/user";
import Button from "@mui/material/Button";

export default function ModernVerifyNewUserView({
  handleFormChange,
}: JwtDialogViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { t } = useTranslate();
  const { validateAccount } = useAuthContext();
  const mdUp = useResponsive("up", "md");
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [loading, setLoading] = useState(false);

  const NewPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("emailValidMsg"))
      .max(USER_EMAIL_MAX_CHARACTERS, t("userMailMaxCharacter")),
    code: Yup.string()
      .min(6, t("codeMustBeAtLeast6Characters"))
      .required(t("codeRequiredMsg")),
  });

  const defaultValues = {
    email: "",
    code: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    const emailRecovery = sessionStorage.getItem("email-recovery");
    if (emailRecovery) {
      methods.setValue("email", emailRecovery);
    }
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await validateAccount?.(data.email, data.code);
      enqueueSnackbar(t("userVerifySuccessful"));
      sessionStorage.removeItem("email-recovery");
      router.push(PATH_AFTER_LOGIN);
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      enqueueSnackbar(errMsg, { variant: "error" });
      methods.setValue("code", "");
    }
  });
  const resendCode = useCallback(async () => {
    setLoading(true);
    try {
      await axios.post(endpoints.auth.resendValidateAccountCode, {
        email: methods.getValues("email"),
      });
      enqueueSnackbar(t("userVerifyCodeResend"));
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      enqueueSnackbar(errMsg, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, []);

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFCode name="code" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {t("verifyMyMail")}
      </LoadingButton>

      <Stack alignItems="center">
        <CountdownWidget />
        <Typography variant="body2" mt={2}>
          {t("dontHaveCode")}
        </Typography>
        <Typography
          mt={1}
          variant="subtitle2"
          onClick={resendCode}
          sx={{
            cursor: "pointer",
            color: isLight
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        >
          {loading ? <CircularProgress size={16} /> : t("resendCode")}
        </Typography>
      </Stack>

      {handleFormChange ? (
        <Button
          onClick={() => handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.REGISTER)}
        >
          {" "}
          {t("returnToSignUp")}{" "}
        </Button>
      ) : (
        <Link
          component={RouterLink}
          href={paths.auth.jwt.register}
          color="inherit"
          variant="subtitle2"
          sx={{
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          <Iconify icon="eva:arrow-ios-back-fill" width={16} />
          {t("returnToSignUp")}
        </Link>
      )}
    </Stack>
  );

  const renderHead = (
    <>
      <SentIcon sx={{ height: 96 }} />

      <Stack spacing={3} sx={{ my: 5, textAlign: mdUp ? "inherit" : "center" }}>
        <Typography variant="h3">
          {t("verificationCodeSentSuccessfully")}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("resetPasswordMsg")}
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
