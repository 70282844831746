import { paths } from "../../routes/paths";

import Iconify from "../../components/iconify";

export const navConfig = [
  {
    title: "Anasayfa",
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.dashboard.root,
  },
  {
    title: "Bizifest",
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.whatIsBizifest,
  },
  {
    title: "Sıkça Sorulan Sorular",
    path: paths.faqs,
    icon: <Iconify icon="solar:file-bold-duotone" />,
  },
  {
    title: "Yenilikler",
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: paths.changelog,
  },
  {
    title: "Blog",
    icon: <Iconify icon="uim:blogger-alt" />,
    path: paths.dashboard.blog.root,
  },
];
