import { PROFILE, STORE_API } from "../config";

export function fGetImageFromStore(bucket: string, fileName?: string) {
  if (!fileName) return "";
  if (PROFILE === "dev") {
    return `${STORE_API}dev-${bucket}/${fileName}`;
  } else if (PROFILE === "test") {
    return `${STORE_API}test-${bucket}/${fileName}`;
  } else {
    return `${STORE_API}${bucket}/${fileName}`;
  }
}

export function capitalizeFirstLetter(inputString: string): string {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function capitalizeFirstLetterOthersSmall(inputString: string): string {
  return `${inputString.charAt(0).toUpperCase()}${inputString
    .slice(1)
    .toLowerCase()}`;
}

// her kelimeyi büyük harfle başlat
export function capitalizeWords(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function generatePostClickURL(title: string, id: string) {
  let defaultTinyURL = "";
  if (title) {
    defaultTinyURL = title.slice(0, 49);
    defaultTinyURL = removeSpecialCharacters(defaultTinyURL);
  } else {
    // Handle the case where title is undefined (e.g., return an empty string)
    defaultTinyURL = "";
  }

  // Use the replace method to remove special characters
  defaultTinyURL = removeSpecialCharacters(defaultTinyURL);
  defaultTinyURL = `${defaultTinyURL
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")}-${id}`;

  return defaultTinyURL;
}

function removeSpecialCharacters(text: string) {
  // Alphanumeric characters and space regex
  // Metin parametresi undefined ise işlem yapmadan geri dön
  if (!text) return "";

  return text.replace(/[^a-zA-Z0-9ğüşıöçĞÜŞİÖÇ\s]/g, "");
}

export function extractValueFromGeneratedUrl(url: string) {
  const parts = url.split("-");
  return parts[parts.length - 1];
}

export function addSpaceEvery30Characters(text: string): string {
  const chunkSize = 30;
  const chunks: string[] = [];

  for (let i = 0; i < text.length; i += chunkSize) {
    const chunk = text.slice(i, i + chunkSize);

    // Eğer metin 30 karakterden küçükse veya zaten boşluk varsa direkt ekle
    if (chunk.length < chunkSize || /\s+/.test(chunk)) {
      chunks.push(chunk);
    } else {
      // Boşluk olmayan 30 karakterlik kısmın arasına boşluk ekle
      chunks.push(
        chunk.slice(0, chunkSize - 1) + " " + chunk.slice(chunkSize - 1),
      );
    }
  }

  return chunks.join("");
}
