import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// layouts
import MainLayout from "../../layouts/main";
import SimpleLayout from "../../layouts/simple";
import CompactLayout from "../../layouts/compact";
import { SplashScreen } from "../../components/loading-screen";

export const HomePage = lazy(() => import("../../pages/home"));
const Page500 = lazy(() => import("../../pages/500"));
const Page403 = lazy(() => import("../../pages/403"));
const Page404 = lazy(() => import("../../pages/404"));
const FaqsPage = lazy(() => import("../../pages/faqs"));
const ForumRulesPage = lazy(() => import("../../pages/legacy"));
//const PaymentPage = lazy(() => import('../../pages/payment'));
const ComingSoonPage = lazy(() => import("../../pages/coming-soon"));
const ChangelogPage = lazy(() => import("../../pages/changelog"));
const TradeSystemExplanationPage = lazy(
  () => import("../../pages/trade-system"),
);
const CoinPage = lazy(() => import("../../pages/coin"));
const ContactPage = lazy(() => import("../../pages/contact"));
const MaintenancePage = lazy(() => import("../../pages/maintenance"));

// BLOG
const PostListPage = lazy(() => import("../../pages/post/list"));
const PostDetailsPage = lazy(() => import("../../pages/post/details"));
const PostTradeDetailsPage = lazy(
  () => import("../../pages/post/trade-details"),
);
const GiveawayPromotionPage = lazy(
  () => import("../../pages/giveaway-promotion"),
);

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: "sikca-sorulan-sorular", element: <FaqsPage /> },
      { path: "yenilikler", element: <ChangelogPage /> },
      /*   {
                   path: 'blog',
                   children: [
                       {element: <BlogHomePage/>, index: true},
                       {path: ':id', element: <BlogHomeDetailsPage/>},
                   ],
               },*/

      { path: "ticaret-sistemi", element: <TradeSystemExplanationPage /> },
      { path: "coin", element: <CoinPage /> },
      { path: "iletisim", element: <ContactPage /> },
      { path: "cekilis-tanitim", element: <GiveawayPromotionPage /> },
      { path: "yasal/:section", element: <ForumRulesPage /> },

      {
        path: "konu-share",
        children: [
          { element: <PostListPage />, index: true },
          { path: "liste", element: <PostListPage /> },
          { path: ":id", element: <PostDetailsPage /> },
          { path: "ticaret/:id", element: <PostTradeDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
      //  { path: 'pricing', element: <PricingPage /> },
      // { path: 'payment', element: <PaymentPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: "yakinda", element: <ComingSoonPage /> },
      { path: "bakim", element: <MaintenancePage /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <Page404 /> },
      { path: "403", element: <Page403 /> },
    ],
  },
];
