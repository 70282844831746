import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useTranslate } from "../../locales";

type Props = {
  title?: string;
};

export default function LoadingOverlay({ title }: Props) {
  const { t } = useTranslate();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: (theme) => theme.zIndex.tooltip + 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <Typography variant="caption" sx={{ mt: 1 }}>
        {title ? t(title) : t("loading")}
      </Typography>
    </Box>
  );
}
