import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { paths } from "../../../routes/paths";
import { RouterLink } from "../../../routes/components";
// config
import { useBoolean } from "../../../hooks/use-boolean";
import { useAuthContext } from "../../../auth/hooks";
import Iconify from "../../../components/iconify";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { useTranslate } from "../../../locales";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  GOOGLE_ANALYTIC_EVENT_NAME,
  JWT_DIALOG_VIEW_FORM_NAMES,
  PASSWORD_REGEX,
  USER_NAME_OR_EMAIL_LOGIN_MAX_CHARACTERS,
  USER_PASSWORD_MAX_CHARACTERS,
  USER_PASSWORD_MIN_CHARACTERS,
} from "../../../utils/constants/constants";
import { HOST_API, OAUTH_TYPES } from "../../../config";
import Button from "@mui/material/Button";
import { JwtDialogViewProps } from "../../../types/user";
import { logEvent } from "../../../analytics";

const oauthTypes = OAUTH_TYPES.split(",");

export default function JwtLoginView({ handleFormChange }: JwtDialogViewProps) {
  const { t } = useTranslate();

  const { login, closeAuthDialog } = useAuthContext();

  const [errorMsg] = useState("");

  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    emailOrUserName: Yup.string()
      .required(t("usernameOrMailRequired"))
      .max(
        USER_NAME_OR_EMAIL_LOGIN_MAX_CHARACTERS,
        t("usernameOrMailRequired"),
      ),
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(PASSWORD_REGEX, t("passwordHelperText"))
      .min(USER_PASSWORD_MIN_CHARACTERS, t("userPasswordMinCharacter"))
      .max(USER_PASSWORD_MAX_CHARACTERS, t("userPasswordMaxCharacter")),
  });

  // todo canliya cikildiginda burasi kullanici bazli gelecek
  const defaultValues = {
    emailOrUserName: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.emailOrUserName, data.password);
      closeAuthDialog && closeAuthDialog();
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.LOGIN, { method: "email" });
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      reset();
      enqueueSnackbar(errMsg, { variant: "error" });
    }
  });

  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const handleLogin = async (provider: string) => {
    try {
      window.location.href = HOST_API + "auth/auth/" + provider;
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.LOGIN, { method: `${provider}` });
    } catch (error) {
      console.error("Failed to initiate OAUTH login:", error);
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.LOGIN, {
        method: `${provider}`,
        error: "error = " + error,
      });
    }
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">{t("sign_into_bizifest")}</Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2">{t("new_user")}</Typography>
        {handleFormChange ? (
          <Button
            onClick={() =>
              handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.REGISTER)
            }
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
          >
            {" "}
            {t("create_an_account")}{" "}
          </Button>
        ) : (
          <Link
            component={RouterLink}
            href={paths.auth.jwt.register}
            variant="subtitle2"
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
          >
            {t("create_an_account")}
          </Link>
        )}
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField
        name="emailOrUserName"
        label={t("usernameOrMail")}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <RHFTextField
        name="password"
        label={t("password")}
        InputLabelProps={{
          shrink: true,
        }}
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {handleFormChange ? (
        <Button
          onClick={() =>
            handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.FORGOT_PASSWORD)
          }
        >
          {" "}
          {t("forgot_password")}{" "}
        </Button>
      ) : (
        <Link
          variant="body2"
          color="inherit"
          underline="always"
          href={paths.auth.jwt.forgotPassword}
          sx={{ alignSelf: "flex-end" }}
        >
          {t("forgot_password")}
        </Link>
      )}

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {t("login")}
      </LoadingButton>

      {oauthTypes.map((provider, index) => (
        <Button
          key={provider}
          fullWidth
          color="inherit"
          size="medium"
          variant="outlined"
          startIcon={
            index === 0 ? <Iconify icon="flat-color-icons:google" /> : undefined
          }
          onClick={() => handleLogin(provider)}
        >
          {`${provider.charAt(0).toUpperCase() + provider.slice(1)} ile Giriş Yap`}
        </Button>
      ))}
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
