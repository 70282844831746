import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useTranslate } from "../../../locales";
import {
  INotificationItem,
  INotificationItemUser,
} from "../../../types/member-notification";
import { MemberNotificaitonType } from "./index";
import { paths } from "../../../routes/paths";
import { useRouter } from "../../../routes/hook";
import AvatarGroup, { avatarGroupClasses } from "@mui/material/AvatarGroup";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { fGetImageFromStore } from "../../../utils/format-string";
import { fToNow } from "../../../utils/format-time";
import Link from "@mui/material/Link";
import { RouterLink } from "../../../routes/components";
import TruncateText from "../../../utils/truncate-text";
import { useFollowAction } from "../../../api/follower";
import React from "react";
import Label from "../../../components/label";

type Props = {
  notification: INotificationItem;
  onCloseDrawer?: () => void; // Yeni prop
};
export default function NotificationItem({
  notification,
  onCloseDrawer,
}: Props) {
  const { type, args, operationId, notifications } = notification;
  const { t } = useTranslate();
  const router = useRouter();

  const getText =
    notifications.length > 2
      ? notifications[0].name +
        ", " +
        notifications[1].name +
        " ve " +
        (notifications.length - 2) +
        " kişi "
      : notifications.map((user) => user.name).join(" ve ");

  const isPost =
    notification.type === MemberNotificaitonType.NotifyPostPublish ||
    notification.type === MemberNotificaitonType.NotifyPostCommentPublish ||
    notification.type === MemberNotificaitonType.NotifyPostLike;

  const isTrade = notification.type === MemberNotificaitonType.NotifyTradeOffer;

  const renderAvatar = (
    <AvatarGroup
      max={2}
      sx={{
        [`& .${avatarGroupClasses.avatar}`]: {
          width: 32,
          height: 32,
        },
      }}
    >
      {notifications.map((user: INotificationItemUser) => {
        return (
          <Tooltip key={user.id} title={user.name}>
            <Avatar
              onClick={() => {
                router.push(paths.dashboard.user.profile(user.name));
              }}
              alt={user.name}
              src={
                user.avatar ? fGetImageFromStore("thumbnail", user.avatar) : ""
              }
            />
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={
        <Typography>
          {notifications.length > 2 ? (
            // İlk kişinin profil linki
            <>
              <Link
                sx={{ color: "inherit", textDecoration: "none" }}
                href={paths.dashboard.user.profile(notifications[0].name)}
                onClick={onCloseDrawer}
              >
                <strong>{notifications[0].name}</strong>
              </Link>
              , {/* İkinci kişinin profil linki */}
              <Link
                sx={{ color: "inherit", textDecoration: "none" }}
                href={paths.dashboard.user.profile(notifications[1].name)}
                onClick={onCloseDrawer}
              >
                <strong>{notifications[1].name}</strong>
              </Link>{" "}
              ve {notifications.length - 2} kişi
            </>
          ) : (
            // Tek kişi ya da iki kişi ise profil linkleri

            notifications.map((user, index) => (
              <React.Fragment key={user.id}>
                <Link
                  sx={{ color: "inherit", textDecoration: "none" }}
                  href={paths.dashboard.user.profile(user.name)}
                  onClick={onCloseDrawer}
                >
                  <strong>{user.name}</strong>
                </Link>
                {index !== notifications.length - 1 && <span> ve </span>}
              </React.Fragment>
            ))
          )}
          &nbsp;{t(notificationMessage(type))}
        </Typography>
      }
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: "caption", color: "text.disabled" }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: "currentColor",
                mx: 0.5,
                borderRadius: "50%",
              }}
            />
          }
        >
          {notifications && fToNow(notifications[0].time)}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = !notification.view && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: "50%",
        bgcolor: "info.main",
        position: "absolute",
      }}
    />
  );

  const followerAction = (
    <Stack alignItems="flex-start">
      <FollowerInfo userId={notifications[0].id} />
    </Stack>
  );

  const tradeAction = (
    <Stack alignItems="flex-start">
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          router.push(paths.dashboard.post.tradeDetails(operationId));
          onCloseDrawer && onCloseDrawer();
        }}
      >
        {t("show")}
      </Button>
    </Stack>
  );

  const argsView = (
    <Box
      sx={{
        borderRadius: 1.5,
        color: "text.secondary",
        bgcolor: "background.neutral",
      }}
      component={RouterLink}
      href={
        isPost || isTrade
          ? isTrade
            ? paths.dashboard.post.tradeDetails(operationId)
            : paths.dashboard.post.details(operationId)
          : ""
      }
      onClick={onCloseDrawer}
    >
      <Typography
        sx={{
          px: 1.5,
          my: 1.5,
        }}
        variant="body2"
      >
        {TruncateText(args || "", 40)}
      </Typography>
    </Box>
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: "flex-start",
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}
      {renderAvatar}

      <Stack sx={{ flexGrow: 1, mx: 1 }} spacing={2}>
        <>
          {renderText}
          {args && args != "" && argsView}
        </>
        {notification.type === MemberNotificaitonType.NotifyFollower &&
          followerAction}

        {notification.type === MemberNotificaitonType.NotifyTradeOffer &&
          tradeAction}
      </Stack>
    </ListItemButton>
  );
}

type PostCommentFollowMenuProps = {
  userId: string;
};

function FollowerInfo({ userId }: PostCommentFollowMenuProps) {
  const { followed, followerLoading, handleFollowedClick } =
    useFollowAction(userId);
  const { t } = useTranslate();

  return (
    <>
      {followed ? (
        <Label color={"success"}>{t("followingNow")}</Label>
      ) : (
        <Button size="small" variant="contained" onClick={handleFollowedClick}>
          {t("follow")}
        </Button>
      )}
    </>
  );
}

// First, you need to move the useTranslate() hook to the top level of your component or custom hook so that it's called within a React function component.

function notificationMessage(type: number) {
  let format;
  switch (type) {
    case MemberNotificaitonType.NotifyFollower:
      format = "notificationStartedFollowingYou";
      break;
    case MemberNotificaitonType.NotifyPostLike:
      format = "notificationLikedTopic";
      break;
    case MemberNotificaitonType.NotifyTradeOffer:
      format = "notificationSendOffer";
      break;
    case MemberNotificaitonType.NotifyPostPublish:
      format = "yeni bir konu paylaştı";
      break;
    case MemberNotificaitonType.NotifyPostCommentLike:
      format = "notificationLikedYourComment";
      break;
    case MemberNotificaitonType.NotifyPostCommentPublish:
      format = "notificationCommentedYourPost";
      break;
    default:
      format = "notificationNewNotification";
  }
  return format;
}
