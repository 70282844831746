import { _mock } from "./_mock";

// APP

export const _appRelated = [
  "Chrome",
  "Drive",
  "Dropbox",
  "Evernote",
  "Github",
].map((name, index) => {
  const system = [2, 4].includes(index) ? "Windows" : "Mac";

  const price = [2, 4].includes(index) ? _mock.number.price(index) : 0;

  const shortcut =
    (name === "Chrome" && "/assets/icons/app/ic_chrome.svg") ||
    (name === "Drive" && "/assets/icons/app/ic_drive.svg") ||
    (name === "Dropbox" && "/assets/icons/app/ic_dropbox.svg") ||
    (name === "Evernote" && "/assets/icons/app/ic_evernote.svg") ||
    "/assets/icons/app/ic_github.svg";

  return {
    id: _mock.id(index),
    name,
    price,
    system,
    shortcut,
    ratingNumber: _mock.number.rating(index),
    totalReviews: _mock.number.nativeL(index),
  };
});

export const _appInstalled = [
  "Germany",
  "England",
  "France",
  "Korean",
  "USA",
].map((name, index) => ({
  id: _mock.id(index),
  name,
  android: _mock.number.nativeL(index),
  windows: _mock.number.nativeL(index + 1),
  apple: _mock.number.nativeL(index + 2),
  flag: [
    "flagpack:de",
    "flagpack:gb-nir",
    "flagpack:fr",
    "flagpack:kr",
    "flagpack:us",
  ][index],
}));

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  avatarUrl: _mock.image.avatar(index),
  totalFavorites: _mock.number.nativeL(index),
}));

export const _appInvoices = [...Array(5)].map((_, index) => {
  const category = ["Android", "Mac", "Windows", "Android", "Mac"][index];

  const status = ["paid", "out of date", "progress", "paid", "paid"][index];

  return {
    id: _mock.id(index),
    invoiceNumber: `INV-199${index}`,
    price: _mock.number.price(index),
    category,
    status,
  };
});

export const _appFeatured = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.postTitle(index),
  description: _mock.sentence(index),
  coverUrl: _mock.image.cover(index),
}));

// ANALYTIC

export const _analyticTasks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.taskNames(index),
}));

export const _analyticPosts = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  postedAt: _mock.time(index),
  title: _mock.postTitle(index),
  coverUrl: _mock.image.cover(index),
  description: _mock.sentence(index),
}));

export const _analyticOrderTimeline = [...Array(5)].map((_, index) => {
  const title = [
    "1983, orders, TL4220",
    "12 Invoices have been paid",
    "Order #37745 from September",
    "New order placed #XF-2356",
    "New order placed #XF-2346",
  ][index];

  return {
    id: _mock.id(index),
    title,
    type: `order${index + 1}`,
    time: _mock.time(index),
  };
});

export const _giveawayEarnTimeline = [...Array(5)].map((_, index) => {
  const title = [
    "Üye Daveti (+1 Bilet!)",
    "Konu Açılışı (+1 Bilet!)",
    "Beğeni Gönderimi (+1 Bilet!)",
    "Favori Gönderimi (+1 Bilet!)",
    "Yorum Gönderimi (+1 Bilet!)",
  ][index];

  return {
    id: _mock.id(index),
    title,
    type: `order${index + 1}`,
    time: _mock.time(index),
  };
});

export const _tradeExplanationForSellers = [
  {
    type: `tradeExplanationForSellersType01`,
    label: "tradeExplanationForSellersLabel01",
    description: `tradeExplanationForSellersDescription01`,
  },
  {
    type: `tradeExplanationForSellersType02`,
    label: "tradeExplanationForSellersLabel02",
    description: "tradeExplanationForSellersDescription02",
  },
  {
    type: `tradeExplanationForSellersType03`,
    label: "tradeExplanationForSellersLabel03",
    description: `tradeExplanationForSellersDescription03`,
  },
  {
    type: `tradeExplanationForSellersType04`,
    label: "tradeExplanationForSellersLabel04",
    description: `tradeExplanationForSellersDescription04`,
  },
  {
    type: `tradeExplanationForSellersType05`,
    label: "tradeExplanationForSellersLabel05",
    description: `tradeExplanationForSellersDescription05`,
  },
];

export const _tradeExplanationForBuyers = [
  {
    type: `tradeExplanationForBuyersType01`,
    label: "tradeExplanationForBuyersLabel01",
    description: `tradeExplanationForBuyersDescription01`,
  },
  {
    type: `tradeExplanationForBuyersType02`,
    label: "tradeExplanationForBuyersLabel02",
    description: "tradeExplanationForBuyersDescription02",
  },
  {
    type: `tradeExplanationForBuyersType03`,
    label: "tradeExplanationForBuyersLabel03",
    description: `tradeExplanationForBuyersDescription03`,
  },
  {
    type: `tradeExplanationForBuyersType04`,
    label: "tradeExplanationForBuyersLabel04",
    description: `tradeExplanationForBuyersDescription04`,
  },
  {
    type: `tradeExplanationForBuyersType05`,
    label: "tradeExplanationForBuyersLabel05",
    description: `tradeExplanationForBuyersDescription05`,
  },
];

export const _analyticTraffic = [
  {
    value: "facebook",
    label: "Facebook",
    total: _mock.number.nativeL(1),
    icon: "eva:facebook-fill",
  },
  {
    value: "google",
    label: "Google",
    total: _mock.number.nativeL(2),
    icon: "eva:google-fill",
  },
  {
    value: "linkedin",
    label: "Linkedin",
    total: _mock.number.nativeL(3),
    icon: "eva:linkedin-fill",
  },
  {
    value: "twitter",
    label: "Twitter",
    total: _mock.number.nativeL(4),
    icon: "eva:twitter-fill",
  },
];

export const _profileQuickView = [
  {
    value: "facebook",
    label: "Bizifest Puanı",
    total: _mock.number.nativeL(1),
    icon: "eva:facebook-fill",
  },
  {
    value: "google",
    label: "Konular",
    total: _mock.number.nativeL(2),
    icon: "eva:google-fill",
  },
  {
    value: "linkedin",
    label: "Yorumlar",
    total: _mock.number.nativeL(3),
    icon: "eva:linkedin-fill",
  },
  {
    value: "twitter",
    label: "Etkileşimler",
    total: _mock.number.nativeL(4),
    icon: "eva:twitter-fill",
  },
];

// ECOMMERCE

export const _ecommerceSalesOverview = [
  "Total Profit",
  "Total Income",
  "Total Expenses",
].map((label, index) => ({
  label,
  totalAmount: _mock.number.price(index) * 100,
  value: _mock.number.percent(index),
}));

export const _profileSkillsOverview = ["Android", "HTML", "CSS"].map(
  (label, index) => ({
    label,
    totalAmount: _mock.number.price(index) * 100,
    value: _mock.number.percent(index),
  }),
);

export const _tradeExplanationOverviewItems = [
  {
    label: "Ticari Konu Açılır",
    description: `Hizmet/Ürün alım ya da satımı hakkında bir ticari konu açılır. Ticari konu, Bizifest tarafından normal forum konularının ticaret için özelleştirilmiş ve kapsamlı halidir.`,
  },
  {
    label: "Ticari Konuya Teklifler Gelir",
    description:
      "Açılan ticari konuya diğer üyelerden teklifler gelir. Bu teklifler ticari konuda yer alan şartlardan farklılık gösterebilir.",
  },
  {
    label: "Ticari Konu Sahibi Uygun Gördüğü Teklifi Onaylar",
    description: `Konu sahibi kendi ticari konusuna gelen teklifleri inceler. Kendisine en uygun teklifi ya da teklifleri seçerek ticaret yapmaya hazır olduğunu belirtir ve bu teklifleri onaylar.`,
  },
  {
    label: "Teklifi Kabul Edilen Üye, Ticareti Başlatır",
    description: `Artık alıcı ve satıcı arasındaki tüm detaylarda anlaşma sağlanmıştır. Teklif veren üye son bir kez daha anlaşmanın detaylarını okur. Konu sahibi tarafından teklifi uygun görülen ve onaylanan kullanıcı ticareti başlatır.`,
  },
  {
    label: "Alıcı ve Satıcının Onayı ile Ticaret Sonlanır",
    description: `İstenen ürün/hizmet teklifteki ve varsa sözleşmedeki gibi teslim edilir ve ücreti alınır. Alıcı ve satıcı durumdan memnunsa Ticareti Sonlandır diyerek alışverişini sonlandırırlar.`,
  },
];

export const _ecommerceBestSalesman = [...Array(5)].map((_, index) => {
  const category = [
    "CAP",
    "Branded Shoes",
    "Headphone",
    "Cell Phone",
    "Earings",
  ][index];

  const flag = [
    "flagpack:de",
    "flagpack:gb-nir",
    "flagpack:fr",
    "flagpack:kr",
    "flagpack:us",
  ][index];

  return {
    id: _mock.id(index),
    flag,
    category,
    rank: `Top ${index + 1}`,
    email: _mock.email(index),
    name: _mock.fullName(index),
    totalAmount: _mock.number.price(index),
    avatarUrl: _mock.image.avatar(index + 8),
  };
});

export const _profilePunishmentData = [...Array(5)].map((_, index) => {
  const punishmentCategory = ["3 Ay", "6 Ay", "3 Gün", "1 Gün", "3 Hafta"][
    index
  ];

  const punishmentStartEndDate = [
    "23/05/2023 - 01/07/2023",
    "31/08/2023 - 01/09/2023",
    "23/05/2023 - 25/05/2023",
    "23/05/2023 - 25/05/2023",
    "23/05/2023 - 25/05/2023",
  ][index];

  const punishmentType = [
    "Uzaklaştırma",
    "Uzaklaştırma",
    "Uzaklaştırma",
    "Uzaklaştırma",
    "Uzaklaştırma",
  ][index];

  const punishmentPoint = ["15", "25", "10", "50", "5"][index];

  const punishmentModerator = [
    "Admin",
    "Bisiniss",
    "Plus",
    "Bisiniss",
    "Bisiniss",
  ][index];

  const punishmentReason = [
    "Ticari konu dışı yorum yapmak",
    "Hakaret",
    "Argo Kullanımı",
    "Ticari konu dışı yorum yapmak",
    "Ticari konu dışı yorum yapmak",
  ][index];

  return {
    id: _mock.id(index),
    punishmentStartEndDate,
    punishmentCategory,
    punishmentType,
    punishmentPoint,
    punishmentModerator,
    punishmentReason,
    rank: `Top ${index + 1}`,
    email: _mock.email(index),
    name: _mock.fullName(index),
    totalAmount: _mock.number.price(index),
    avatarUrl: _mock.image.avatar(index + 8),
  };
});

export const _ecommerceLatestProducts = [...Array(5)].map((_, index) => {
  const colors = (index === 0 && [
    "#2EC4B6",
    "#E71D36",
    "#FF9F1C",
    "#011627",
  ]) ||
    (index === 1 && ["#92140C", "#FFCF99"]) ||
    (index === 2 && [
      "#0CECDD",
      "#FFF338",
      "#FF67E7",
      "#C400FF",
      "#52006A",
      "#046582",
    ]) ||
    (index === 3 && ["#845EC2", "#E4007C", "#2A1A5E"]) || ["#090088"];

  return {
    id: _mock.id(index),
    colors,
    name: _mock.productName(index),
    price: _mock.number.price(index),
    coverUrl: _mock.image.product(index),
    priceSale: [1, 3].includes(index) ? _mock.number.price(index) : 0,
  };
});

export const _ecommerceNewProducts = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.productName(index),
  coverUrl: _mock.image.product(index),
}));

export const _giveawayList = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.giveawayTitles(index),
  description: _mock.giveawayDescriptions(index),
  coverUrl: _mock.image.product(index),
}));

// BANKING

export const _bankingContacts = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  email: _mock.email(index),
  avatarUrl: _mock.image.avatar(index),
}));

export const _bankingCreditCard = [
  {
    id: _mock.id(2),
    balance: 23432.03,
    cardType: "mastercard",
    cardHolder: _mock.fullName(2),
    cardNumber: "**** **** **** 3640",
    cardValid: "11/22",
  },
  {
    id: _mock.id(3),
    balance: 18000.23,
    cardType: "visa",
    cardHolder: _mock.fullName(3),
    cardNumber: "**** **** **** 8864",
    cardValid: "11/25",
  },
  {
    id: _mock.id(4),
    balance: 2000.89,
    cardType: "mastercard",
    cardHolder: _mock.fullName(4),
    cardNumber: "**** **** **** 7755",
    cardValid: "11/22",
  },
];

export const _bankingRecentTransitions = [
  {
    id: _mock.id(2),
    name: _mock.fullName(2),
    avatarUrl: _mock.image.avatar(2),
    type: "Income",
    message: "Receive money from",
    category: "Annette Black",
    date: _mock.time(2),
    status: "progress",
    amount: _mock.number.price(2),
  },
  {
    id: _mock.id(3),
    name: _mock.fullName(3),
    avatarUrl: _mock.image.avatar(3),
    type: "Expenses",
    message: "Payment for",
    category: "Courtney Henry",
    date: _mock.time(3),
    status: "completed",
    amount: _mock.number.price(3),
  },
  {
    id: _mock.id(4),
    name: _mock.fullName(4),
    avatarUrl: _mock.image.avatar(4),
    type: "Receive",
    message: "Payment for",
    category: "Theresa Webb",
    date: _mock.time(4),
    status: "failed",
    amount: _mock.number.price(4),
  },
  {
    id: _mock.id(5),
    name: null,
    avatarUrl: null,
    type: "Expenses",
    message: "Payment for",
    category: "Beauty & Health",
    date: _mock.time(5),
    status: "completed",
    amount: _mock.number.price(5),
  },
  {
    id: _mock.id(6),
    name: null,
    avatarUrl: null,
    type: "Expenses",
    message: "Payment for",
    category: "Books",
    date: _mock.time(6),
    status: "progress",
    amount: _mock.number.price(6),
  },
];

// BOOKING

export const _bookings = [...Array(5)].map((_, index) => {
  const status = ["Paid", "Paid", "Pending", "Cancelled", "Paid"][index];

  const customer = {
    avatarUrl: _mock.image.avatar(index),
    name: _mock.fullName(index),
    phoneNumber: _mock.phoneNumber(index),
  };

  const destination = [...Array(5)].map((__, _index) => ({
    name: _mock.tourName(_index + 1),
    coverUrl: _mock.image.travel(_index + 1),
  }))[index];

  return {
    id: _mock.id(index),
    destination,
    status,
    customer,
    checkIn: _mock.time(index),
    checkOut: _mock.time(index),
  };
});

export const _bookingsOverview = [...Array(3)].map((_, index) => ({
  status: ["Pending", "Canceled", "Sold"][index],
  quantity: _mock.number.percent(index) * 1000,
  value: _mock.number.percent(index),
}));

export const _bookingReview = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  postedAt: _mock.time(index),
  rating: _mock.number.rating(index),
  avatarUrl: _mock.image.avatar(index),
  description: _mock.faqs_bizifest_platform_description(index),
  tags: ["Great Sevice", "Recommended", "Best Price"],
}));

export const _bookingNew = [...Array(5)].map((_, index) => ({
  guests: "3-5",
  id: _mock.id(index),
  bookedAt: _mock.time(index),
  duration: "3 days 2 nights",
  isHot: _mock.boolean(index),
  name: _mock.fullName(index),
  price: _mock.number.price(index),
  avatarUrl: _mock.image.avatar(index),
  coverUrl: _mock.image.travel(index),
}));
