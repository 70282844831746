import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default function CountdownWidget() {
  const [minutes, setMinutes] = useState(3); // Örnek olarak 3 dakika
  const [seconds, setSeconds] = useState(0); // Saniye sıfır ile başlayacak

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(intervalId);
          // Geri sayım bittiğinde burada gerekli işlemleri yapabilirsiniz
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [minutes, seconds]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      divider={<Box sx={{ mx: { xs: 0.5, sm: 0.5 } }}>:</Box>}
      sx={{ typography: "body2" }}
    >
      <TimeBlock value={minutes} />
      <TimeBlock value={seconds} />
    </Stack>
  );
}

type TimeBlockProps = {
  value: number;
};

function TimeBlock({ value }: TimeBlockProps) {
  return <Box>{value < 10 ? `0${value}` : value}</Box>;
}
