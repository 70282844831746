import Typography from "@mui/material/Typography";
import Paper, { PaperProps } from "@mui/material/Paper";
import { useTranslate } from "../../locales";
import Stack from "@mui/material/Stack";
import { Player } from "@lottiefiles/react-lottie-player";

interface Props extends PaperProps {
  query?: string;
}

export default function SearchLets({ query, sx, ...other }: Props) {
  const { t } = useTranslate();
  // @ts-ignore
  return (
    <Paper
      sx={{
        bgcolor: "unset",
        textAlign: "center",
        ...sx,
      }}
      {...other}
    >
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Player
          src={"/assets/lottie/lets_search.json"}
          className="player"
          loop
          style={{ width: "20vh" }}
          autoplay
        />
        <Typography variant="body2">{t("searchHelpText")}</Typography>
        <Typography
          variant="caption"
          sx={{ marginTop: 2, fontStyle: "italic" }}
        >
          {t("searchHelpText2")}
        </Typography>
      </Stack>
    </Paper>
  );
}
