import { m } from "framer-motion";
import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import NotificationItem from "./notification-item";
import { useTranslate } from "../../../locales";
import {
  markAsReadAll,
  useGetAllNotificationByUserId,
} from "../../../api/member-notification";
import { useSnackbar } from "../../../components/snackbar";
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import { useBoolean } from "../../../hooks/use-boolean";
import { useResponsive } from "../../../hooks/use-responsive";
import { varHover } from "../../../components/animate";
import Scrollbar from "../../../components/scrollbar";
import { useRouter } from "../../../routes/hook";
import { paths } from "../../../routes/paths";

import Link from "@mui/material/Link";

import { RouterLink } from "../../../routes/components";
import Typography from "@mui/material/Typography";

/*
 * Todo Bildirim countlar dinamik gelecek.
 *  Todo Tab geçişleri çalışmıyor
 *    */
const TABS = [
  {
    value: "all",
    label: "All",
    count: 22,
  },
];

export enum MemberNotificaitonType {
  NotifyFriendRequest = 1,
  NotifyFriendRequestApproval = 2,
  NotifyPostPublish = 3,
  NotifyPostCommentLike = 4,
  NotifyPostLike = 5,
  NotifyPostCommentPublish = 6,
  NotifyTradeOffer = 7,
  NotifyTradeOfferStatusChange = 8,
  NotifyFollower = 9,
}

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { t } = useTranslate();
  const router = useRouter();
  const smUp = useResponsive("up", "sm");

  const { notifications, notificationsLoading, totalUnRead } =
    useGetAllNotificationByUserId();
  const { enqueueSnackbar } = useSnackbar();

  const handleMarkAllAsRead = async () => {
    await markAsReadAll();
    enqueueSnackbar(t("setAsAllDidRead"));
  };

  const handleClick = () => {
    router.push(paths.dashboard.notification);
    drawer.onFalse();
  };

  const renderHead = (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"flex-start"}
        spacing={1.5}
      >
        <Link
          component={RouterLink}
          href={paths.dashboard.notification}
          color="inherit"
          underline="none"
          onClick={drawer.onFalse}
          sx={{
            "&:hover": {
              textDecoration: "underline", // Add underline on hover
              color: "inherit",
              cursor: "pointer", // Change cursor on hover
            },
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t("notifications")}
          </Typography>
        </Link>
        <Label variant={"filled"} color={"primary"}>
          {totalUnRead ? totalUnRead : 0}
        </Label>

        {!!totalUnRead && (
          <Tooltip title={t("markAllAsRead")}>
            <IconButton color="secondary" onClick={handleMarkAllAsRead}>
              <Iconify icon="eva:done-all-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification, index) => (
          <NotificationItem
            key={`${index}-${notification.operationId}`}
            notification={notification}
            onCloseDrawer={drawer.onFalse}
          />
        ))}

        {notifications && notifications.length == 0 && (
          <Box sx={{ p: 1.5, marginLeft: 2 }}>
            <Typography variant={"caption"}>
              {t("noNotificationYet")}
            </Typography>
          </Box>
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? "primary" : "default"}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        <Stack direction={"column"} justifyContent={"flex-start"}>
          {renderHead}

          <Divider />

          {renderList}
        </Stack>

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large" onClick={handleClick}>
            {t("view_all")}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
