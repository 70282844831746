import { GOOGLE_ANALYTIC_KEY } from "./config";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const initializeGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function () {
      window.dataLayer.push(arguments);
    };
  window.gtag("js", new Date());
  window.gtag("config", GOOGLE_ANALYTIC_KEY);
};

/*
export const logPageView = (pageName: string) => {
    window.gtag('event', 'sayfa_goruntuleme3', {
        page_name: pageName
    });
};
*/

export const logEvent = (
  eventName: string,
  eventParams: { [key: string]: string | number | boolean } = {},
) => {
  window.gtag("event", eventName, eventParams);
};
