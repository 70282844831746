import { _id, _postTitles } from "../_mock/assets";
import { paramCase } from "../utils/change-case";

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: "/uyelik",
  DASHBOARD: "/",
};

export const paths = {
  comingSoon: "/yakinda",
  whatIsBizifest: "/bizifest-nedir",
  maintenance: "/bakim",
  pricing: "/pricing",
  payment: "/payment",
  about: "/hakkimizda",
  contact: "/iletisim",
  forumRules: (section: string) => `/yasal/${section}`,
  faqs: "/sikca-sorulan-sorular",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  giveawayPromotion: "/cekilis-tanitim",
  badges: "/rozet",
  punishments: "/ceza",
  components: "/components",
  changelog: "/yenilikler",
  tradeSystem: "/ticaret-sistemi",
  coin: "/coin",
  /* product: {
                  root: `/product`,
                  checkout: `/product/checkout`,
                  details: (id: string) => `/product/${id}`,
                  demo: {
                    details: `/product/${MOCK_ID}`,
                  },
                },*/
  post: {
    root: `/konu-share`,
    details: (id: string) => `/konu-share/${id}`,
    tradeDetails: (id: string) => `/konu-share/ticaret/${id}`,
  },
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/giris`,
      register: `${ROOTS.AUTH}/uye-ol`,
      registerCode: (code: string) => `${ROOTS.AUTH}/uye-ol/${code}`,
      forgotPassword: `${ROOTS.AUTH}/parolami-unuttum`,
      newPassword: `${ROOTS.AUTH}/yeni-parola`,
      verifyMail: `${ROOTS.AUTH}/hesabimi-dogrula`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },

  badge: {
    root: `/panel-rozet`,
    new: `/panel-rozet/yeni`,
    edit: (id: string) => `/panel-rozet/${id}/duzenle`,
    list: `/panel-rozet/liste`,
  },

  category: {
    root: `/panel-kategori`,
    new: `/panel-kategori/yeni`,
    edit: (id: string) => `/panel-kategori/${id}/duzenle`,
    list: `/panel-kategori/liste`,
  },

  systemNotification: {
    root: `/panel-sistem-bildirimleri`,
    new: `/panel-sistem-bildirimleri/yeni`,
    edit: (id: string) => `/panel-sistem-bildirimleri/${id}/duzenle`,
    list: `/panel-sistem-bildirimleri/liste`,
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}mail`,
    chat: `${ROOTS.DASHBOARD}mesaj`,
    notification: `${ROOTS.DASHBOARD}bildirim`,
    blank: `${ROOTS.DASHBOARD}blank`,
    badges: `${ROOTS.DASHBOARD}rozet`,
    giveaway: `${ROOTS.DASHBOARD}cekilis`,
    search: `${ROOTS.DASHBOARD}arama`,
    permission: `${ROOTS.DASHBOARD}permission`,
    general: {
      app: `${ROOTS.DASHBOARD}app`,
    },
    category: {
      root: (id: string, title: string) =>
        `${ROOTS.DASHBOARD}kategori/${id}/${title}`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}kullanici`,
      new: `${ROOTS.DASHBOARD}kullanici/yeni`,
      list: `${ROOTS.DASHBOARD}kullanici/liste`,
      //cards: `${ROOTS.DASHBOARD}kullanici/cards`,
      profile: (username: string, userProfileTabName?: string) =>
        `${ROOTS.DASHBOARD}${username}/${userProfileTabName ?? ""}`,
      profileMe: `${ROOTS.DASHBOARD}kullanici/profilim`,
      account: (profileTabName: string) =>
        `${ROOTS.DASHBOARD}kullanici/hesabim/${profileTabName}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}kullanici/${id}/duzenle`,
      demo: {
        edit: `${ROOTS.DASHBOARD}kullanici/${MOCK_ID}/duzenle`,
      },
    },
    receipt: {
      root: `${ROOTS.DASHBOARD}dekont`,
      new: `${ROOTS.DASHBOARD}dekont/yeni`,
      statistic: `${ROOTS.DASHBOARD}dekont/istatistik`,
      details: (id: string) => `${ROOTS.DASHBOARD}dekont/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}dekont/${id}/duzenle`,
      demo: {
        details: `${ROOTS.DASHBOARD}dekont/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}dekont/${MOCK_ID}/duzenle`,
      },
    },
    sss: {
      root: `${ROOTS.DASHBOARD}yonetim/sikca-sorulan-sorular`,
      list: `${ROOTS.DASHBOARD}yonetim/sikca-sorulan-sorular/liste`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}konu`,
      new: `${ROOTS.DASHBOARD}konu/yeni`,
      newWithCategory: (categoryId: string) =>
        `${ROOTS.DASHBOARD}konu/kategori/${categoryId}`,
      details: (id: string) => `${ROOTS.DASHBOARD}konu/${id}`,
      detailsFromComment: (id: string) => `${ROOTS.DASHBOARD}konu/yorum/${id}`,
      tradeDetails: (id: string) => `${ROOTS.DASHBOARD}konu/ticaret/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}konu/${id}/duzenle`,
      demo: {
        details: `${ROOTS.DASHBOARD}konu/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}konu/${paramCase(MOCK_TITLE)}/duzenle`,
      },
    },
    blog: {
      root: `/blog`,
      new: `${ROOTS.DASHBOARD}blog/yeni`,
      edit: (id: string) => `${ROOTS.DASHBOARD}blog/${id}/duzenle`,
      details: (id: string) => `/blog/${id}`,
    },
    trade: {
      root: `${ROOTS.DASHBOARD}ticaret`,
      offerDetail: (id: string) =>
        `${ROOTS.DASHBOARD}ticaret/teklif-detay/${id}`,
      review: (id: string, statusCode: string, postId: string) =>
        `${ROOTS.DASHBOARD}ticaret/ticari-teklif-inceleme/${id}/${statusCode}/${postId}`,
    },
    order: {
      root: `${ROOTS.DASHBOARD}order`,
      details: (id: string) => `${ROOTS.DASHBOARD}order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}order/${MOCK_ID}`,
      },
    },
    report: {
      root: `${ROOTS.DASHBOARD}rapor-ve-ceza`,
      result: (reportId: string) =>
        `${ROOTS.DASHBOARD}rapor-ve-ceza/sonuc/${reportId}`,
      punishment: `${ROOTS.DASHBOARD}rapor-ve-ceza/uye-ceza`,
    },
  },
};
