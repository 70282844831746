import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { memo, useCallback, useState } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog, { dialogClasses } from "@mui/material/Dialog";

import ResultItem from "./result-item";
import { useTranslate } from "../../../locales";
import { useRouter } from "../../../routes/hook";
import { useGetMainSearch } from "../../../api/analytics";
import { paths } from "../../../routes/paths";
import { generatePostClickURL } from "../../../utils/format-string";
import { useBoolean } from "../../../hooks/use-boolean";
import { useResponsive } from "../../../hooks/use-responsive";
import { useEventListener } from "../../../hooks/use-event-listener";
import Iconify from "../../../components/iconify";
import Label from "../../../components/label";
import Scrollbar from "../../../components/scrollbar";
import SearchNotFound from "../../../components/search-not-found";
import SearchLets from "../../../components/search-not-found/search-lets";
import SearchLoading from "../../../components/search-not-found/search-loading";

function Searchbar() {
  const theme = useTheme();
  const { t } = useTranslate();
  const router = useRouter();

  const search = useBoolean();

  const lgUp = useResponsive("up", "lg");

  const [searchQuery, setSearchQuery] = useState("");
  const { searchData, searchDataLoading } = useGetMainSearch(searchQuery);

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery("");
  }, [search]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "k" && event.metaKey) {
      search.onToggle();
      setSearchQuery("");
    }
  };

  useEventListener("keydown", handleKeyDown);

  const handleClick = useCallback(
    (path: string) => {
      if (path.includes("http")) {
        window.open(path);
      } else {
        router.push(path);
      }
      handleClose();
    },
    [handleClose, router],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
    },
    [],
  );

  const notFound = searchQuery && !searchData.length;
  const emptySearchText = searchQuery === "" && !searchData.length;

  const renderItems = () => {
    return searchData.map((group, index) => {
      const { title, id, originTable } = group;
      const partsTitle = parse(title, match(title, searchQuery));

      let path = paths.maintenance;
      if (originTable == "Kullanıcı") {
        path = paths.dashboard.user.profile(title);
      } else if (originTable == "Kategori") {
        path = paths.dashboard.category.root(id, title);
      } else if (originTable == "Konu Yorum") {
        path = paths.dashboard.post.detailsFromComment(id);
      } else if (originTable == "Konu") {
        path = paths.dashboard.post.details(generatePostClickURL(title, id));
      } else if (originTable == "Ticari Konu") {
        path = paths.dashboard.post.tradeDetails(
          generatePostClickURL(title, id),
        );
      } else if (originTable == "Blog") {
        path = paths.dashboard.blog.details(generatePostClickURL(title, id));
      }
      const partsPath = parse(path, match(path, searchQuery));
      return (
        <List key={id || index} disablePadding>
          <ResultItem
            path={partsPath}
            title={partsTitle}
            key={`${id}${title}${path}`}
            groupLabel={searchQuery && t(group.originTable)}
            onClickItem={() => handleClick(path)}
          />
        </List>
      );
    });
  };

  const renderButton = (
    <Stack direction="row" alignItems="center" onClick={search.onTrue}>
      <IconButton>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {lgUp && (
        <Label sx={{ px: 0.75, fontSize: 12, color: "text.secondary" }}>
          {t("search")}
        </Label>
      )}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: "unset",
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: "flex-start",
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder={t("searchThreeDots")}
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  width={24}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
            endAdornment={
              <IconButton onClick={handleClose}>
                <Iconify icon={"material-symbols:close"}></Iconify>
              </IconButton>
            }
            inputProps={{
              sx: { typography: "h6" },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {searchDataLoading ? (
            <SearchLoading />
          ) : notFound ? (
            <SearchNotFound query={searchQuery} sx={{ py: 5 }} />
          ) : emptySearchText ? (
            <SearchLets />
          ) : (
            renderItems()
          )}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
