import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import { Grid } from "@mui/material";
import TruncateText from "../../../utils/truncate-text";
import { useResponsive } from "../../../hooks/use-responsive";
import Label from "../../../components/label";

type Props = {
  title: {
    text: string;
    highlight: boolean;
  }[];
  path: {
    text: string;
    highlight: boolean;
  }[];
  groupLabel: string;
  onClickItem: VoidFunction;
};

export default function ResultItem({
  title,
  path,
  groupLabel,
  onClickItem,
}: Props) {
  const smUp = useResponsive("up", "sm");

  return (
    <ListItemButton
      onClick={onClickItem}
      sx={{
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "transparent",
        borderBottomColor: (theme) => theme.palette.divider,
        "&:hover": {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.hoverOpacity,
            ),
        },
      }}
    >
      <Grid container>
        <Grid item xs={10}>
          <ListItemText
            primaryTypographyProps={{
              typography: "subtitle2",
              sx: { textTransform: "capitalize", marginBottom: { xs: 1 } },
            }}
            secondaryTypographyProps={{ typography: "caption" }}
            primary={title.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  color: part.highlight ? "primary.main" : "text.primary",
                }}
              >
                {smUp ? part.text : TruncateText(part.text, 20)}
              </Box>
            ))}
            secondary={path.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  color: part.highlight ? "primary.main" : "text.secondary",
                }}
              >
                {smUp ? part.text : TruncateText(part.text, 30)}
              </Box>
            ))}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "unset", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          {groupLabel && <Label color="info">{groupLabel}</Label>}
        </Grid>
      </Grid>
    </ListItemButton>
  );
}
