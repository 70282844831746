import { ListItemButtonProps } from "@mui/material/ListItemButton";

import Iconify from "../../iconify";
import IconButton from "@mui/material/IconButton";
import { varHover } from "../../animate";
import { useTranslate } from "../../../locales";
import { m } from "framer-motion";
import { useSettingsContext } from "../context";

// ----------------------------------------------------------------------

type Props = ListItemButtonProps & {
  icons: string[];
  selected: boolean;
  iconSize?: number;
};

export default function BaseOptionsTheme({
  title,
  icons,
  iconSize = 40,
  selected,
  ...other
}: Props) {
  const { t } = useTranslate();
  const settings = useSettingsContext();
  return (
    <IconButton
      component={m.button}
      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      aria-label={t("settings")}
      onClick={() =>
        settings.onUpdate(
          "themeMode",
          settings.themeMode === "dark" ? "light" : "dark",
        )
      }
      sx={{
        width: iconSize,
        height: iconSize,
      }}
    >
      <Iconify
        width={iconSize}
        icon={selected ? icons[1] : icons[0]}
        color={selected ? "primary.main" : "default"}
      />
    </IconButton>
  );
}
