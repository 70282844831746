// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Router from "./routes/sections";
import ThemeProvider from "./theme";
import { useScrollToTop } from "./hooks/use-scroll-to-top";
import ProgressBar from "./components/progress-bar";
import MotionLazy from "./components/animate/motion-lazy";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import { SettingsDrawer, SettingsProvider } from "./components/settings";
import { AuthConsumer, AuthProvider } from "./auth/context/jwt";
import { TradeOfferAcceptProvider } from "./sections/trade/context";
import { tr } from "date-fns/locale";
import { useEffect } from "react";
import JwtDialogView from "./sections/auth/jwt/jwt-dialog-view";
import { initializeGoogleAnalytics } from "./analytics";

export default function App() {
  const charAt = `
┏━━┓━━━━━━━━━━━┏━┓━━━━━━━━━┏┓━
┃┏┓┃━━━━━━━━━━━┃┏┛━━━━━━━━┏┛┗┓
┃┗┛┗┓┏┓┏━━━┓┏┓┏┛┗┓┏━━┓┏━━┓┗┓┏┛
┃┏━┓┃┣┫┣━━┃┃┣┫┗┓┏┛┃┏┓┃┃━━┫━┃┃━
┃┗━┛┃┃┃┃┃━━┫┃┃━┃┃━┃┃━┫┣━━┃━┃┗┓
┗━━━┛┗┛┗━━━┛┗┛━┗┛━┗━━┛┗━━┛━┗━┛
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  `;

  console.info(`%c${charAt}`, "color: #5BE49B");

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: "dark", // 'light' | 'dark'
            themeDirection: "ltr", //  'rtl' | 'ltr'
            themeContrast: "default", // 'default' | 'bold'
            themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <TradeOfferAcceptProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                  <JwtDialogView />
                </TradeOfferAcceptProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
