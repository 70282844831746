import { m } from "framer-motion";
import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useBoolean } from "../../hooks/use-boolean";
import { useResponsive } from "../../hooks/use-responsive";

import Label from "../../components/label";
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
import { varHover } from "../../components/animate";
import { useTheme } from "@mui/material/styles";
import { clickConversation, useGetConversations } from "../../api/chat";
import { usePopover } from "../../components/custom-popover";
import { useRouter } from "../../routes/hook";
import { paths } from "../../routes/paths";
import Link from "@mui/material/Link";
import { RouterLink } from "../../routes/components";
import { IChatConversation } from "../../types/chat";
import { useAuthContext } from "../../auth/hooks";
import { useGetNavItem } from "../../sections/chat/hooks";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { formatDistanceToNowStrict } from "date-fns";
import { tr } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useTranslate } from "../../locales";
import { fGetImageFromStore } from "../../utils/format-string";

export default function MessagePopoverDrawer() {
  const {
    conversations,
    conversationsAllUnRead,
    latestConversationId,
    conversationsLoading,
  } = useGetConversations();

  const popover = usePopover();
  const { t } = useTranslate();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickConversation = useCallback(
    async (conversationId: string) => {
      try {
        await clickConversation(conversationId);
        router.push(`${paths.dashboard.chat}?id=${conversationId}`);
        popover.onClose();
      } catch (error: any) {
        const errMsg =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : error.message;
        enqueueSnackbar(errMsg, { variant: "error" });
      }
    },
    [],
  );

  const drawer = useBoolean();
  const theme = useTheme();

  const smUp = useResponsive("up", "sm");

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? "primary" : "default"}
        onClick={drawer.onTrue}
      >
        {/* todo badgeContent dinamik alınack*/}
        <Badge badgeContent={conversationsAllUnRead} color="error">
          <Iconify icon="ic:baseline-chat" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Link
              component={RouterLink}
              href={paths.dashboard.chat}
              variant="h6"
              onClick={drawer.onFalse}
              sx={{
                color: "text.primary",
                p: 1.5,
                transition: (theme) => theme.transitions.create(["color"]),
                "&:hover": {
                  color: "text.primary",
                },
              }}
            >
              {t("messages")}
            </Link>
            <Label variant={"filled"} color={"primary"}>
              {conversationsAllUnRead}
            </Label>
          </Stack>

          <IconButton onClick={drawer.onFalse}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <List>
            {conversations &&
              conversations.allIds
                .slice(0, 15)
                .map((conversationId) => (
                  <MessagePopoverItem
                    key={conversationId}
                    handleClickConversation={handleClickConversation}
                    conversation={conversations.byId[conversationId]}
                    onCloseDrawer={drawer.onFalse}
                  />
                ))}

            {conversations && conversations.allIds.length == 0 && (
              <Box sx={{ p: 1.5, marginLeft: 2 }}>
                <Typography variant={"caption"}>{t("noMessageYet")}</Typography>
              </Box>
            )}
          </List>
        </Scrollbar>

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            size="large"
            onClick={() => {
              router.push(`${paths.dashboard.chat}?id=${latestConversationId}`);
              drawer.onFalse();
            }}
          >
            {t("view_all")}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

type MessagePopoverProps = {
  conversation: IChatConversation;
  handleClickConversation: (conversationId: string) => void;
  onCloseDrawer: () => void; // Yeni prop
};

function MessagePopoverItem({
  conversation,
  handleClickConversation,
  onCloseDrawer,
}: MessagePopoverProps) {
  const { user } = useAuthContext();

  const {
    group,
    displayName,
    displayText,
    participants,
    lastActivity,
    hasOnlineInGroup,
  } = useGetNavItem({
    conversation,
    currentUserId: user?.id,
  });

  const singleParticipant = participants[0];

  const { name, avatar, userName, status } = singleParticipant;

  const renderGroup = (
    <Badge
      variant={hasOnlineInGroup ? "online" : "invisible"}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <AvatarGroup variant="compact" sx={{ width: 48, height: 48 }}>
        {participants.slice(0, 2).map((participant: any) => (
          <Avatar
            key={participant.id}
            alt={participant.userName}
            src={fGetImageFromStore("thumbnail", participant.avatar)}
          />
        ))}
      </AvatarGroup>
    </Badge>
  );

  const renderSingle = (
    <Badge
      key={status}
      variant={status}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Avatar
        alt={userName}
        src={fGetImageFromStore("thumbnail", avatar)}
        sx={{ width: 48, height: 48 }}
      />
    </Badge>
  );

  return (
    <ListItemButton
      disableGutters
      onClick={() => {
        handleClickConversation(conversation.id);
        onCloseDrawer();
      }}
      sx={{
        py: 1.5,
        px: 2.5,
      }}
    >
      <Badge
        color="error"
        overlap="circular"
        badgeContent={conversation.unreadCount}
      >
        {group ? renderGroup : renderSingle}
      </Badge>

      <ListItemText
        sx={{ ml: 2 }}
        primary={userName}
        primaryTypographyProps={{
          noWrap: true,
          variant: "subtitle2",
        }}
        secondary={displayText}
        secondaryTypographyProps={{
          noWrap: true,
          component: "span",
          variant: conversation.unreadCount ? "subtitle2" : "body2",
          color: conversation.unreadCount ? "text.primary" : "text.secondary",
        }}
      />

      <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
        <Typography
          noWrap
          variant="body2"
          component="span"
          sx={{
            mb: 1.5,
            fontSize: 12,
            color: "text.disabled",
          }}
        >
          {formatDistanceToNowStrict(new Date(lastActivity), {
            addSuffix: false,
            locale: tr,
          })}
        </Typography>

        {!!conversation.unreadCount && (
          <Box
            sx={{
              width: 8,
              height: 8,
              bgcolor: "info.main",
              borderRadius: "50%",
            }}
          />
        )}
      </Stack>
    </ListItemButton>
  );
}
