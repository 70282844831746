import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// layouts
import DashboardLayout from "../../layouts/dashboard";
import { LoadingScreen } from "../../components/loading-screen";

// OVERVIEW
const IndexPage = lazy(() => import("../../pages/dashboard/app"));
const OverviewCategoryPage = lazy(
  () => import("../../pages/dashboard/category"),
);

//Giveaway
const GiveawayPage = lazy(() => import("../../pages/dashboard/giveaway"));
//Search
const SearchPage = lazy(() => import("../../pages/dashboard/search"));

// BADGES
const BadgesPage = lazy(() => import("../../pages/dashboard/badges"));

// Punishments
const PunishmentsPage = lazy(() => import("../../pages/dashboard/punishments"));

// REPORT
const ReportListPage = lazy(() => import("../../pages/dashboard/report/list"));
const ReportResultPage = lazy(
  () => import("../../pages/dashboard/report/result"),
);

// USER
const UserProfilePage = lazy(
  () => import("../../pages/dashboard/user/profile"),
);
const UserListPage = lazy(() => import("../../pages/dashboard/user/list"));
const PunishmentUserListPage = lazy(
  () => import("../../pages/dashboard/punishment/list"),
);
const UserAccountPage = lazy(
  () => import("../../pages/dashboard/user/account"),
);
const UserCreatePage = lazy(() => import("../../pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("../../pages/dashboard/user/edit"));

// ADMIN PANEL BADGES
const BadgeCreatePage = lazy(() => import("../../pages/panel/badge/badge-new"));
const BadgeEditPage = lazy(() => import("../../pages/panel/badge/badge-edit"));
const BadgeListPage = lazy(() => import("../../pages/panel/badge/badge-list"));
// ADMIN PANEL CATEGORIES
const CategoryCreatePage = lazy(
  () => import("../../pages/panel/category/category-new"),
);
const CategoryEditPage = lazy(
  () => import("../../pages/panel/category/category-edit"),
);
const CategoryListPage = lazy(
  () => import("../../pages/panel/category/category-list"),
);
// ADMIN PANEL SYSTEM NOTIFICATIONS
const SystemNotificationCreatePage = lazy(
  () => import("../../pages/panel/system-notification/system-notification-new"),
);
const SystemNotificationEditPage = lazy(
  () =>
    import("../../pages/panel/system-notification/system-notification-edit"),
);
const SystemNotificationListPage = lazy(
  () =>
    import("../../pages/panel/system-notification/system-notification-list"),
);
// DEKONT
// RECEIPT
const ReceiptListPage = lazy(() => import("../../pages/panel/receipt/list"));
const ReceiptDetailsPage = lazy(
  () => import("../../pages/panel/receipt/details"),
);
const ReceiptCreatePage = lazy(() => import("../../pages/panel/receipt/new"));
const ReceiptEditPage = lazy(() => import("../../pages/panel/receipt/edit"));
const ReceiptStatisticListPage = lazy(
  () => import("../../pages/panel/receipt/statistic"),
);

//SSS
const SSSListPage = lazy(() => import("../../pages/dashboard/sss/list"));

// BLOG
const PostListPage = lazy(() => import("../../pages/dashboard/post/list"));
const PostDetailsPage = lazy(
  () => import("../../pages/dashboard/post/details"),
);
const PostDetailsFromCommentPage = lazy(
  () => import("../../pages/dashboard/post/details-from-comment"),
);
const PostNewPostPage = lazy(() => import("../../pages/dashboard/post/new"));
const PostEditPostPage = lazy(() => import("../../pages/dashboard/post/edit"));

const BlogHomePage = lazy(() => import("../../pages/blog/blog"));
const BlogHomeDetailsPage = lazy(() => import("../../pages/blog/blog-details"));
const BlogCreatePage = lazy(() => import("../../pages/blog/new"));
const BlogEditPage = lazy(() => import("../../pages/blog/edit"));

// ORDER

const TradePage = lazy(() => import("../../pages/dashboard/trade/trade"));
const TradeOfferDetailsPage = lazy(
  () => import("../../pages/dashboard/trade/trade-offer-detail"),
);

const BlogTradePostPage = lazy(
  () => import("../../pages/dashboard/trade/details"),
);
const TradeOfferReviewAndStartPage = lazy(
  () => import("../../pages/dashboard/trade/review-and-start"),
);

// APP
const ChatPage = lazy(() => import("../../pages/dashboard/chat"));
// User notification page
const NotificationPage = lazy(
  () => import("../../pages/dashboard/notification"),
);

// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(
  () => import("../../pages/dashboard/permission"),
);

export const dashboardRoutes = [
  {
    path: "",

    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: ":username/:userProfileTabName?", element: <UserProfilePage /> },
      { path: "cekilis", element: <GiveawayPage /> },
      { path: "arama", element: <SearchPage /> },
      { path: "rozet", element: <BadgesPage /> },
      { path: "ceza", element: <PunishmentsPage /> },
      { path: "kategori/:id/:title", element: <OverviewCategoryPage /> },

      {
        path: "kullanici",
        children: [
          { element: <UserProfilePage />, index: true },
          //{ path: 'cards', element: <UserCardsPage /> },
          { path: "liste", element: <UserListPage /> },
          { path: "yeni", element: <UserCreatePage /> },
          { path: ":id/duzenle", element: <UserEditPage /> },
          { path: "hesabim/:profileTabName", element: <UserAccountPage /> },
        ],
      },
      {
        path: "yonetim/sikca-sorulan-sorular",
        children: [
          { element: <SSSListPage />, index: true },
          { path: "liste", element: <SSSListPage /> },
        ],
      },
      {
        path: "rapor-ve-ceza",
        children: [
          { element: <ReportListPage />, index: true },
          { path: "sonuc/:reportId", element: <ReportResultPage /> },
          { path: "uye-ceza", element: <PunishmentUserListPage /> },
        ],
      },
      {
        path: "konu",
        children: [
          { element: <PostListPage />, index: true },
          { path: "liste", element: <PostListPage /> },
          { path: ":id", element: <PostDetailsPage /> },
          { path: "yorum/:id", element: <PostDetailsFromCommentPage /> },
          { path: "ticaret/:id", element: <BlogTradePostPage /> },
          { path: ":id/duzenle", element: <PostEditPostPage /> },
          { path: "yeni", element: <PostNewPostPage /> },
          { path: "kategori/:categoryId", element: <PostNewPostPage /> },
        ],
      },
      {
        path: "blog",
        children: [
          { element: <BlogHomePage />, index: true },
          { path: ":id", element: <BlogHomeDetailsPage /> },
          { path: "yeni", element: <BlogCreatePage /> },
          { path: ":id/duzenle", element: <BlogEditPage /> },
        ],
      },
      {
        path: "dekont",
        children: [
          { element: <ReceiptListPage />, index: true },
          { path: "liste", element: <ReceiptListPage /> },
          { path: ":id", element: <ReceiptDetailsPage /> },
          { path: ":id/duzenle", element: <ReceiptEditPage /> },
          { path: "yeni", element: <ReceiptCreatePage /> },
          { path: "istatistik", element: <ReceiptStatisticListPage /> },
        ],
      },
      {
        path: "panel-rozet",
        children: [
          { element: <BadgeListPage />, index: true },
          { path: "yeni", element: <BadgeCreatePage /> },
          { path: "liste", element: <BadgeListPage /> },
          { path: ":id/duzenle", element: <BadgeEditPage /> },
        ],
      },
      {
        path: "panel-kategori",
        children: [
          { element: <CategoryListPage />, index: true },
          { path: "yeni", element: <CategoryCreatePage /> },
          { path: "liste", element: <CategoryListPage /> },
          { path: ":id/duzenle", element: <CategoryEditPage /> },
        ],
      },
      {
        path: "panel-sistem-bildirimleri",
        children: [
          { element: <SystemNotificationListPage />, index: true },
          { path: "yeni", element: <SystemNotificationCreatePage /> },
          { path: "liste", element: <SystemNotificationListPage /> },
          { path: ":id/duzenle", element: <SystemNotificationEditPage /> },
        ],
      },
      {
        path: "ticaret",
        children: [
          { element: <TradePage />, index: true },
          { path: "liste", element: <TradePage /> },
          { path: "teklif-detay/:id", element: <TradeOfferDetailsPage /> },
          {
            path: "ticari-teklif-inceleme/:id/:statusCode/:postId",
            element: <TradeOfferReviewAndStartPage />,
          },
        ],
      },
      { path: "mesaj", element: <ChatPage /> },
      { path: "bildirim", element: <NotificationPage /> },
      { path: "permission", element: <PermissionDeniedPage /> },
    ],
  },
];
