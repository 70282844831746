import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "../../auth/guard";
// layouts
import AuthClassicLayout from "../../layouts/auth/classic";
import { SplashScreen } from "../../components/loading-screen";

// JWT
const JwtLoginPage = lazy(() => import("../../pages/auth/jwt/login"));
const JwtRegisterPage = lazy(() => import("../../pages/auth/jwt/register"));
const JwtRegisterWithCodePage = lazy(
  () => import("../../pages/auth/jwt/register-with-code"),
);
const ModernForgotPasswordPage = lazy(
  () => import("../../pages/auth/jwt/forgot-password"),
);
const ModernNewPasswordPage = lazy(
  () => import("../../pages/auth/jwt/new-password"),
);
const VerifyMailPage = lazy(
  () => import("../../pages/auth/jwt/validate-account-with-email"),
);

// AUTH0
const Auth0LoginPage = lazy(() => import("../../pages/auth/auth0/login"));
const Auth0Callback = lazy(() => import("../../pages/auth/auth0/callback"));

const authJwt = {
  path: "",
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: "giris",
      element: (
        <AuthClassicLayout isRegister={true}>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "uye-ol",
      element: (
        <AuthClassicLayout isRegister={false}>
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "uye-ol/:code",
      element: (
        <AuthClassicLayout isRegister={false}>
          <JwtRegisterWithCodePage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "yeni-parola",
      element: (
        <AuthClassicLayout isRegister={false}>
          <ModernNewPasswordPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "hesabimi-dogrula",
      element: (
        <AuthClassicLayout isRegister={false}>
          <VerifyMailPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "parolami-unuttum",
      element: (
        <AuthClassicLayout isRegister={false}>
          <ModernForgotPasswordPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

const authAuth0 = {
  path: "auth0",
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: "login",
      element: (
        <AuthClassicLayout isRegister={true}>
          <Auth0LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: "callback",
      element: <Auth0Callback />,
    },
  ],
};

export const authRoutes = [
  {
    path: "uyelik",
    children: [authJwt, authAuth0],
  },
];
