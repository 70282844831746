import { createContext, useContext } from "react";
import { TradeOfferAcceptContextProps } from "../../../types/post-trade-offer";

export const TradeOfferAcceptContext = createContext(
  {} as TradeOfferAcceptContextProps,
);

export const useTradeOfferAcceptContext = () => {
  const context = useContext(TradeOfferAcceptContext);

  if (!context)
    throw new Error(
      "useTradeOfferAcceptContext must be use inside TradeOfferAcceptProvider",
    );

  return context;
};
