import { useCallback, useEffect, useMemo } from "react";
import { TRADE_ACCEPT_STEPS } from "../../../_mock/_blog";
//
import { TradeOfferAcceptContext } from "./trade-offer-accept-context";
import { IProgressPayment } from "../../.././types/post-trade-offer";
import { getStorage, useLocalStorage } from "../../../hooks/use-local-storage";

const STORAGE_KEY = "tradeOfferAccept";

const initialState = {
  activeStep: 0,
  tradeOfferId: "",
  progressPayment: false,
  progressPayments: [],
  rejectOthers: false,
  contract: false,
  contractContent: "",
};

type Props = {
  children: React.ReactNode;
};

export function TradeOfferAcceptProvider({ children }: Props) {
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const onGetProgressPayments = useCallback(() => {}, [
    state.progressPayments,
    state.progressPayment,
  ]);
  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetProgressPayments();
    }
  }, [onGetProgressPayments]);

  const onAddToProgressPayment = useCallback(
    (newItem: IProgressPayment) => {
      const updatedProgressPayments = state.progressPayments.concat(newItem);
      update("progressPayments", updatedProgressPayments);
    },
    [update, state.progressPayments],
  );

  const onDeleteProgressPayment = useCallback(
    (itemId: string) => {
      const updatedProgressPayments = state.progressPayments.filter(
        (item: IProgressPayment) => item.id !== itemId,
      );

      update("progressPayments", updatedProgressPayments);
    },
    [update, state.progressPayments],
  );

  const onProgressPaymentDescChange = useCallback(
    (itemId: string, desc: string) => {
      const updatedItems = state.progressPayments.map(
        (item: IProgressPayment) => {
          if (item.id === itemId) {
            return {
              ...item,
              description: desc,
            };
          }
          return item;
        },
      );

      update("progressPayments", updatedItems);
    },
    [update, state.progressPayments],
  );

  const onProgressPaymentPriceChange = useCallback(
    (itemId: string, price: number) => {
      const updatedItems = state.progressPayments.map(
        (item: IProgressPayment) => {
          if (item.id === itemId) {
            return {
              ...item,
              price: price,
            };
          }
          return item;
        },
      );

      update("progressPayments", updatedItems);
    },
    [update, state.progressPayments],
  );

  const onContractContentChange = useCallback(
    (content: string) => {
      update("contractContent", content);
    },
    [update, state.contractContent],
  );

  const onSetTradeOfferId = useCallback(
    (tradeOfferId: string) => {
      reset();
      update("tradeOfferId", tradeOfferId);
    },
    [update, reset, state.tradeOfferId],
  );

  const onSetContract = useCallback(() => {
    update("contract", !state.contract);
    update("contractContent", "");
  }, [update]);

  const onSetProgressPayment = useCallback(() => {
    update("progressPayment", !state.progressPayment);
    update("progressPayments", []);
  }, [update]);

  const onSetRejectOthers = useCallback(() => {
    update("rejectOthers", !state.rejectOthers);
  }, [update]);

  const completed = state.activeStep === TRADE_ACCEPT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      reset();
    }
  }, [completed, reset]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToProgressPayment,
      onDeleteProgressPayment,
      onProgressPaymentDescChange,
      onProgressPaymentPriceChange,
      //
      onSetContract,
      onContractContentChange,
      //
      onSetTradeOfferId,
      //
      onSetProgressPayment,
      //
      onSetRejectOthers,

      //
      onReset,
    }),
    [
      state,
      completed,
      onAddToProgressPayment,
      onDeleteProgressPayment,
      onProgressPaymentDescChange,
      onProgressPaymentPriceChange,
      onSetTradeOfferId,
      onSetContract,
      onContractContentChange,
      onSetProgressPayment,
      onSetRejectOthers,
      onReset,
    ],
  );

  return (
    <TradeOfferAcceptContext.Provider value={memoizedValue}>
      {children}
    </TradeOfferAcceptContext.Provider>
  );
}
