import { sub } from "date-fns";
// config
//
import {
  _ages,
  _booleans,
  _companyNames,
  _descriptions,
  _emails,
  _faqs_badges_descriptions,
  _faqs_badges_headings,
  _faqs_bizifest_platform_descriptions,
  _faqs_bizifest_platform_headings,
  _faqs_general_descriptions,
  _faqs_general_headings,
  _faqs_reputation_descriptions,
  _faqs_reputation_headings,
  _faqs_system_descriptions,
  _faqs_system_headings,
  _faqs_trading_post_descriptions,
  _faqs_trading_post_headings,
  _firstNames,
  _fullAddress,
  _fullNames,
  _giveawayNames,
  _giveawayShortDescriptions,
  _id,
  _jobTitles,
  _lastNames,
  _nativeL,
  _nativeM,
  _nativeS,
  _percents,
  _phoneNumbers,
  _postTitles,
  _prices,
  _productNames,
  _ratings,
  _roles,
  _sentences,
  _taskNames,
  _tourNames,
} from "./assets";
import { ASSETS_API } from "../config";

export const _mock = {
  id: (index: number) => _id[index],
  time: (index: number) => sub(new Date(), { days: index, hours: index }),
  boolean: (index: number) => _booleans[index],
  role: (index: number) => _roles[index],
  // Text
  taskNames: (index: number) => _taskNames[index],
  postTitle: (index: number) => _postTitles[index],
  jobTitle: (index: number) => _jobTitles[index],
  description: (index: number) => _descriptions[index],
  tourName: (index: number) => _tourNames[index],
  productName: (index: number) => _productNames[index],
  sentence: (index: number) => _sentences[index],
  giveawayTitles: (index: number) => _giveawayNames[index],
  giveawayDescriptions: (index: number) => _giveawayShortDescriptions[index],

  //faqs
  faqs_bizifest_platform_description: (index: number) =>
    _faqs_bizifest_platform_descriptions[index],
  faqs_bizifest_platform_heading: (index: number) =>
    _faqs_bizifest_platform_headings[index],
  faqs_trading_posts_description: (index: number) =>
    _faqs_trading_post_descriptions[index],
  faqs_trading_posts_heading: (index: number) =>
    _faqs_trading_post_headings[index],
  faqs_reputation_descriptions: (index: number) =>
    _faqs_reputation_descriptions[index],
  faqs_reputation_headings: (index: number) => _faqs_reputation_headings[index],
  faqs_badges_headings: (index: number) => _faqs_badges_headings[index],
  faqs_badges_descriptions: (index: number) => _faqs_badges_descriptions[index],
  faqs_general_headings: (index: number) => _faqs_general_headings[index],
  faqs_general_descriptions: (index: number) =>
    _faqs_general_descriptions[index],
  faqs_system_headings: (index: number) => _faqs_system_headings[index],
  faqs_system_descriptions: (index: number) => _faqs_system_descriptions[index],

  // Contact
  email: (index: number) => _emails[index],
  phoneNumber: (index: number) => _phoneNumbers[index],
  fullAddress: (index: number) => _fullAddress[index],
  // Name
  firstName: (index: number) => _firstNames[index],
  lastName: (index: number) => _lastNames[index],
  fullName: (index: number) => _fullNames[index],
  companyName: (index: number) => _companyNames[index],
  // Number
  number: {
    percent: (index: number) => _percents[index],
    rating: (index: number) => _ratings[index],
    age: (index: number) => _ages[index],
    price: (index: number) => _prices[index],
    nativeS: (index: number) => _nativeS[index],
    nativeM: (index: number) => _nativeM[index],
    nativeL: (index: number) => _nativeL[index],
  },
  // Image
  image: {
    cover: (index: number) =>
      `${ASSETS_API}/assets/images/cover/cover_${index + 1}.jpg`,
    avatar: (index: number) =>
      `${ASSETS_API}/assets/images/avatar/avatar_${index + 1}.jpg`,
    travel: (index: number) =>
      `${ASSETS_API}/assets/images/travel/travel_${index + 1}.jpg`,
    company: (index: number) =>
      `${ASSETS_API}/assets/images/company/company_${index + 1}.png`,
    product: (index: number) =>
      `${ASSETS_API}/assets/images/m_product/product_${index + 1}.jpg`,
    portrait: (index: number) =>
      `${ASSETS_API}/assets/images/portrait/portrait_${index + 1}.jpg`,
    course: (index: number) => `/assets/images/course/course_${index + 1}.jpg`,
  },
};

export const _mockGiveaway = {};
