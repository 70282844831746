/**
 * Author: Beefy
 * User:PLUS
 * Date:2/1/2024
 * Time:23:57 AM
 */

/*
 * This page includes constants values of User Profile Page
 *
 */

// The enum used when populating USER ACCOUNT SETTINGS DATA
export enum USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM {
  GENERAL = "genel",
  INVITE = "davetiye",
  NOTIFICATION = "bildirim",
  SOCIAL = "sosyal",
  SECURITY = "güvenlik",
  SKILLS = "yetenek",
  REPORT = "rapor",
  PUNISHMENTS = "ceza",
  BLOCK = "engellenen",
}

// USER ACCOUNT SETTINGS TABS DATA
export const USER_ACCOUNT_MAIN_TABS = [
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.GENERAL,
    icon: "ic:round-account-circle",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.INVITE,
    icon: "ic:round-card-giftcard",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.NOTIFICATION,
    icon: "ic:round-notifications",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.SOCIAL,
    icon: "ic:round-share",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.SECURITY,
    icon: "ic:round-vpn-key",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.SKILLS,
    icon: "ic:round-workspaces",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.REPORT,
    icon: "ic:round-report",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.PUNISHMENTS,
    icon: "ic:round-gavel",
  },
  {
    value: USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.BLOCK,
    icon: "ic:round-block",
  },
];

// The enum used when populating PROFILE_MAIN_TABS_LIST
export enum PROFILE_MAIN_TABS_LIST_ITEMS_ENUM {
  PROFILE = "profil",
  ACTIVITIES = "aktivite",
}

// Titles of the main TABS of the user profile
export const PROFILE_MAIN_TABS_LIST = [
  {
    value: PROFILE_MAIN_TABS_LIST_ITEMS_ENUM.PROFILE,
    label: "profil",
    icon: "ic:round-person",
  },
  {
    value: PROFILE_MAIN_TABS_LIST_ITEMS_ENUM.ACTIVITIES,
    label: "aktivite",
    icon: "ic:round-rocket",
  },
] as const;

// MINIO Image buckets names
export const IMAGE_BUCKET_AVATAR = "avatar";
export const IMAGE_BUCKET_COVER = "cover";
