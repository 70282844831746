import Typography from "@mui/material/Typography";
import Paper, { PaperProps } from "@mui/material/Paper";
import { useTranslate } from "../../locales";
import Stack from "@mui/material/Stack";
import { Player } from "@lottiefiles/react-lottie-player";

interface Props extends PaperProps {
  query?: string;
}

export default function SearchNotFound({ query, sx, ...other }: Props) {
  const { t } = useTranslate();
  // @ts-ignore
  return query ? (
    <Paper
      sx={{
        bgcolor: "unset",
        textAlign: "center",
        ...sx,
      }}
      {...other}
    >
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Player
          src={"/assets/lottie/not_found_search.json"}
          className="player"
          loop
          style={{ width: "20vh", marginTop: -44 }}
          autoplay
        />
      </Stack>

      <Typography variant="h6" gutterBottom>
        {t("notFound")}
      </Typography>

      <Typography variant="body2">
        {t("notFoundFor")}
        <strong>{query}</strong>.
        <br /> {t("checkTypo")}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      {t("searchHelpText")}
    </Typography>
  );
}
