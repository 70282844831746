import { AuthUserType } from "../auth/types";
import { ROLE_ADMIN, ROLE_MODERATOR } from "../config";

export function fUserRoleAdmin(user: AuthUserType) {
  return user && user?.roleId && user.roleId == ROLE_ADMIN;
}

export function fUserRoleModerator(user: AuthUserType) {
  return user && user?.roleId && user.roleId == ROLE_MODERATOR;
}

export function fUserRoleAdminOrModerator(user: AuthUserType) {
  return (
    user &&
    user?.roleId &&
    (user.roleId == ROLE_ADMIN || user.roleId == ROLE_MODERATOR)
  );
}
