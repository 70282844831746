import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import { useOffSetTop } from "../../hooks/use-off-set-top";
import { useResponsive } from "../../hooks/use-responsive";

import { bgBlur } from "../../theme/css";

import Logo from "../../components/logo";
import SvgColor from "../../components/svg-color";
import { useSettingsContext } from "../../components/settings";

import Searchbar from "../common/searchbar";
import { HEADER, NAV } from "../config-layout";
import SettingsButton from "../common/settings-button";
import AccountPopover from "../common/account-popover";
import NotificationsPopover from "../common/notifications-popover";
import LogoBizifestText from "../../components/logo/logo-bizifest-text";
import Container from "@mui/material/Container";
import MessagePopoverDrawer from "../common/message-popover-drawer";
import { useAuthContext } from "../../auth/hooks";
import { fUserRoleAdminOrModerator } from "../../utils/authorization";
import { useEffect, useState } from "react";

type Props = {
  onOpenNav?: VoidFunction;
};

export default function HeaderWithMargin({ onOpenNav }: Props) {
  const theme = useTheme();

  const settings = useSettingsContext();
  const [adminModerator, setAdminModerator] = useState(false);

  const isNavHorizontal = settings.themeLayout === "horizontal";

  const isNavMini = settings.themeLayout === "mini";

  const lgUp = useResponsive("up", "lg");
  const mdUp = useResponsive("up", "md");

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  // Logic here to get current user role
  const { user } = useAuthContext();

  useEffect(() => {
    setAdminModerator(fUserRoleAdminOrModerator(user));
  }, [user]);

  const renderContent = (
    <Container maxWidth={"xl"}>
      <Stack direction={"row"}>
        {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

        {!lgUp && adminModerator && (
          <IconButton onClick={onOpenNav}>
            <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
          </IconButton>
        )}

        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingLeft={!lgUp ? 0 : 0}
          paddingRight={1.5}
          sx={{ display: "flex" }}
        >
          <Logo sx={{ mr: { xs: 1, md: 2.5 }, ml: { xs: 0.5, md: 0 } }} />
          {mdUp && <LogoBizifestText sx={{ mr: 2.5, mt: 0.5 }} />}
        </Stack>
        {mdUp && <Searchbar />}

        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          paddingRight={0.5}
          spacing={{ xs: 0.5, sm: 1, md: 1.5 }}
        >
          {!mdUp && <Searchbar />}
          <NotificationsPopover />

          {/*Mesaj kısmı da notification dizaynının aynısı yapıldı*/}
          <MessagePopoverDrawer />

          {/*<MessagePopover />*/}

          {/* <SettingsButton/>*/}

          <AccountPopover />
        </Stack>
      </Stack>
    </Container>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: adminModerator ? `calc(100% - ${NAV.W_VERTICAL + 1}px)` : 1,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: adminModerator ? `calc(100% - ${NAV.W_MINI + 1}px)` : 1,
          }),
        }),
      }}
    >
      <Toolbar disableGutters>{renderContent}</Toolbar>
    </AppBar>
  );
}
