import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { paths } from "../../routes/paths";

import Label from "../../components/label";
import { useTranslate } from "../../locales";
import { useAuthContext } from "../../auth/hooks";
import React from "react";
import { fGetImageFromStore } from "../../utils/format-string";
import { useRouter } from "../../routes/hook";
import { fUserRoleAdmin, fUserRoleModerator } from "../../utils/authorization";

export default function NavUpgrade() {
  const { user } = useAuthContext();

  const { t } = useTranslate();
  const { logout } = useAuthContext();
  const router = useRouter();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace(paths.whatIsBizifest);
    } catch (error: any) {
      localStorage.removeItem("accessToken");
      window.location.href = paths.auth.jwt.login;
    }
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: "center",
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: "relative" }}>
          <Avatar
            alt={user?.nick}
            src={fGetImageFromStore("thumbnail", user?.avatar)}
            sx={{ width: 48, height: 48 }}
          />
          <Label
            color="primary"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: "absolute",
              borderBottomLeftRadius: 2,
            }}
          >
            {fUserRoleAdmin(user) && "admin"}
            {fUserRoleModerator(user) && "moderator"}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.userName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.disabled" }}>
            {user?.email}
          </Typography>
        </Stack>

        <Button variant="contained" onClick={handleLogout}>
          {t("logout")}
        </Button>
      </Stack>
    </Stack>
  );
}
