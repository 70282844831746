import { _mock } from "./_mock";
import { _tags } from "./assets";

export const POST_PUBLISH_OPTIONS = [
  {
    value: "published",
    label: "Published",
  },
  {
    value: "draft",
    label: "Draft",
  },
];

export const TRADE_POST_PUBLISH_OPTIONS = [
  {
    value: "published",
    label: "Yayında",
  },
  {
    value: "draft",
    label: "Taslak",
  },
];

export const TRADE_VIEW_OPTIONS = [
  {
    value: "myTradePosts",
  },
  {
    value: "incomingOffers",
  },
  {
    value: "sendingOffers",
  },
];

export const TRADE_OFFER_VIEW_OPTIONS = [
  {
    value: "tradeOffer",
  },
];

export const TRADE_OFFER_CONTRACT_VIEW_OPTIONS = [
  {
    value: "tradeOffer",
  },
  {
    value: "contractAndPayments",
  },
];

export const OFFER_DETAILS_TABS = [
  { value: "content", label: "Ticari Konu" },
  { value: "offers", label: "Teklifler" },
];

export const POST_SORT_OPTIONS = [
  { value: "latestPost", label: "En Yeni" },
  { value: "popular", label: "Popüler" },
  //  { value: 'oldest', label: 'Oldest' },
];

export const POST_DAY_SEARCH_OPTIONS = [
  { value: 0, label: "Tüm Zamanlar" },
  { value: 1, label: "Bugün" },
  { value: 7, label: "Hafta" },
  { value: 30, label: "Ay" },
  { value: 360, label: "Yıl" },
  //  { value: 'oldest', label: 'Oldest' },
];

export const POST_TRADE_OPTIONS = [
  { value: 0, label: "all" },
  { value: 1, label: "tradePost" },
  { value: 2, label: "normalPosts" },
  //  { value: 'oldest', label: 'Oldest' },
];

export const POST_PUBLISH_STATUS_OPTIONS = [
  { value: 0, label: "all" },
  { value: 1, label: "published" },
  { value: 2, label: "draft" },
  //  { value: 'oldest', label: 'Oldest' },
];

export const POST_ACTIVE_STATUS_OPTIONS = [
  { value: 0, label: "all" },
  { value: 1, label: "active" },
  { value: 2, label: "closed" },
  //  { value: 'oldest', label: 'Oldest' },
];

export const TRADE_ACCEPT_STEPS = ["Hakediş", "Sözleşme", "Diğer"];

const content = (name: string) => `
<p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc.</p>
<br/>

<p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh. Donec posuere vulputate arcu. Quisque rutrum.</p>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
<br/>

<p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh.</p>

<br/>
<br/>
<br/>
<p><img alt="alt marketing" src="/assets/images/${name}/${name}_post_01.jpg" /></p>
<br/>
<br/>
<br/>

<h4>Curabitur suscipit suscipit tellus</h4>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
<br/>

<h4>Nullam accumsan lorem in</h4>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo.</p>

<br/>
<br/>
<br/>
<p><img alt="alt marketing" src="/assets/images/${name}/${name}_post_02.jpg" /></p>
<br/>
<br/>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
<br/>

<p>Pellentesque posuere. Phasellus a est. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Phasellus viverra nulla ut metus varius laoreet. Praesent egestas tristique nibh.</p>
<br/>

<p>Donec posuere vulputate arcu. Quisque rutrum. Curabitur vestibulum aliquam leo. Nam commodo suscipit quam. Vestibulum ullamcorper mauris at ligula.</p>
`;

const base = (index: number) => ({
  id: _mock.id(index),
  title: _mock.postTitle(index),
  description: _mock.description(index),
  category: "Marketing",
  favorited: _mock.boolean(index),
  createdAt: _mock.time(index),
  duration: "8 minutes read",
  tags: _tags.slice(index + 1, index + 2),
  author: {
    name: _mock.fullName(index),
    role: _mock.role(index),
    avatarUrl: _mock.image.avatar(index),
    quotes: "Member since Mar 15, 2021",
    about:
      "Integer tincidunt. Nullam dictum felis eu pede mollis pretium. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem.",
  },
});
export const _travelPosts = [...Array(12)].map((_, index) => ({
  ...base(index),
  content: content("travel"),
  coverUrl: `/assets/images/dummy_blog_cover2.jpg`,
  heroUrl: `/assets/images/travel_post_hero.jpg`,
}));
