import MainLayout from "../../layouts/main";
import { HomePage } from "./main";

export const homeRoutes = [
  {
    path: "/bizifest-nedir",
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
  },
];
