import { endpoints, fetcher } from "../utils/axios";
import useSWR from "swr";
import { useMemo } from "react";
import { IAnalytics, INavMenuData, ISearchItem } from "../types/analytics";

const URL = endpoints.analytics.analyticsSite;

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetAnalyticsSite() {
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  const memoizedValue = useMemo(
    () => ({
      analyticsSite: (data as IAnalytics) || [],
      analyticsSiteLoading: isLoading,
      analyticsSiteError: error,
      analyticsSiteValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetNavMenuData() {
  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.analytics.navMenuData,
    fetcher,
    options,
  );

  return useMemo(
    () => ({
      navMenuData: (data as INavMenuData) || {},
      navMenuDataLoading: isLoading,
      navMenuDataError: error,
      navMenuDataValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
}

export function useGetMainSearch(searchText: string) {
  const URL = `${endpoints.analytics.mainSearch}?searchText=${searchText}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      searchData: (data as ISearchItem[]) || [],
      searchDataLoading: isLoading,
      searchDataError: error,
      searchDataValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetMainSearchWithType(searchText: string, type: string) {
  const URL = `${endpoints.analytics.mainSearch}?searchText=${searchText}&type=${type}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      searchData: (data as ISearchItem[]) || [],
      searchDataLoading: isLoading,
      searchDataError: error,
      searchDataValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );

  return memoizedValue;
}
