import { IChatMessage, IChatParticipant } from "../../../types/chat";

type Props = {
  message: IChatMessage;
  currentUserId: string;
  participants: IChatParticipant[];
};

export default function useGetMessage({
  message,
  participants,
  currentUserId,
}: Props) {
  const sender = participants.find(
    (participant) => participant.id === message.senderId,
  );

  const senderDetails =
    message.senderId === currentUserId
      ? {
          type: "me",
        }
      : {
          avatar: sender?.avatar,
          firstName: sender?.name.split(" ")[0],
        };

  const me = senderDetails.type === "me";

  const hasImage = message.contentType === "img";
  const hasFile = message.contentType === "doc";

  return {
    hasImage,
    hasFile,
    me,
    senderDetails,
  };
}
