// ----------------------------------------------------------------------

export const _id = [...Array(40)].map(
  (_, index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
);

export const _fullAddress = [
  "Kemerburgaz Bulvari Betonsa Mevkii Hanimkece Sok. Ayazaga Koyu Ayazaga Istanbul",
  "İlker 1.c N 9/d, Dikmen Çankaya Ankara",
  "Ereğli S N 2/2., Siteler Altındağ Ankara",
  "Dr.Tevfik Sağlam C N 124/18, Etlik Keçiören Ankara",
  "Degol C N 29/c, Beşevler Çankaya Ankara",
  "R.Paşa M Gazi Bulvari 3.s N 36c Merkez Aydın",
  "Gaziosmanpaşa Bulvari Nob: 10/1-c, Çankaya Ankara",
  "Fetih Cad. Akyüz Apt. No:14/2 Bahçelievler Ankara",
  "Konur S N 59/1, Kızılay Ankara",
  "Kemerburgaz Bulvari Betonsa Mevkii Hanimkece Sok. Ayazaga Koyu Ayazaga Istanbul",
  "İlker 1.c N 9/d, Dikmen Çankaya Ankara",
  "Ereğli S N 2/2., Siteler Altındağ Ankara",
  "Dr.Tevfik Sağlam C N 124/18, Etlik Keçiören Ankara",
  "Degol C N 29/c, Beşevler Çankaya Ankara",
  "R.Paşa M Gazi Bulvari 3.s N 36c Merkez Aydın",
  "Gaziosmanpaşa Bulvari Nob: 10/1-c, Çankaya Ankara",
  "Fetih Cad. Akyüz Apt. No:14/2 Bahçelievler Ankara",
  "Konur S N 59/1, Kızılay Ankara",
  "Kemerburgaz Bulvari Betonsa Mevkii Hanimkece Sok. Ayazaga Koyu Ayazaga Istanbul",
  "İlker 1.c N 9/d, Dikmen Çankaya Ankara",
  "Ereğli S N 2/2., Siteler Altındağ Ankara",
  "Dr.Tevfik Sağlam C N 124/18, Etlik Keçiören Ankara",
  "Degol C N 29/c, Beşevler Çankaya Ankara",
  "R.Paşa M Gazi Bulvari 3.s N 36c Merkez Aydın",
];

export const _booleans = [
  true,
  true,
  true,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  true,
  true,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  true,
];

export const _emails = [
  "nannie_abernathy70@yahoo.com",
  "ashlynn_ohara62@gmail.com",
  "milo.farrell@hotmail.com",
  "violet.ratke86@yahoo.com",
  "letha_lubowitz24@yahoo.com",
  "aditya_greenfelder31@gmail.com",
  "lenna_bergnaum27@hotmail.com",
  "luella.ryan33@gmail.com",
  "joana.simonis84@gmail.com",
  "marjolaine_white94@gmail.com",
  "vergie_block82@hotmail.com",
  "vito.hudson@hotmail.com",
  "tyrel_greenholt@gmail.com",
  "dwight.block85@yahoo.com",
  "mireya13@hotmail.com",
  "dasia_jenkins@hotmail.com",
  "benny89@yahoo.com",
  "dawn.goyette@gmail.com",
  "zella_hickle4@yahoo.com",
  "avery43@hotmail.com",
  "olen_legros@gmail.com",
  "jimmie.gerhold73@hotmail.com",
  "genevieve.powlowski@hotmail.com",
  "louie.kuphal39@gmail.com",
];

export const _status = [
  true,
  false,
  true,
  false,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  true,
];

export const _fullNames = [
  "Çilem Akçay",
  "Recep Ali Samet Akdoğan",
  "Atakan Keremseven Akman",
  "Berker Akkiray",
  "Senem Aksevim",
  "Bahar Özlem Albaş",
  "Elif Tuğçe Altaş",
  "Rima Altıparmak",
  "Öget Arif",
  "Mustafa Burhan Askın",
  "Dilara Ateş",
  "Almina Avcı Özsoy",
  "Murat Kaan Ayanoglu",
  "Güneş Aykan",
  "Rüya Su Baldo",
  "Tazika Hilal Hamzaoğlu",
  "Elif Nisan İmamoğlu",
  "Abdurahman Morgül",
  "Türkan Nalçacı",
  "İzel Okcu",
  "Orkide Sayıl",
  "Cengizhan Sökel",
  "Buraktan Şahingöz",
  "Kerem Arda Tatlısu",
];

export const _firstNames = [
  "Çilem",
  "Recep Ali Samet",
  "Ata Kerem",
  "Berker",
  "Senem",
  "Bahar Özlem",
  "Elif Tuğçe",
  "Rima",
  "Öget",
  "Mustafa Burhan",
  "Dilara",
  "Almina Avcı",
  "Murat Kaan",
  "Rüya Su",
  "Tazika Hilal",
  "Elif Nisan",
  "Abdurahman",
  "Türkan",
  "İzel",
  "Orkide",
  "Cengizhan",
  "Buraktan",
  "Kerem Arda",
  "Tahsin",
];

export const _lastNames = [
  "Ünlü",
  "Yapar",
  "Zeybek",
  "Soylu",
  "Söylemez",
  "Sözümçetin",
  "Solak",
  "Özbey",
  "Özırk",
  "Muslu",
  "Nalçacı",
  "Maydagil",
  "Kozakçıoğlu",
  "Kılınçarslan",
  "Karabıyık",
  "Irıcıoğlu",
  "Hamzaoğlu",
  "Güntek",
  "Gülcan",
  "Golba",
  "Ercoşkun",
  "Delice",
  "Çintan",
  "Çapa",
];

export const _prices = [
  83.74, 97.14, 68.71, 85.21, 52.17, 25.18, 43.84, 60.98, 98.42, 53.37, 72.75,
  56.61, 64.55, 77.32, 60.62, 79.81, 93.68, 47.44, 76.24, 92.87, 72.91, 20.54,
  94.25, 37.51,
];

export const _ratings = [
  4.2, 3.7, 4.5, 3.5, 0.5, 3.0, 2.5, 2.8, 4.9, 3.6, 2.5, 1.7, 3.9, 2.8, 4.1,
  4.5, 2.2, 3.2, 0.6, 1.3, 3.8, 3.8, 3.8, 2.0,
];

export const _ages = [
  30, 26, 59, 47, 29, 46, 18, 56, 39, 19, 45, 18, 46, 56, 38, 41, 44, 48, 32,
  45, 42, 60, 33, 57,
];

export const _percents = [
  10.1, 13.6, 28.2, 42.1, 37.2, 18.5, 40.1, 94.8, 91.4, 53.0, 25.4, 62.9, 86.6,
  62.4, 35.4, 17.6, 52.0, 6.8, 95.3, 26.6, 69.9, 92.1, 46.2, 85.6,
];

export const _nativeS = [
  11, 10, 7, 10, 12, 5, 10, 1, 8, 8, 10, 11, 12, 8, 4, 11, 8, 9, 4, 9, 2, 6, 3,
  7,
];

export const _nativeM = [
  497, 763, 684, 451, 433, 463, 951, 194, 425, 435, 807, 521, 538, 839, 394,
  269, 453, 821, 364, 849, 804, 776, 263, 239,
];

export const _nativeL = [
  9911, 1947, 9124, 6984, 8488, 2034, 3364, 8401, 8996, 5271, 8478, 1139, 8061,
  3035, 6733, 3952, 2405, 3127, 6843, 4672, 6995, 6053, 5192, 9686,
];

export const _phoneNumbers = [
  "0538-365-37-61",
  "0539-904-96-36",
  "0533-399-75-99",
  "0536-692-76-03",
  "0539-990-58-16",
  "0539-955-43-78",
  "0532-226-92-58",
  "0535-552-91-54",
  "0532-285-84-38",
  "0533-306-26-46",
  "0538-883-37-53",
  "0534-476-50-66",
  "0532-201-46-54",
  "0535-538-29-08",
  "0535-531-49-28",
  "0539-981-69-88",
  "0535-500-26-26",
  "0532-205-95-28",
  "0532-222-25-90",
  "0534-408-43-33",
  "0532-272-94-66",
  "0538-812-68-57",
  "0533-353-80-12",
  "0536-606-28-28",
];

export const _roles = [
  "İK Müdürü",
  "Veri Analisti",
  "Hukuk Danışmanı",
  "Kullanıcı Deneyimi (UX/UI) Tasarımcısı",
  "Proje Yöneticisi",
  "Hesap Müdürü",
  "Kayıtlı Hemşire",
  "İş Analisti",
  "Yaratıcı Direktör",
  "Finansal Planlamacı",
  "Etkinlik Koordinatörü",
  "Pazarlama Direktörü",
  "Yazılım Geliştirici",
  "Araştırma Bilimci",
  "İçerik Stratejisti",
  "Operasyonlar Müdürü",
  "Satış Temsilcisi",
  "Tedârik Zinciri Analisti",
  "Operasyonlar Koordinatörü",
  "Müşteri Hizmetleri Temsilcisi",
  "Kalite Güvence Uzmanı",
  "CEO",
  "CFO",
  "CTO",
];

export const _postTitles = [
  "Web Sitesi Geliştirme İşi İçin Uzman Aranıyor",
  "Grafik Tasarımcı Aranıyor: Logo Tasarımı Projesi",
  "Lisans Satın Almak İsteyenler İçin Yardım Aranıyor",
  "Makale Yazımı İşleri: Konu ve Anahtar Kelime Rehberi Verilecek",
  "Uygulama Geliştirme Projesi İçin Freelance Yazılımcı Aranıyor",
  "Sosyal Medya Yönetimi İşleri: Günlük Paylaşımlar ve Etkileşim",
  "E-ticaret Sitesi İçin Ürün Fotoğrafçısı Aranıyor",
  "Teknik Dökümantasyon Yazımı Projesi",
  "Video Düzenleme ve Animasyon İşi İçin Uzman Aranıyor",
  "Dijital Pazarlama Stratejileri Danışmanlığı İş İlanı",
  "WordPress Tema Özelleştirmesi İçin Uzman Aranıyor",
  "E-kitap Yazımı Projesi: Konu ve İçerik Sağlanacak",
  "Görüntü Düzenleme İşi İçin Grafik Tasarımcı Aranıyor",
  "Teknik Destek ve Sorun Giderme Uzmanı Aranıyor",
  "Dijital Reklam Kampanyası Yürütme İşi İlanı",
  "Eğitim Videoları Hazırlama Projesi İçin Profesyonel Aranıyor",
  "İçerik Yönetimi ve Blog Yazımı İşleri",
  "Mobil Uygulama Testi ve Hata Ayıklama Görevi",
  "E-posta Pazarlama Kampanyaları Yönetimi İşi İlanı",
  "Online Mağaza İçin Ürün Tanıtım Videoları Üretimi İş İlanı",
  "Çeviri İşi: İngilizceden Türkçeye Metin Tercümesi",
  "Web Sitesi SEO İyileştirme ve Analiz Görevi",
  "E-ticaret Platformunda Ürün Listeleme ve Stok Takibi İşi",
  "Yazılım Güncelleme ve Bakım Görevi İş İlanı",
];

export const _productNames = [
  "Makale Yazım İşi",
  "Grafik Tasarım Hizmetleri",
  "Web Sitesi Geliştirme ve Tasarımı",
  "Logo Tasarımı ve Kurumsal Kimlik Oluşturma",
  "Sosyal Medya Yönetimi ve Reklam Kampanyaları",
  "E-ticaret Sitesi Oluşturma ve Yönetme",
  "Mobil Uygulama Geliştirme",
  "Teknik Destek ve Sorun Giderme",
  "Video Düzenleme ve Animasyon Üretimi",
  "Çeviri Hizmetleri",
  "Dijital Pazarlama Stratejileri Danışmanlığı",
  "E-posta Pazarlama ve Kampanya Yönetimi",
  "SEO (Arama Motoru Optimizasyonu) Danışmanlığı",
  "Yazılım Geliştirme ve Kodlama İşleri",
  "Görüntü Düzenleme ve Fotoğrafçılık",
  "İçerik Yönetimi ve Blog Yazımı",
  "Sunucu ve Altyapı Yönetimi",
  "3D Modelleme ve Tasarım",
  "Yazılım Testi ve Kalite Kontrol",
  "Dijital İllüstrasyon ve Çizim",
  "Sosyal Medya İçerik Üretimi",
  "Finansal Danışmanlık ve Muhasebe Hizmetleri",
  "Ürün Tanıtım Videoları Üretimi",
  "İş Danışmanlığı ve Kariyer Koçluğu",
];

export const _giveawayNames = [
  "Genişletilmiş Dijital Tasarım Eğitimi",
  "Yazılım ve Web Uzmanlığı Eğitimi",
  "Sosyal Medya Yönetimi Eğitimi",
  "Siber Güvenlik Uzmanlığı Eğitimi",
  "Metaverse Mühendisliği Eğitimi",
  "Yapay Zekâ Kullanımı ve Prompt Mühendisliği",
  "Yapay Zeka (AI) Eğitimi",
  "E-Ticaret Uzmanlığı Eğitimi",
  "Robotik Kodlama Eğitimi",
  "Drone Yapımı ve Kodlama Eğitimi",
];

export const _giveawayShortDescriptions = [
  "Grafik tasarımın temellerini öğrenerek dijital sanat alanında uzmanlaşın.",
  "Yazılım geliştirme ve web teknolojileri konusunda derinlemesine bilgi edinin.",
  "Marka yönetimi, içerik stratejileri ve etkileşim konularında uzmanlaşın.",
  "Bilgisayar sistemlerini koruma, güvenlik tehditlerini analiz etme ve önleme becerilerini geliştirin.",
  "Sanal dünyalara yönelik gelişmiş mühendislik becerileri kazanın.",
  "Yapay zekâ ve prompt tekniklerini kullanarak öğrenme modelleri oluşturun.",
  "Temel yapay zeka prensiplerini anlayın ve uygulamalı projelerde deneyim kazanın.",
  "Online ticaret stratejileri, pazarlama ve işletme yönetimi konularında uzmanlaşın.",
  "Robotik programlama ve kodlama konusundaki temel yetenekleri öğrenin.",
  "Kendi drone cihazınızı yapın ve programlamayı öğrenerek kontrol edin.",
];

export const _tourNames = [
  "Macera Arayıcılarının Keşfi",
  "Tarihi Miras Turu",
  "Lezzetlerin Keşfi",
  "Doğanın Harikaları Kaçamağı",
  "Kültürel İçe Dönüş Yolculuğu",
  "Vahşi Yaşam Safarisi",
  "Şehir Turu için Şehir Keşifleri",
  "Sahil Cenneti Kaçamağı",
  "Sarap Tadım Deneyimi",
  "Ruhani Huzur Turu",
  "Dış Mekan Macera Yürüyüşü",
  "Fotoğrafçılık Keşfi",
  "Müzik ve Sanat Keşfi Turu",
  "İyilik ve Yoga Kaçamağı",
  "Gizli Hazineler Keşfi Turu",
  "Volkan ve Jeotermal Araştırma",
  "Gurme Gezisi",
  "Dağcılık ve Kamp Macerası",
  "Mimarlık ve Tasarım Turu",
  "Sahil Gezisi ve Ada Hoplaması",
  "Manzaralı Tren Yolculuğu Deneyimi",
  "Tarihi Anıtlar Keşfi",
  "Sörf ve Plaj Macerası",
  "Gece Hayatı ve Eğlence Turu",
];

export const _jobTitles = [
  "Yazılım Mühendisi",
  "Pazarlama Müdürü",
  "Finans Analisti",
  "Grafik Tasarımcı",
  "Satış Temsilcisi",
  "Proje Yöneticisi",
  "Veri Bilimci",
  "İnsan Kaynakları Koordinatörü",
  "Muhasebeci",
  "Müşteri Hizmetleri Temsilcisi",
  "Hemşire",
  "Ürün Yöneticisi",
  "Operasyonlar Müdürü",
  "Sosyal Medya Uzmanı",
  "İş Geliştirme Yöneticisi",
  "İçerik Yazarı",
  "Web Geliştirici",
  "Elektrik Mühendisi",
  "Araştırma Bilimci",
  "Hukuk Asistanı",
  "Aşçı",
  "Finansal Planlamacı",
  "Mimar",
  "Etkinlik Planlayıcısı",
];

export const _descriptions = [
  `Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.`,
  `Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.`,
  `Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla sequi recusandae illum velit quia quas. Et error laborum maiores cupiditate occaecati.`,
  `Et non omnis qui. Qui sunt deserunt dolorem aut velit cumque adipisci aut enim. Nihil quis quisquam nesciunt dicta nobis ab aperiam dolorem repellat. Voluptates non blanditiis. Error et tenetur iste soluta cupiditate ratione perspiciatis et. Quibusdam aliquid nam sunt et quisquam non esse.`,
  `Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.`,
  `Non rerum modi. Accusamus voluptatem odit nihil in. Quidem et iusto numquam veniam culpa aperiam odio aut enim. Quae vel dolores. Pariatur est culpa veritatis aut dolorem.`,
  `Est enim et sit non impedit aperiam cumque animi. Aut eius impedit saepe blanditiis. Totam molestias magnam minima fugiat.`,
  `Unde a inventore et. Sed esse ut. Atque ducimus quibusdam fuga quas id qui fuga.`,
  `Eaque natus adipisci soluta nostrum dolorem. Nesciunt ipsum molestias ut aliquid natus ut omnis qui fugiat. Dolor et rem. Ut neque voluptatem blanditiis quasi ullam deleniti.`,
  `Nam et error exercitationem qui voluptate optio. Officia omnis qui accusantium ipsam qui. Quia sequi nulla perspiciatis optio vero omnis maxime omnis ipsum. Perspiciatis consequuntur asperiores veniam dolores.`,
  `Perspiciatis nulla ut ut ut voluptates totam consectetur eligendi qui. Optio ut cum. Dolorum sapiente qui laborum. Impedit temporibus totam delectus nihil. Voluptatem corrupti rem.`,
  `Distinctio omnis similique omnis eos. Repellat cumque rerum nisi. Reiciendis soluta non ut veniam temporibus. Accusantium et dolorem voluptas harum. Nemo eius voluptate dicta et hic nemo. Dolorem assumenda et beatae molestias sit quo mollitia quis consequatur.`,
  `Sed ut mollitia tempore ipsam et illum doloribus ut. Occaecati ratione veritatis explicabo. Omnis nam omnis sunt placeat tempore accusantium placeat distinctio velit.`,
  `Eum illo dicta et perspiciatis ut blanditiis eos sequi. Ea veritatis aut et voluptas aut. Laborum eos quia tempore a culpa.`,
  `Aut quos quae dolores repudiandae similique perferendis perferendis earum laudantium. Facere placeat natus nobis. Eius vitae ullam dolorem.`,
  `Vero dolorem et voluptatem fugit tempore a quam iure. Fuga consequatur corrupti sunt asperiores vitae. Libero totam repellendus animi debitis illum et sunt officia.`,
  `Cupiditate illum officiis id molestiae. Numquam non molestiae aliquid et natus sed hic. Alias quia explicabo sed corrupti sint. Natus in et odio qui unde facilis quia. Est sit eius laboriosam aliquid non aperiam quia quo corporis.`,
  `Et a ab. Optio aspernatur minus tempora amet vitae consectetur inventore cumque. Sed et omnis. Aspernatur a magnam.`,
  `Ipsum omnis et. Quia ea et autem tempore consequuntur veniam dolorem officiis. Ipsa dicta et ut quidem quia doloremque. Sequi vitae doloremque temporibus. Deserunt incidunt id aperiam itaque natus. Earum sit eaque quas incidunt nihil.`,
  `Quae consequatur reiciendis. Consequatur non optio. Eaque id placeat. Commodi quo officia aut repudiandae reiciendis tempore voluptatem et. Ut accusamus qui itaque maxime aliquam. Fugit ut animi molestiae porro maiores.`,
  `Modi hic asperiores ab cumque quam est aut. Voluptas atque quos molestias. Ut excepturi distinctio ipsam aspernatur sit.`,
  `Sunt totam facilis. Quam commodi voluptatem veniam. Tempora deleniti itaque fugit nihil voluptas.`,
  `Ipsam aliquam velit nobis repellendus officiis aut deserunt id et. Nihil sunt aut dolores aut. Dolores est ipsa quia et laborum quidem laborum accusamus id. Facilis odit quod hic laudantium saepe omnis nisi in sint. Sed cupiditate possimus id.`,
  `Magnam non eveniet optio optio ut aliquid atque. Velit libero aspernatur quis laborum consequatur laudantium. Tempora facere optio fugit accusantium ut. Omnis aspernatur reprehenderit autem esse ut ut enim voluptatibus.`,
];

export const _companyNames = [
  "Aselsan",
  "Havelsan",
  "Türk Telekom",
  "Vestel",
  "STM Savunma Teknolojileri Mühendislik ve Ticaret A.Ş.",
  "TÜBİTAK BİLGEM",
  "Logo Yazılım",
  "Türk Havacılık ve Uzay Sanayii A.Ş. (TAI)",
  "Turkcell Teknoloji",
  "Arçelik",
  "Netas",
  "HAVELSAN",
  "CodeMakerz Yazılım A.Ş.",
  "Etiya",
  "Netaş",
  "Türkiye İş Bankası Teknoloji",
  "Karel",
  "VeriPark",
  "Arcelik",
  "Inveon",
  "STM Savunma Teknolojileri Mühendislik ve Ticaret AŞ",
  "Nokia Türkiye",
  "HCL Turkey",
  "DorukNet",
];

export const _tags = [
  "Teknoloji",
  "Pazarlama",
  "Tasarım",
  "Fotoğrafçılık",
  "Sanat",
  "Moda",
  "Yemek",
  "Seyahat",
  "Fitness",
  "Doğa",
  "İş",
  "Müzik",
  "Sağlık",
  "Kitaplar",
  "Spor",
  "Sinema",
  "Eğitim",
  "Motivasyon",
  "Oyun",
  "Evcil Hayvanlar",
  "Yemek Yapma",
  "Finans",
  "Kendine Bakım",
  "Yazma",
];

export const _taskNames = [
  "Tamamlanmış Proje Teklifi",
  "Pazar Araştırması Yap",
  "Kullanıcı Arayüzü Tasarımı Örnekleri Oluştur",
  "Arka Uç API Geliştirme",
  "Kimlik Doğrulama Sistemi Uygulama",
  "Test Senaryoları Yaz",
  "Veri Tabanı Optimizasyonu Gerçekleştir",
  "İçerik Pazarlama Planı Oluştur",
  "Web Sitesi Metinlerini Güncelle",
  "A/B Testleri Yap",
  "Sosyal Medya Grafikleri Oluştur",
  "Web Sitesi Performansını Optimize Et",
  "Rekabetçi Web Sitelerini İncele",
  "Ödeme Ağ Geçidi Entegrasyonu Uygula",
  "Kullanıcı Kabul Testi Gerçekleştir",
  "Aylık Satış Raporunu Hazırla",
  "SEO Stratejisini Güçlendir",
  "Müşteri Memnuniyeti Anketi Yap",
  "E-posta Bülten Şablonu Tasarla",
  "Sunucu Loglarını Hata İçin İzle",
  "Eğitim Materyalleri Oluştur",
  "Pazarlama Kampanyası Planla ve Uygula",
  "Mobil Uygulama Geliştir",
  "Proje Toplantılarını Koordine Et",
];

export const _sentences = [
  "Güneş yavaşça ufuk üzerine battı, gökyüzünü parlak turuncu ve pembe tonlarında boyadı.",
  "O hediyesini heyecanla açtı, gözleri heyecanla parlıyordu.",
  "Eski meşe ağacı dimdik ve görkemli duruyordu, dalları hafifçe rüzgarla sallanıyordu.",
  "Taze demlenmiş kahvenin kokusu havayı doldurdu, duyularımı uyanışa geçirdi.",
  "Çocuklar sıcak bir yaz gününde fıskiye arasından koşarken sevinçle kıkırdadılar.",
  "O dikkatlice kil ile güzel bir heykel yaptı, elleri ustalıkla karmaşık detayları şekillendirdi.",
  "Konser büyüleyici bir deneyimdi, müzik mekânı doldururken kalabalık coşkuyla tezahüratta bulundu.",
  "Dalgalar kıyıya çarptı, huzur veren bir sesler şöleni yarattı.",
  "Açan çiçeklerin kokusu bahçeden yayıldı, ferahlatıcı bir cennet oluşturdu.",
  "O gece gökyüzüne bakarak, karanlıkta parlayan yıldızları hayranlıkla izledi.",
  "Profesör düşündürücü fikirlerle öğrencileri etkileyen etkileyici bir ders verdi.",
  "Dağcı, ağaçların arasından süzülen güneş ışığının yumuşak parıltısıyla rehberliğinde yoğun ormanı tırmandı.",
  "Nazik kelebek zarifçe çiçekten çiçeğe uçtu, ince probosisiyle nektar içti.",
  "Taze pişmiş kurabiyelerin kokusu mutfağı doldurdu, herkesi çekici kokusuyla cezbetti.",
  "Heybetli şelale kayaların üzerinden aşağıya döküldü, doğanın gücünün nefes kesen bir gösterisi yarattı.",
  "Oyuncu etkileyici bir performans sergiledi, duygusal rolünü oynayarak izleyiciyi gözyaşlarına boğdu.",
  "Kitap beni sihirli bir dünyaya taşıdı, hayal gücünün sınırlarının olmadığı bir yerdi.",
  "Yağmurun kokusu havayı doldururken, karanlık bulutlar başımızın üzerinde bir ferahlatıcı sağanak vadetti.",
  "Aşçı lezzetli bir tabak hazırladı, basit malzemeleri bir mutfak sanat eserine dönüştürdü.",
  "Yeni doğmuş bebek küçük bir çığlık attı, varlığını dünyaya duyurdu.",
  "Sporcu, kalabalığın alkışlarıyla karşılaşırken zaferle bitiş çizgisine doğru koştu.",
  "Antik kalıntılar, uzun zaman önce kaybolan bir medeniyetin bir kanıtı olarak ayakta duruyor, büyüklükleri hala hayranlık uyandırıcı.",
  "Sanatçı fırçayı canlı boyalarla daldırdı, tuvali cesur vuruşlar ve canlı renklerle hayata döndürdü.",
  "Çocukların kahkahaları oyun parkında yankılandı, atmosferi saf mutlulukla doldurdu.",
];

export const _faqs_bizifest_platform_headings = [
  "Bizifest Nedir?",
  "Bizifest'in Amacı Nedir?",
  "Bizifest Topluluğu'na Nasıl Katkı Sağlarım?",
  "Bizifest'te Neler Yapabilirim?",
  "Bizifest Fikri Nereden Çıktı?",
  "Bizifest Freelance Platform Kimin?",
  "Nasıl Müşteri Bulabilirim?",
  "Freelance Çalışmak Nedir?",
  "Freelance Çalışmanın Avantajları Nelerdir?",
  "Freelance Çalışmanın Dezavantajları Nelerdir?",
  "Hangi Freelance Alanlarında Müşteri Talebi Yüksektir?",
];

export const _faqs_bizifest_platform_descriptions = [
  "Bizifest adını “Business” ve “Festival” kelimelerinden alan ve Türkçeye “İş Festivali” olarak çevrilen yenilikçi bir freelance platformudur.\n" +
    "\n" +
    "Dijital alanda hizmet veren yetenekli freelancer çalışanlar ile müşterileri bir araya getirir. Birden fazla ayağı olan platform projesinin ilk ayağı forum kısmıdır.",
  "CodeMakerz Yazılım A.Ş. tarafından oluşturulan ve desteklenen Bizifest Platformu’nun ana amacı; yeniliği benimseyen ve bunu yaşam tarzı haline getiren, dijital alanda hizmet veren kaliteli freelance çalışanlar ile müşterileri bir araya getirmektedir.\n" +
    "\n" +
    "Oluşturacağımız topluluk ile freelance çalışanlar ticaretin dışında; kendilerini yeni bilgilerle geliştirebilir, soru sorabilir, eğitim alabilir, portfolyo oluşturabilir...",
  "Bizifest Freelance Topluluğu senin için var! Bu topluluğun gelişmesi ve ilerlemesi için her türlü fikrini açıkça paylaş. \n\nYeni tasarım fikirlerin, sistemdeki olası aksaklıklar ve diğer her türlü görüşünü duymaktan keyif alırız.",
  "Freelancer olarak, forum üzerinden bilgi paylaşımı yapabilir, portfolyonu sergileyebilir ve yeni müşteriler bulabilirsin.\n" +
    "\n" +
    "Aynı şekilde müşteri olarak da, Bizifest sisteminin özel algoritmaları tarafından puanlanan kaliteli freelance çalışanlara gönül rahatlığı ile işlerini verebilirsin.",
  "Uzun yıllar freelance olarak sektörde hizmet verdikten sonra kendi firmamızı kurarak yurtiçi ve yurtdışı yazılım, sistem ve tasarım konularında çalışmaya başladık.\n" +
    "\n" +
    "Ama freelance olarak çalıştığımız yıllardaki o sıkıntıları hiçbir zaman unutmadık.\n" +
    "\n" +
    "Emek sahibinden daha çok para kazanan platformlar...\n" +
    "Şahısların yönettiği kurumsallıktan uzak forumlar...\n" +
    "Bu forum sahiplerinin her zaman arkadaşlarını bir adım önde tutması...\n" +
    "Ve senin de bildiğin daha neler neler...\n" +
    "\n" +
    "İşte bütün bu olumsuzlukların olabildiğince önüne geçmek, senin de fikirlerini alarak şu ana kadar hiç bir zaman oluşturulmamış bu kaliteli topluluğu oluşturmak istiyoruz.\n" +
    "\n" +
    "Bu topluluk için 1 yılı aşkın süredir iş analistleri ile çalışarak çok çeşitli freelancer’lar ile görüşerek binlerce geri bildirim topladık. Platformumuzun ilk ayağı olarak Forum sitesi artık seninle! Forum sitesinin devamında ise ihtiyacın olduğunu bildiğimiz diğer sistemleri ayağa kaldırmaya başlayacağız.",
  "Hepimizin. Bizifest Freelance Platform, yeniliği seven ve dijital alanda hizmet veren/alan herkesin ortak değeridir. \n\nBiz CodeMakerz Yazılım A.Ş. olarak dijital alanda freelance sektöründe hizmet veren ve hizmet alan kullanıcılara daha keyifli, güvenilir ve yenilikçi bir ortam oluşturmak için ilk adımı attık.\n\nBu platformu hepimizin yenilikçi fikirleri ve iyileştirmeleri ile birlikte büyüteceğiz.",
  "Bizifest topluluğuna katıldıktan sonra, Yeni Konu Oluştur -> Ticari Konu Oluştur seçeneği ile vermek istediğin hizmete yönelik konu açabilirsin. \n\nBizifest sistemi Ticari olarak açılan konuları otomatik olarak muhtemel müşterilere göstermeye başlayacaktır. \n\nUnutmadan; konu açarken ne kadar spesifik olursan o kadar yüksek ihtimalle doğru müşteriyi bulabilirsin.",
  "\n" +
    "Freelance çalışmak, bağımsız çalışan kişilerin belirli projeleri veya görevleri gerçekleştirmek için sözleşmeli olarak başkalarıyla çalıştığı bir iş modeline verilen isimdir. \n\nBu bağımsız çalışanlar, genellikle serbest meslek sahipleri veya serbest çalışanlar olarak adlandırılırlar. \n\nFreelancerlar, işverenlerle belirli bir projenin tamamlanması veya belirli bir hizmetin sunulması için anlaşmalar yaparlar ve genellikle bu işleri evden veya uzaktan yaparlar.",
  "1. Özgürlük ve Bağımsızlık: Freelance çalışmak, işini kendi kurallarına göre yapabilmen anlamına gelir. Kendi iş saatlerini, müşterilerini ve projelerini seçme özgürlüğüne sahipsindir.\n" +
    "\n" +
    "2. Esneklik: Freelance çalışma, iş hayatı ile kişisel yaşamı daha iyi dengelemeni sağlayabilir. Tatil yapmak, aile zamanı ayırmak ve hobilerle ilgilenmek için daha fazla esnekliğe sahip olabilirsin.\n" +
    "\n" +
    "3. Daha Geniş Müşteri Tabanı: Freelance çalışırken, farklı endüstrilerden ve yerlerden müşterilere hizmet verebilirsin. Bu, iş deneyimini çeşitlendirmene ve daha geniş bir müşteri tabanı oluşturmana yardımcı olabilir.\n" +
    "\n" +
    "4. Kendi Kazanç Potansiyeli: Freelance çalışanlar, genellikle kendi fiyatlarını belirleyebilirler. Başarılı projeler ve müşteri ilişkileri kurarak, gelirini artırma potansiyelin daha yüksek olabilir.\n" +
    "\n" +
    "5. Kariyer Gelişimi: Freelance çalışma, farklı projeler üzerinde çalışarak ve yeni beceriler öğrenerek sürekli kariyer gelişimini teşvik edebilir.",
  "1. İş Güvencesi Yokluğu: Freelance çalışanlar, sabit bir iş güvencesi olmadan çalışırlar. Bu, gelir istikrarı konusunda belirsizlik yaratabilir.\n" +
    "\n" +
    "2. Kendi Mali Sorumlulukları: Freelance çalışanlar, kendi işlerini yönetmek ve mali sorumlulukları üstlenmek zorundadır. Vergiler, sigorta ve emeklilik gibi konularla ilgilenmelidirler.\n" +
    "\n" +
    "3. Kendi İşinizi Yönetme Yükü: Freelance çalışma, projeleri, müşteri ilişkilerini ve finansal işleri kendiniz yönetmeyi gerektirir. Bu, bazen stresli olabilir.\n" +
    "\n" +
    "4. Sağlık Sigortası ve Diğer Avantajlar: Freelance çalışanlar, kendileri sağlık sigortası ve diğer avantajlar gibi işveren sağladığı faydalardan yoksun olabilirler.",
  "Dijital freelance alanlarında müşteri talebi sürekli olarak değişebilir, ancak bazı alanlar genellikle daha yüksek talep görmektedir. \n\nÜlkemiz şartlarında en yüksek freelance hizmet talepleri; Yazılım, Grafik Tasarım, Makale Yazımı ve Sosyal Medya Yönetimi gibi konularda olmaktadır. ",
  "Ticari Konular, normal açtığımız konuların ticaret için özelleştirilmiş halidir. \n\nNormal konularda elde edemeyeceğin 'Ticaret Puan'larını' toplayabieceğin, seni ve karşı tarafı isteğe bağlı olarak Sözleşme ve Hakediş'lerle korumayı amaçlayan gönderi türüdür. ",
  "İş teklifleri için nasıl daha etkili teklifler hazırlanır?",
  "Freelance çalışırken vergi konuları hakkında bilgi sahibi olan var mı?",
  "Freelance işler için özgeçmiş nasıl düzenlenir?",
  "İş başvurularında hangi referansları paylaşmalıyım?",
  "Freelance işlerde hedef kitlemi nasıl belirlerim?",
  "Hangi online ödeme platformları daha güvenilir?",
  "Freelance çalışırken motivasyonu nasıl yüksek tutabilirim?",
  "Freelance işlerde müşteri şikayetleriyle nasıl başa çıkılır?",
  "Güncel iş trendleri ve sektör önerileri paylaşabilir misiniz?",
  "Hangi yazılım dilleri veya teknolojiler daha fazla talep görüyor?",
  "Freelance işlerde iş sözleşmeleri nasıl oluşturulur?",
  "Hangi online topluluklar freelance profesyoneller için faydalı?",
  "Freelance işlerde zaman yönetimi ipuçları nelerdir?",
  "Freelance çalışanlar için sağlık sigortası seçenekleri hakkında bilgi sahibi olan var mı?",
];

export const _faqs_trading_post_headings = [
  "Ticari Konu Nedir?",
  "Normal Konu VS Ticari Konu",
  "Neden Ticari Konu ile Alım-Satım Yapmalıyım?",
  "Ticari Konu Ayrımı Neden Var?",
  "Ticari Konu Sözleşme Doldurmalıyım?",
  "Ticari Konuya Kendim Başka Bir Teklif Verebilir Miyim?",
  "Ticari Konu Açma Limiti Nedir?",
  "Ön Ödeme Yapmalı Mıyım?",
  "Hakediş Belirlemeli Miyim?",
  "Garanti Nedir?",
];
export const _faqs_trading_post_descriptions = [
  "Ticari Konular, normal açtığımız konuların ticaret için özelleştirilmiş halidir. \n\nNormal konularda elde edemeyeceğin 'Ticaret Puan'larını' toplayabieceğin, seni ve karşı tarafı isteğe bağlı olarak Sözleşme ve Hakediş'lerle korumayı amaçlayan gönderi türüdür. ",
  'Forum akışında her türlü bilgiyi ya da soruyu "Normal Konu" açarak halledebilirsiniz.\n\n"Ticari Konu"nun "Normal Konu"lardan en büyük farkı bağlayıcılığının yüksek olması ve üyelere istatistik olarak geri dönüşüdür.\n\nTicari Konular üzerinden yapılan işlemler üye profiline ayrıca yansıtılır.',
  "Bizifest kurallar çerçevesinde son derece yenilikçi ve özgür bir platformdur. Ticari işlerini Normal Konu açarak da tabiki gerçekleştirebilirsin. Ama Ticari Konu üzerinden gerçekleştirilmeyen alışverişlerin profiline yansıtılmayacağını unutma...\n\nBiz genel olarak herhangi bir hizmet/ürün alışverişinde Ticari Konu kullanmanızı tavsiye ederiz. Hem ticaretinizin ciddiyeti belirlenmiş olur, hem de alıcı ya da satıcı olarak ticari başarın profiline yansır.",
  "Ticari Konular sayesinde dijital alışverişlerinizi bir sözleşmeye bağlayabilir, hakedişler ayarlayabilir ve son teslim tarihlerini oluşturabilirsin.\n\nSistem üzerinden açılan Ticari Konular titizlikle takip edilir. Olası anlaşmazlıkların önüne geçmek ve üye bazlı ticari itibarın oluşturulması için bu özel konu tipi oluşturulmuştur.",
  "Sözleşme alıcı ve satıcının istediğine bağlı olarak doldurulan yasal olarak bağlayıcı bir formdur. 2 tarafında rızası ve istediğiyle oluşturulmuş ve onaylanmış bir sözleşmenin hukuki açıdan bağlayıcılığı vardır.\n\nHerhangi bir ticari anlaşmazlıkta, Bizifest taraflar arasındaki yazışmaları ve sözleşmeyi yetkili mercilerin isteği üzerine sunmakla yükümlüdür.",
  "Tabiki verebilirsin. Ticari konuyu açan üyenin şartlarının bir kısmını uygun bulmadığında, yeni bir teklif oluştururak müzakere yapabilirsin.",
  "Her üye aktif olarak en fazla 3 adet Ticari Konu açabilir. Daha fazla ticari konu oluşturmak için önceki açılmış konularından bazılarını kilitlemen gerekiyor.",
  "Alıcı ve satıcının anlaşmasına bağlı olarak hizmet/ürün alım/satımında ön ödeme yapılabilir. Ön ödeme işlemi verilecek işin ciddiyetine ve büyüklüğüne göre değişebilir.\n\nTicaretin daha başarılı olması için biz genel olarak ön ödeme yapmayı tavsiye ediyoruz. Ama tabiki son karar senin :)",
  "Özellikle kapsamlı ve büyük işlerde hem müşterinin, hem de freelancer çalışan üyenin haklarını korumak adına taraflar arasında hakedişlerin belirlenmesi oldukça faydalıdır.\n\n Özellikle kapsamlı işlerin aylarca sürebileceği göz önünde bulundurulduğunda projenin iptal edilmesi durumunda freelancerın emeğinin boşa gitmemesi için hakedişlerin oluşturulmasını tavsiye ediyoruz.",
  "Taraflar arasında iş verilişinde belirlenen ve satıcının ne tür durumlara garantör olduğunun yazılı bir belgesidir.\n\nMesela satıcı tarafından teslim edilen yazılımın bir şekilde hata alması durumunda 2 ay süre ile hata giderme sözü vermesi bir garantidir.",
];

export const _faqs_reputation_headings = [
  "Bizifest Reputation Puanı Nedir?",
  "Nasıl Reputation Puanı Kazanırım?",
  "Reputation Puanı Ne İşe Yarar?",
  "Reputation Puanımı Nasıl Artırabilirim?",
  "Reputation Puanı Azalabilir Mi?",
  "Reputation Puanı devredilebilir Mi?",
  "Popüler Kullanıcılar Nasıl Belirleniyor?",
  "Reputation Puanı Yüksekse Güvenilir Mi?",
  "Başarılı Ticaret Reputation Puanı Kazandırır Mı?",
  "Reputation İçin Çalışmalı Mıyım?",
];

export const _faqs_reputation_descriptions = [
  "Bizifest Reputation Puanı (itibar puanı da denebilir), sistem üzerinde gerçekleştirdiğin spesifik aktiviteleri ödüllendiren bir sistemdir. \n\nAktif bir forum kullanıcısını, pasif kullanıcıların önüne çıkarır ve forumlardaki 'Reputation puanı' veya 'Reputation skoru' olarak adlandırılan bu ölçüt, bir forum üyesinin katkılarının kalitesini ve güvenilirliğini gösterir. Bu puan, forumlardaki diğer üyelere bu kişinin geçmişteki katkılarına dayanarak ne kadar güvenebileceklerini anlamalarına yardımcı olur.",
  "Bizifest sistemi üyelerin forum içi katkılarına göre otomatik olarak Reputation Puanı ile ödüllendirme yapar. Açtığın konuların beğenilmesi, sistem meydan okumalarını tamamlaman gibi aktiviteler Bizifest Reputation Puanı olarak sana geri döner. ",
  "Reputation Puanı, diğer forum üyelerinin senin geçmiş katkılarına dayalı olarak sana ne kadar güvenebileceklerini gösteren bir ölçüdür.",
  "Reputation Puanını artırmak için foruma ve yenilikçi freelance topluluğuna katkıda bulunmayı hedeflemen yeterli. Sistem otomatik olarak forum katkını ölçerek seni Reputation Paunı ile ödüllendirecektir.",
  "Bizifest sistemi, forum katkılarını reputation puanı ile ödüllendirdiği gibi, forum içindeki kurallara aykırı davranışları tespit ettiğinde reputation puanını azaltma cezası uygulayabilir.",
  "Hayır. Reputation Puanı hesaba ve üyeye ait, takas edilemez, devredilemez ve satılamaz bir güvenilirlik göstergesidir.",
  "Anasayfada yer alan Popüler Kullanıcılar segmenti; üyelerin reputation puanları, ticaret puanları ve diğer olumlu davranışları baz alınarak belirlenir.",
  "Daha yüksek reputation puanına sahip olan üyeler, genellikle daha fazla saygı ve itibar kazanırlar. Ancak bu puanlar sadece bir rehberlik sağlar ve gerçek dünya deneyimi ve güvenilirlik her zaman daha önemlidir.",
  "Tabiki. Bizifest Sistemi içerisinde Ticaret Puanı ve Reputation Puanı birlikte çalışır. Başarılı ticaretlerde büyük miktarda reputation puanı da kazanılır.",
  "Hayır. Reputation için değil, bu muhteşem topluluğa katkı sağlamak için çalışmalısın. Amaç güzel olduktan sonra sistem seni bir şekilde bulup ödüllendirecektir.",
];

export const _faqs_badges_headings = [
  "Rozet Sistemi Nedir?",
  "Rozet Türleri Nedir?",
  "Bronz Rozet Nedir?",
  "Gümüş Rozet Nedir?",
  "Altın Rozet Nedir?",
  "Rozetler Kalıcı Mıdır?",
  "Kazanabileceğim Rozetleri Nereden Görebilirim?",
  "Rozet ve Reputation İlişkisi Nedir?",
  "Yeni Rozetler Eklenecek Mi?",
  "Rozetlerin Katkısı Nedir?",
];

export const _faqs_badges_descriptions = [
  "Rozet; üyelerin forumda aktif olmaları, katkıda bulunmaları ve belirli başarıları elde etmeleri durumunda kazandıkları sanal ödüllerdir.",
  "Bizifest Freelance Platform'da 3 çeşit Rozet türü vardır; Gold(Altın), Silver(Gümüş) ve Bronze(Bronz).\n\nBu rozetler sistem tarafından tanımlanan belirli görevlerin tamamlanması ile alınabilir. Alınan rozetler kullanıcıların profillerine işlenir.\n\nRozet sayısının artması, kullanıcı profilini daha güvenilir kılar. Böylelikle kullanıcılar gerçekleştirecekleri ticarette daha rahat hareket ederler.",
  "Bu rozet tipi tamamlaması en kolay rozet tipidir. Bu rozetin rengi kahverengidir.",
  "Bu rozet tipi forumdaki orta seviye zorluktaki görevler karşılığında kazanılır. Bu rozetin rengi gridir.",
  "Bu rozet tipi forumdaki en zorlu görevler karşılığında verilir. Bu Rozetin rengi sarıdır.",
  "Evet. Kazanılan rozetler üye profiline yansır ve kalıcıdır.",
  "Rozet Şehri sayfasından Bizifest Sistemi'nde olan bütün rozetleri görebilirsin.",
  "Rozet ve Reputation sistemi birbirine sıkı sıkıya bağlıdır. Kazanılan rozet karşılığında ekstra reputation puanı kazanırsın. Ayrıca belirli reputation puan seviyelerinde de ekstra rozet elde edersin.",
  "Tabiki. Özellikle sistem içerisinde gerekli gördüğün ve öneri de bulunmak istediğin bir rozet var ise fikirlerini duymak isteriz.",
  "Rozetler kullanıcıların profillerine işlenir.\n\nRozet sayısının artması, kullanıcı profilini daha güvenilir kılar. Böylelikle kullanıcılar gerçekleştirecekleri ticarette daha rahat hareket ederler.",
];

export const _faqs_general_headings = [
  "Kullanıcı Adı Uygunluğu Nedir?",
  "Kullanıcı Adımı Değiştirebilir Miyim? ",
  "Birden Fazla Hesabım Olabilir Mi?",
  "Doğum Günümü Değiştirebilir Miyim?",
  "Meta Bizifest Nedir?",
  "Neden Ayrı Meta Sitesi Var?",
  "Uymam Gereken Kurallar Neler?",
  "Bizifest Platform'u Aracılık(Escrow) Var Mı?",
  "Daha Güvenli Ticaret İçin Nelere Dikkat Etmeliyim?",
  "Ne Tür Konular Açabilirim?",
];
export const _faqs_general_descriptions = [
  "Bir kullanıcı adı daha önce başka bir kullanıcı tarafından alındıysa bu kullanıcı adı başka bir kullanıcı tarafından alınamaz. Buna ek olarak sistem tarafından engellenen bazı kelimeler ile de üyelik adı alınamaz.",
  "Bu aşamada kullanıcı adı değiştirilme özelliği bulunmamaktadır.",
  "Her üyenin yalnızca 1 üyeliği olabilir. Bunun dışındaki üyelikler multi hesap kapsamında değerlendirilir.",
  "Her hesap için yalnızca 1 seferlik doğum günü değiştirme vardır.",
  "Meta Bizifest; forum yönetimi, yazılımı ya da tasarımıyla ilgili fikirlerini paylaşabileceğin bir sitedir.",
  "Forum geliştirilme kısmını normal forum konularından ayırmak ve sana daha kolay bir hizmet sağlamak adına ayrıca meta.bizifest.com sitesini oluşturduk. ",
  "Bizifest; 6698 sayılı Kişisel Verilerin Korunması Kanunu, 5651 Sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlar ile Mücadele Edilmesi Hakkında Kanun, 5846 Sayılı Fikir ve Sanat Eserleri Kanunu, 5237 Sayılı Türk Ceza Kanunu, 5187 Sayılı Basın Kanunu ve diğer ilişkili Türkiye Cumhuriyeti kanunları ve mevzuat hükümleri çerçevesinde yasal yükümlülüklerini yerine getirerek hizmet sunmaktadır.\n\nKısacası sen de bu kurallara uygun bir şekilde hareket etmelisin.",
  "Hayır!",
  "Kullanıcıların profillerini, açtığı konularını, varsa önceki ticaretlerini incelemen gerekiyor. Bizifest Forum; alıcı ve satıcıyı aynı alanda toplayan bir sistemdir ve halihazırda bir havuz sistemi barındırmamaktadır.",
  "Platform üzerinde dijital alanda hizmet veren ve alan freelancer üyelerin olduğunu unutmadan; teknoloji, yazılım, grafik tasarım ya da yenilikler ile ilgili konuların açılması önceliğimizdir.\n\nBizifest Freelance Platformu’nun Forum kısmında aşağıdaki davranışlar yasaktır: aşağılama, alaycılık, küçümseme, saygısızlık, müstehcenlik, kabalık, iftira, nefret söylemi, tehdit, huzursuzluk çıkarma amaçlı yayınlar, spoiler içeren içerikler, kullanıcıların kimliğini ifşa etme, yetişkin içerik, korsan yazılım, warez, crack, şifre kırma gibi faaliyetler, Türkiye Cumhuriyeti yasalarına, uluslararası kanunlara, tüzüklere veya anlaşmalara aykırı hareket etme, ülke çıkarlarına, kişilik haklarına veya toplum ahlak kurallarına karşı konular açma ve içerikler paylaşma, rahatsız edici linkler verme ve tartışmaları amaçlarının dışına çıkarma.",
];

export const _faqs_system_headings = [
  "Bizifest Kaç Farklı Fazdan Oluşuyor?",
  "Forumdan Sonraki Aşama Ne?",
  "Peki Diğer Aşamalar?",
  "Bizifest Topluluğu'na Nasıl Yardımcı Olabilirim?",
  "Sistem İyileştirmesine Nasıl Yardım Edebilirim?",
  "Üyeliğimin Sonlandırılması İçin Ne Yapmalıyım?",
  "Üyeliğim Silindiğinde Verilerim Silinir Mi?",
  "Sistem Limitleri Neler?",
  "Nasıl Yenilik Öneririm?",
  "Reklam Verebilir Miyim?",
];
export const _faqs_system_descriptions = [
  "Bizifest Freelance Platformu, ilk aşamada 4 temel ayak üzerine kurgulanmıştır. İlk aşamada Forum kısmının tamamlanması hedeflenmektedir.",
  "Forum aşamasının geliştirilmesi tamamlandıktan sonra Blog kısmı hizmete giderektir.",
  "Sürprizi bozmak istemeyiz :) Blog aşamasına geçtiğimizde bir sonraki önemli aşamayı size duyuracağız.",
  "Bütün üyeler Bizifest sisteminin ayrılmaz parçasıdır. Bu topluluk içerisinde daha fazla katkı sağlamak istiyorsan, zaman içerisinde sistem tarafından moderasyon aşamalarına yönelik ekstra yetkilere sahip olabilirsin.",
  "Bizifest Freelance Platformu'nu sıfırdan muhteşem üyeler için hazırladık. Sistem yeni olduğundan bazı yazılımsal hataların olabileceğini öngörüyoruz.\n\nSistemde bulduğun hataları ya da çalışmayan kısımları bildirerek, sistemin daha iyi bir hale gelmesine yardımcı olabilirsin.",
  "Üyelik sonlandırılması ile ilgili olarak destek@bizifest.com e-posta adresine mail gönderebilirsin.",
  "KVKK ve diğer mevzuatlar gereği, üyelikle ilgili bilgiler belirli sürelerle saklanmak zorundadır. Silinen üyeliğin verileri sistem üzerinde görünmez hale getirilir.",
  "Sistem üzerinde neredeyse her aktivitenin üye bazlı spesifik limitleri bulunur. Kötüye kullanım amacı güdülmediği sürece bu limitlerden haberin bile olmayacak :)",
  "Yenilik için oluşturulmuş bir platforma, yenilikçi fikirler sunmamak olmaz. destek@bizifest.com e-posta adresinden fikirlerini duymak için sabırsızlanıyoruz.",
  "Şuan için hayır. Yakında gelecek güncellemeleri takip et.",
];

export const _forum_rules =
  "<strong>1. Takma isimler (nickname) :</strong> Üyelik sözleşmesi kapsamında, kullanıcılarımızdan forumda kullanacakları takma adlarını belirlemeleri istenmektedir." +
  "    <p>Türkiye Cumhuriyeti kanunlarına ve ahlak değerlerine uygun olmayan veya yasaları ihlal eden herhangi bir takma adın kullanımı kesinlikle yasaktır.</p></br>" +
  "    <p>Takma adlar, aşağıdaki kriterlere uygun olmalıdır:</p>" +
  "<ul>" +
  "    <li>Yasalara uygun olmalı ve yasaları ihlal etmemelidir.</li>" +
  "    <li>Ahlaki değerlere saygı göstermelidir.</li>" +
  "    <li>Irk, cinsiyet, din, dil veya diğer ayrımcı unsurları içermemelidir.</li>" +
  "    <li>Tehdit edici, hakaret içeren, müstehcen veya saldırgan olmamalıdır.</li>" +
  "    <li>Başkalarının gizliliğini veya kişisel bilgilerini ifşa etmemelidir.</li>" +
  "    <li>Diğer kullanıcıları yanıltıcı veya kafa karıştırıcı olmamalıdır.</li>" +
  "    <li>Marka veya telif hakkı ihlali yapmamalıdır.</li>" +
  "    <li>Diğer yasal veya ahlaki sınırlamaları ihlal etmemelidir.</li></br>" +
  "</ul>" +
  "<u><p>Ayrıca : </p></u>" +
  "<ul>" +
  "<li>Moderasyon ekibimiz, takma adların uygunluğunu değerlendirme ve denetleme hakkına sahiptir.</li>" +
  "<li>Herhangi bir takma adın uygun olmadığı tespit edilirse, Moderasyon Ekibimiz tarafından kullanıcıya bildirim yapılarak uygun bir takma ad seçmesi istenebilir ya da hesap direkt olarak askıya alınabilir.</li>" +
  "<li>Kullanıcılarımız, takma ad seçimi konusunda sorumluluk taşımaktadır ve seçtikleri takma adların yasalara ve ahlaki değerlere uygun olduğunu teyit etmekle yükümlüdürler.</li>" +
  "<li>Moderasyon ekibimiz, Türkiye Cumhuriyeti kanunlarına aykırı veya ahlak değerleri dışında herhangi bir takma ad kullanımını tespit ettiğinde gerekli önlemleri alma hakkına sahiptir, bu da takma adın silinmesi veya hesabın askıya alınması gibi önlemleri içerebilir.</li>" +
  "<li>Bu kurallara uymayan takma adlar, forumun amacını bozan veya diğer kullanıcıları rahatsız eden unsurlar olarak değerlendirilecektir. Kullanıcılarımız, bu kuralı kabul ederek uygun takma adlar kullanacaklarını beyan etmektedirler.</li>" +
  "</ul>" +
  "<br>" +
  "<p><strong>2. Spam/Flood Yapma ve Gereksiz İçerikler:</strong></p>" +
  "<ul>" +
  "    <li>Üyelik sözleşmesi çerçevesinde, üyelerimizden spam oluşturma ve flood yapmama konusunda hassasiyet göstermeleri beklenmektedir.</li>" +
  "    <li>Spam gönderi yapmak, sürekli aynı mesajı tekrarlamak veya forumu gereksiz yere dolduracak şekilde mesajlar göndermek kesinlikle yasaktır.</li>" +
  '    <li>Üyelerimiz, kendi konularını "güncel" veya "up" gibi çeşitli kelime yorumlarıyla en son cevaplanan konular arasına taşıma gibi eylemlerden kaçınmalıdır.</li>' +
  "    <li>Üyelerimizin, forumun düzenini veya konuşma akışını bozan, bilgi paylaşımını zorlaştıran veya diğer üyelerin deneyimini olumsuz etkileyen gereksiz içerikler paylaşması yasaktır.</li>" +
  "    <li>Üyelerimiz, forum kuralları gereği bu tür davranışlara yönelik belirli yaptırımlarla karşılaşabileceklerini kabul etmektedirler.</li>" +
  "</ul>" +
  "<p><br></p>" +
  "<p>Bu kurallara uymayan spam veya flood içeren gönderiler, forumun düzenini bozan ve diğer kullanıcıları rahatsız eden unsurlar olarak değerlendirilecektir. Yönetim ekibimiz, bu tür durumları tespit ettiğinde gerekli önlemleri alma hakkına sahiptir, bu da ilgili gönderilerin silinmesi, kullanıcının uyarılması veya hesabın askıya alınması gibi önlemleri içerebilir.</p></br>" +
  "<p>Üyelerimiz, bu kuralı kabul ederek spam oluşturma ve flood yapmama konusunda sorumluluk taşımaktadır. Forumun düzenli ve verimli bir ortam olması için üyelerimizin bu kurallara uygun davranmaları gerekmektedir. Bu sayede herkesin katkıda bulunabileceği etkili bir iletişim platformu sağlanacaktır.</p>" +
  "<br>" +
  "<p><strong>3. Konu Açma ve Yorum Yazma Kuralları:</strong></p>" +
  "<ul>" +
  "<li>Üyelik sözleşmesi kapsamında, her kategori için moderasyon ekibi tarafından belirlenen saatlik ve günlük konu açma limitleri, kategori bazlı konu açık konu limiti ile mesaj yazma limitleri bulunmaktadır.</li>" +
  "<li>Bu limitler, ayrı bir sayfada kullanıcılara sunulacak ve moderasyon ekibi tarafından ihtiyaçlar doğrultusunda dinamik bir şekilde güncellenecektir.</li>" +
  "<li>Amaç, forum düzeninin sağlanması ve forum kirliliğinin önüne geçilmesidir.</li>" +
  "<li>Limitlerin belirlenmesinde, kullanıcıların deneyimlerini geliştirmeleri ve etkileşimde bulunmaları için dengeli bir ortam yaratma amacı gözetilmektedir.</li>" +
  "<li>Limitlere uyum sağlamak, forumun düzenini ve kalitesini korumak için önemlidir.</li>" +
  "<li>Moderasyon ekibi, limitleri güncelleme ve gerektiğinde değiştirme hakkına sahiptir. Bu değişiklikler, forumun ihtiyaçlarına ve kullanıcı deneyimine uygun olarak yapılacaktır.</li>" +
  "<li>Limitlere uymayan veya aşan kullanıcılar, moderasyon ekibi tarafından uyarılabilir ve gerekli görüldüğünde ilgili yaptırımlar uygulanabilir.</li>" +
  "</ul>" +
  "<p><br></p>" +
  "<p>Bu konu açma ve mesaj yazma limitleri, forumun düzenini ve kullanıcıların deneyimini geliştirme amacı taşımaktadır. Üyelerimiz, limitlere uymayı kabul ederek forumun sağlıklı işleyişine katkıda bulunacaklarını beyan etmektedirler.</p>" +
  "<p><br></p>" +
  "<p><strong>4. Özel Mesaj Yazma Kuralları:</strong></p>" +
  "<ul>" +
  "    <li>Kullanıcılarımızın özel mesajlarında flood yapmamaları, spam içerik göndermemeleri ve ticari reklam yapmamaları önemlidir.</li>" +
  "    <li>Türkiye Cumhuriyeti kanunlarına aykırı linkler veya içeriklerin forum genelinde olduğu gibi, özel mesaj yoluyla da paylaşılması kesinlikle yasaktır.</li>" +
  "    <li>Özel mesajlar, iletişimin gizlilik ve kişisel alanın saygınlığına dikkat edilerek kullanılmalıdır.</li>" +
  "    <li>Kullanıcılarımız, özel mesajlarda aşağıdaki kurallara uymakla yükümlüdürler: </li>" +
  "<ul>" +
  "<li>Flood yapmamak ve spam içerik göndermemek.</li>" +
  "<li>Ticari reklam yapmamak,</li>" +
  "<li>Türkiye Cumhuriyeti kanunlarına aykırı linkler veya içerikler paylaşmamak.</li>" +
  "<li>Karşı tarafa rahatsızlık verici, tehdit edici veya hakaret içeren mesajlar göndermemek.</li>" +
  "<li>Gizlilik haklarına saygı göstermek ve kişisel bilgileri ifşa etmemek.</li>" +
  "<li>Kullanıcının rızası olmadan ticari veya reklam içerikli mesajlar göndermemek.</li>" +
  "</ul>" +
  "</ul>" +
  "<ul>" +
  "    <li>Moderasyon Ekibimiz, özel mesajlar aracılığıyla yapılan ihlalleri izleme ve denetleme hakkına sahiptir. Bu tür ihlaller tespit edildiğinde gerekli önlemler alınabilir, bu da ilgili mesajların silinmesi, kullanıcının uyarılması veya hesabın askıya alınması gibi önlemleri içerebilir.</li>" +
  "</ul>" +
  "<p>Özel mesaj yazma kuralları, kullanıcılarımızın iletişimde saygılı, etik ve yasalara uygun davranmalarını sağlamak için konulmuştur. Kullanıcılarımız, bu kuralları kabul ederek özel mesaj gönderimlerinde uygun davranacaklarını beyan etmektedirler.</p>" +
  "<p><br></p>" +
  "<p><strong>5. Ticari Konular:</strong></p>" +
  "<ul>" +
  "    <li>Üyelik sözleşmesi kapsamında, kullanıcılarımızın normal konuların yanı sıra dijital freelance olarak ticari konular açmalarına izin verilmektedir. Bu ticari konular, dijital hizmetlerin alınması, verilmesi veya satılması gibi ticari işlemleri içermektedir.</li>" +
  "    <li>Ticari konular, genel konulardan farklıdır. Genel konular, gündem, bilgilendirici konular, haberler gibi çeşitli konuları kapsarken, ticari konularda bir ürün veya hizmetin alımı, satımı veya sağlanması gerekmektedir.</li>" +
  '    <li>Ticari konular içindeki anlaşmazlıkların ve sorunların çözüme kavuşturulması için, ticaretle ilgili konu açacak kullanıcıların "ticari konu" seçeneğini işaretlemeleri zorunludur.</li>' +
  "    <li>Ticari konular, moderasyon ekibi tarafından titizlikle takip edilmekte ve içerisinde yer alan hizmet/ürün alıcıları ve sağlayıcıları arasında bağlayıcı bir sözleşme bulunmaktadır.</li>" +
  "    <li>Kullanıcılar, ticari konular içerisindeki işlemleri gerçekleştirmeden önce kendi aralarında bir sözleşme yapmak ve bu sözleşmeyi dijital olarak onaylamakla yükümlüdürler.</li>" +
  "    <li>Ticari konuların sağlıklı bir şekilde yürütülmesi ve kullanıcıların güvenli bir forum deneyimi yaşamaları amacıyla, moderasyon ekibi ilgili konuları yakından takip etmektedir.</li>" +
  "</ul>" +
  "<p><br></p>" +
  "<p>Bu ticari konular, kullanıcılarımız arasında işbirliğini ve dijital hizmet alışverişini teşvik etmektedir. Ancak, ticari konuların içerisindeki işlemler ve anlaşmalar, kullanıcılar arasında gerçekleşen bağımsız işlemler olduğu için forumun sorumluluğu bulunmamaktadır. Kullanıcılarımız, ticari konularda gerekli önlemleri alarak güvenli ve etkili bir işlem gerçekleştirmeyi kabul etmektedirler.</p>" +
  "<p><br></p>" +
  "<p><strong>6. Hesap ve Profil Kuralları:</strong></p>" +
  "<ul>" +
  "    <li>Üyelik sözleşmesi kapsamında, kullanıcıların hesapları ve profilleriyle ilgili bazı kurallar bulunmaktadır.</li>" +
  "    <li>Kullanıcılar, kendi hesap giriş bilgilerini (kullanıcı adı, e-posta, telefon numarası, şifre vb.) saklamaktan tamamen sorumludur. Bu bilgilerin güvenliği kullanıcının sorumluluğundadır.</li>" +
  "    <li>Birden fazla hesap açma (multi hesap) kesinlikle yasaktır. Her kullanıcı, yalnızca tek bir hesap kullanmalıdır.</li>" +
  "    <li>Kullanıcıların profil bilgilerinde (kullanıcı adı, avatar fotoğrafı, arkaplan fotoğrafı ve diğer bilgi alanları) yasa dışı veya forum kurallarına aykırı içerik bulundurması yasaktır.</li>" +
  "    <li>Kullanıcılar, profillerinde herhangi bir şekilde yasadışı, ahlaka aykırı, hakaret içeren, saldırgan veya zarar verici içerikler kullanmamalıdır.</li>" +
  "    <li>Kullanıcılar, profil bilgilerini dürüst, doğru ve güncel şekilde doldurmalıdır. Yanıltıcı bilgiler vermek veya başka bir kişinin kimliğini taklit etmek kesinlikle yasaktır.</li>" +
  "    <li>Forum yönetimi, kullanıcıların hesap ve profil bilgilerini denetleme hakkına sahiptir ve gerekli gördüğü durumlarda düzeltmeler yapabilir veya ihlal edenleri uyarabilir.</li>" +
  "    <li>Kullanıcılar, hesaplarını başka bir kullanıcıya devretme veya paylaşma hakkına sahip değillerdir.</li>" +
  "    <li>Üyeliğin durdurulması veya hesabın silinmesi talepleri durumunda, kullanıcılar Moderasyon ekibi ile iletişime geçmelidir. Bu veriler, ihtiyaç duyulması halinde yasal mevzuata uygun süre ve yerde Bizifest Platformu tarafından muhafaza edilmeye devam edecektir. Log kayıtları, yasal mevzuat gereği olan surece silinmeyecektir. Silinen hesabın verileri, sadece talep üzerine diğer ziyaretçilere gösterilmeyecektir.</li>" +
  "</ul>" +
  "<p><br></p>" +
  "<p>Bu hesap ve profil kuralları, forumun güvenliğini ve düzenini sağlamak, kullanıcıların kimliklerini korumak ve istenmeyen içeriklerin yayılmasını engellemek amacıyla konulmuştur. Kullanıcılarımız, bu kuralları kabul ederek hesap ve profil bilgilerini yasalara uygun, dürüst ve etik değerlere uygun bir şekilde kullanacaklarını beyan etmektedirler.</p>";

export const _membership_agreement =
  "<p><strong>1. Taraflar</strong></p>" +
  `<p>İşbu üyelik sözleşmesi, Bizifest Freelance Platformu (Kısaca site yada platform olarak anılacaktır) ile Bizifest Freelance Platformu'na üye olurken üyenin elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.</p></br>` +
  `<p>Kullanıcı, Site'ye üye olurken verdiği elektronik onayla, Üyelik Sözleşmesi'nin tamamını okuduğunu, içeriğini bütünüyle anladığını ve tüm hükümlerini onayladığını kabul, beyan ve taahhüt eder.</p>` +
  "<p><br></p>" +
  "<p><strong>2. Sözleşmenin Amacı</strong></p>" +
  `<p>Bu sözleşme, Bizifest Freelance Platformu'na üye olmasını takiben sağlanan tüm hizmetlerden faydalanan Kullanıcı ile Bizifest Freelance Platformu arasındaki tüm yükümlülükleri ve hakları düzenlemek amacıyla hazırlanmıştır.</p></br>` +
  "<p>Bu sözleşme, Kullanıcı ile Bizifest Freelance Platformu arasındaki ilişkilerin düzenlenmesinde ve platformun kullanımına ilişkin tüm hususların belirlenmesinde temel referans kaynağıdır.</p></br>" +
  "<p>Sözleşmenin amacı, Kullanıcının platforma üye olması, platform üzerinde sunulan hizmetlerden faydalanması, kullanım koşullarını kabul etmesi, sorumluluklarını yerine getirmesi ve platformun sağladığı imkanları doğru ve etkin bir şekilde kullanmasıdır.</p></br>" +
  "<p>Bu sözleşme, Kullanıcı ve Bizifest Freelance Platformu arasında karşılıklı güven, şeffaflık ve adil bir ilişki kurmayı hedefler. Tarafların haklarını, yükümlülüklerini, gizlilik politikasını, telif hakkı korumasını ve diğer önemli konuları düzenler.</p></br>" +
  "<p>Kullanıcı, bu sözleşmeyi kabul etmekle, platforma üye olurken platformun sağladığı hizmetlerden yararlanmak için tüm yükümlülükleri ve hükümleri yerine getirmeyi taahhüt eder.</p>" +
  "<p><br></p>" +
  "<p><strong>3. Tanımlar</strong></p>" +
  "<p>İşbu üyelik sözleşmesinde yer alan tanımlar aşağıdaki şekilde düzenlenebilir:</p>" +
  "<ul>" +
  `    <li>Bizifest, platform veya "Bizifest Freelance Platform": CodeMakerz Yazılım A.Ş.'ye ait olan bizifest.com internet sitesini,</li>` +
  '    <li>Forum veya "Bizifest Forum": Bizifest platformunun bir ayağı olan Forum arayüzünü,</li>' +
  "    <li>Kullanıcı: Sisteme kayıt başvurusu yapan her bireyi,</li>" +
  "    <li>Üye: Başvurusu onaylanan kullanıcıyı,</li>" +
  "    <li>Ziyaretçi: Bizifest platformuna üye olmadan sitede gezinen kişileri,</li>" +
  "    <li>Forum Kuralları: Bizifest platformunun forum kısmının işleyebilmesi için Bizifest moderasyon ekibi tarafından belirlenen kurallar bütününü,</li>" +
  `    <li>Moderasyon ekibi: Forum'un Forum Kuralları dahilinde modere edilmesini sağlayan ekibi,</li>` +
  `    <li>"Yönetici" veya "Admin": Forumun mülkiyetine ve tüm yasal haklarına sahip olan CodeMakerz Yazılım A.Ş.'yi,</li>` +
  "    <li>Yönetim: Yönetici ve moderatörleri,</li>" +
  "    <li>Genel Moderatör: Yönetici tarafından belirlenen, forum kurallarının tüm forumda uygulanmasında ve genel işleyişin düzenlenmesinde görevli kişiyi,</li>" +
  "    <li>Kategori Moderatörü: Yönetici tarafından bir forum kategorisine atanmış ve atandığı kategoride forum kurallarının uygulanmasında ve kategorinin işleyişinin düzenlenmesinde görevli kişiyi,</li>" +
  "    <li>Kategori : Yönetici tarafından belirlenen ihtiyaçlara göre oluşturulan, üyelerin konu açabilecekleri ve ileti paylaşabilecekleri özel forum alanlarını,</li>" +
  "    <li>Konu : Forum sisteminde üyelerin açabileceği veya tartışabileceği belirli bir başlık, konu veya sorunu,</li>" +
  "    <li>Yorum : Üyelerin forum sistemindeki konular altında kendi görüşlerini, yorumlarını veya yanıtlarını paylaştığı ileti veya katkıyı,</li>" +
  `    <li>KVKK Aydınlatma Metni: 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında hazırlanan Aydınlatma Metni'ni.</li>` +
  "    <li>Gizlilik ve Çerez Politikaları: KVKK Aydınlatma Metni kapsamında hazırlanan veri kayıt ve güvenliğine ilişkin politikaları</li>" +
  "</ul>" +
  "<p>ifade eder.</p>" +
  "<p><br></p>" +
  "<p><strong>4. Genel Hükümler</strong></p>" +
  `<li>Forum moderasyon ekibi (forum yöneticileri ve moderatörleri), uygunsuz veya itiraz edilebilecek her türlü içeriği mümkün olan en kısa sürede silmeye gayret gösterse de, tüm mesajlar incelenemeyebilir. Forumlara gönderilen her mesaj, gönderenin görüşlerini yansıtmakta ve gönderen yazarının sorumluluğundadır. Bizifest Freelance Platformu'nun kurucusu CodeMakerz Yazılım A.Ş. BTK onaylı yer sağlayıcı olduğundan dolayı, kullanıcı mesajlarının içeriğinden dolayı sorumlu tutulamazlar, yalnızca kendi iletişimlerinden sorumludurlar.</li></br>` +
  `<li>Bizifest Freelance Platformu'nun Forum kısmında aşağıdaki davranışlar yasaktır: aşağılama, alaycılık, küçümseme, saygısızlık, müstehcenlik, kabalık, iftira, nefret söylemi, tehdit, huzursuzluk çıkarma amaçlı yayınlar, spoiler içeren içerikler, kullanıcıların kimliğini ifşa etme, yetişkin içerik, korsan yazılım, warez, crack, şifre kırma gibi faaliyetler, Türkiye Cumhuriyeti yasalarına, uluslararası kanunlara, tüzüklere veya anlaşmalara aykırı hareket etme, ülke çıkarlarına, kişilik haklarına veya toplum ahlak kurallarına karşı konular açma ve içerikler paylaşma, rahatsız edici linkler verme ve tartışmaları amaçlarının dışına çıkarma. Her mesajın IP adresi ve giriş tarihi gerektiğinde yetkililere sunulmak üzere kaydedilmektedir. Kullanıcılar, Forum Moderasyon Ekibi'nin başlıkları silme, taşıma veya kilitleme yetkisini kabul etmektedir. Kullanıcılar, girdikleri bilgilerin veritabanında saklanacağını kabul etmektedir. Bu bilgiler, 3. şahıslara verilmemekle birlikte, olası bir "hack" olayında 3. şahısların eline geçmesi durumunda Forum Moderasyon Ekibi sorumlu tutulamazlar.</li></br>` +
  "<li>Forumumuz, orijinal yazılım kullanımını desteklemekte ve teşvik etmektedir. Hem site yapısında hem de sunucu altyapısında lisanslı işletim sistemleri ve yazılımlar kullanılmaktadır. Kullanıcılarımıza, tercih ettikleri yazılımların orijinal lisanslarını alarak kullanmalarını tavsiye ediyoruz.</li></br>" +
  '<li>Forumdaki mesajlarınız, Moderasyon Ekibi tarafından gerekli durumlarda değiştirilebilir, referans eklemeleri yapılarak anlam bütünlüğü korunabilir, kısmen veya tamamen silinebilir, taşınabilir veya cevap yazılamaz hale getirilebilir. Ayrıca, forum kuralları çerçevesinde hesabınız gerektiğinde süresiz olarak kapatılabilir. Üyeler, bu anlaşmayı kabul ederek yönetim kararlarını kabul etmiş sayılırlar. Bu uygulamalar, yöneticilerimizin forum düzenini sağlamak için uyguladığı yöntemlerden biridir. Eğer yanlış bir nedenle bu uygulamalarla karşılaştığınızı düşünüyorsanız, <a data-fr-linked="true" href="http://bizifest.com/destek">http://bizifest.com/destek</a> adresinden moderasyon ekibine ulaşabilir ve konunuzun veya mesajınızın neden silindiğini öğrenmek için destek formu oluşturabilirsiniz.</li></br>' +
  "<li>Bu forum sistemi, gezintinizi kolaylaştırmak amacıyla bilgisayarınızdaki çerezleri (cookie) kullanmaktadır. Çerezlerde, girdiğiniz özel bilgilerin hiçbiri bulunmamaktadır. Bu çerezlerin tek amacı, forumda daha rahat bir deneyim yaşamanızı sağlamaktır. Geçerli e-posta adresiniz, kaydınızı onaylamak, şifrenizi iletmek ve unuttuğunuzda size şifre hatırlatma amacıyla talep edilmektedir.</li></br>" +
  `<li>Bizifest Freelance Platformu'ndan gönderilecek her türlü bilgilendirme ve kampanya haberini almayı kabul ediyorum. Not: E-posta ve SMS almak istemeyen kullanıcılarımız profil ayarlarından bu seçeneği kaldırabilirler.</li></br>` +
  "<li>Kısaltılmış linkler kullanarak para kazanmak, backlink elde etmek amacıyla konu açmak veya mesajlar yazmak kesinlikle yasaktır. Kısaltılmış link verirken her zaman referanslı olmayan tam url linkini de vermek ve bu linklerin hangisinin referanslı link, hangisinin orijinal link olduğunu belirtmek zorunludur.</li></br>" +
  '<li>Sistem tarafından izin verilen limitler dahilinde "Ticari Konu" olmak kaydıyla, üyelerin kendi ürün ya da hizmetlerinin reklamını yapması ve konular açması serbesttir. Ticari Konu olarak işaretlenmeden reklam içerikli konular açmak veya mesajlar yazmak, sürekli kendi sitesinin reklamını yapmak veya başka bir amaçla reklam içeriği paylaşmak kesinlikle yasaktır.</li></br>' +
  '<li>Konu açarken konu başlıklarının açıklayıcı olması, bütün karakterlerin büyük harf olmaması ve gereksiz karakter ve semboller kullanılmaması gerekmektedir. Diğer Forum üyelerinin sadece konu başlığını okuyarak, özetle ne anlatıldığını anlamalarını sağlayacak şekilde başlıklar girilmelidir. "Yardım", "Bir bakar mısınız", "Bu ne demek" vb. tarzında çok genel ve konu içeriği anlaşılamayacak şekilde başlıklar girilmesi yasaktır.</li></br>';

export const _privacy_policy =
  "<p><strong>KVKK</strong></p>" +
  "<p>6698 Sayılı Kişisel Verilerin Korunması Kanunu (KVKK) kapsamında CodeMakerz Yazılım A.Ş. bünyesinde yer alan veriler, Türkiye Cumhuriyeti kanun hükümleri çerçevesinde kişilerin temel hak ve özgürlüklerini korumak amacıyla sistemde gerekli süre ve şartlarda işlenip saklanır.</p>" +
  "<p><br></p>" +
  "<p>CodeMakerz, 5651 Sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlar ile Mücadele Edilmesi Hakkında Kanun, 5846 Sayılı Fikir ve Sanat Eserleri Kanunu, 5237 Sayılı Türk Ceza Kanunu, 5187 Sayılı Basın Kanunu ve diğer ilişkili Türkiye Cumhuriyeti kanunları ve mevzuat hükümleri çerçevesinde yasal yükümlülüklerini yerine getirerek hizmet sunmaktadır.</p>" +
  "<p><br></p>" +
  '<p>Sitemiz içerisinde bizimle iletişim için göndermiş olduğunuz e-posta adresinden size ulaşabilir, kampanya ve diğer fırsatlarla ilgili iletişime geçebiliriz. E-posta listemizden ayrılmak için size göndermiş olduğumuz e-posta içerisinde yer alan "e-posta üyeliğinden çık" özelliğini kullanabilirsiniz.</p>' +
  "<p><br></p>" +
  "<p>CodeMakerz Yazılım A.Ş. hizmeti olan Bizifest Freelance Platformu (Kısaca Bizifest veya Platform olarak anılacaktır) olarak, kullanıcılarımızın gönüllü olarak sisteme girdikleri verileri kullanıcılarımızın rızası doğrultusunda saklamaktayız. Kullanıcı adı, e-posta adresi, telefon numarası gibi giriş için gerekli bilgiler, kullanıcı hesabıyla ilişkilendirilmiş gezilen konular, atılan mesajlar, favorilere eklenen içerikler ve platformun doğal işleyişiyle ilgili diğer kayıtlar, kullanıcının sonradan erişebilmesini sağlamak amacıyla sistemimizde tutulmaktadır. Kullanıcının gezdiği sayfalar, sisteme giriş yaptığı IP adresi, saati ve tarihi yasal yükümlülükler gereği belirli sürelerce sistemimizde kayıtlı olarak tutulmaktadır.</p>" +
  "<p><br></p>" +
  "<p>Kullanıcıların sitedeki ziyaretleri kapsamında çerezler aracılığıyla tutulan kayıtlarla reklam hedeflemeleri yapılabilir. Kullanıcı verileri, anonimleştirilerek kaydedilebilir ve kesinlikle kullanıcı hesabı, e-posta adresi, kullanıcı adı gibi bilgilerle ilişkilendirilmez.</p>" +
  "<p><br></p>" +
  "<p>Sistemde tutulan kullanıcı verilerinin saklı kalacağı süre garanti edilmemektedir. Bizifest, önceden haber vermeden verileri silebilir.</p>" +
  "<p><br></p>" +
  "<p>Herhangi bir anlaşmazlık durumunda Ankara Merkez Mahkemeleri yetkilidir.</p>" +
  "<p><br></p>" +
  `<p>CodeMakerz Yazılım A.Ş., Bizifest Freelance Platformu'nda yer alan; değişen teknolojiler, yeni hizmete alınan özellikler, yeni sunulan platformlar ve diğer durumlar gerektiğinde maddelerde değişiklik veya eklemeler yapma hakkına sahiptir. Bu değişiklikleri kontrol etmek için kullanıcılara açık olarak sunulan bu adresi ziyaret edebilirsiniz.</p>` +
  "<p><br></p>" +
  `<p>Sistemimizin işleyişi hakkında ek bilgiler için lütfen Üyelik Sözleşmesi'ne başvurunuz.</p>` +
  "<p><br></p>" +
  "<p><br></p>" +
  "<p><strong>Hangi Verileri Topluyoruz?</strong></p>" +
  "<p>Bizifest Freelance Platformu olarak, sağladığımız hizmetleri kullandığınızda doğrudan bizimle paylaştığınız bazı verileri kullanmaktayız.</p>" +
  "<p><br></p>" +
  "<p><u>Hesap Bilgileri:</u></p>" +
  "<p>Platforma hesap oluştururken kullanıcı adı, e-posta adresi, isim, soyisim ve şifre sağlamanız gerekmektedir. Kullanıcı adınız gerçek adınızla ilgili olması gerekli olmamakla birlikte herkese açık olarak görünecektir. Ayrıca, gerçek isminiz ve soyisimiz de herkese açık olarak görünecektir. Verdiğiniz bilgiler, hesabınıza ilişkin profil fotoğrafı da dahil olmak üzere hesap yönetimi amaçlarıyla saklanır. Profil bilgilerinizi yönetmek için aşağıdaki bağlantıyı kullanabilirsiniz:</p>" +
  "<p><br></p>" +
  "<p>https://bizifest.com/kullaniciAdiniz/</p>" +
  "<p><br></p>" +
  "<p><u>Gönderdiğiniz İçerikler:</u></p>" +
  "<p>Platformda sunduğumuz hizmetler kapsamında gönderdiğiniz içerikleri kaydetmekteyiz. Bu içerikler arasında gönderileriniz, yorumlarınız, diğer kullanıcılarla olan mesajlaşmalarınız (yanıtlar, alıntılar, özel mesajlar dahil), raporlarınız, kullanıcı notlarınız, moderatörler ve bizimle olan diğer iletişim kayıtlarınız bulunur.</p>" +
  "<p><br></p>" +
  "<p>Platformda paylaştığınız içerikler metin, bağlantı, resim, gif, video gibi çeşitli formatlarda olabilir.</p>" +
  "<p><br></p>" +
  "<p><u>Yaptığınız İşlemler:</u></p>" +
  "<p>Platformu kullanırken gerçekleştirdiğiniz işlemler hakkında bilgiler toplamaktayız. Bu işlemler, oylama, anket yanıtlama, mesaj beğenme, favorilere ekleme, takip etme, gizleme, engelleme, haber ve konu okuma, abonelikleriniz, arkadaş listeniz gibi topluluk etkileşimleri ve platform özelliklerinin kullanımıyla ilgili kayıtları içerir.</p>" +
  "<p><br></p>" +
  "<p><u>Diğer Bilgiler:</u></p>" +
  "<p>Doğrudan bize bilgi sağlayabilirsiniz. Örneğin, iletişim formu doldurduğunuzda, bir şikayet kaydında veya başka bir iletişim yöntemiyle bizimle iletişim kurduğunuzda, gerekli görüldüğünde bilgi toplayabiliriz.</p>" +
  "<p><br></p>" +
  "<p><u>Otomatik Olarak Toplanan Bilgiler:</u></p>" +
  "<p>Bazı hizmetlerimizi kullandığınızda veya sunucularımıza eriştiğinizde otomatik olarak bazı bilgiler hakkınızda toplanabilir. Bu bilgiler genellikle yasal zorunluluklar çerçevesinde kaydedilir. Kayıtlar, IP adresiniz, cihaz kimliği (DeviceID, UUID), tarayıcı adı, işletim sistemi, erişim zamanı, cihaz bilgileri, ziyaret edilen sayfalar, tıklanan bağlantılar, arama terimleri gibi bilgileri içerebilir.</p>" +
  "<p><br></p>" +
  "<p><u>Çerezler ve Benzer Teknolojilerle Toplanan Bilgiler:</u></p>" +
  `<p>Tarayıcınızın sakladığı ve talepte bulunurken bize gönderdiği bazı verilerden veya benzer teknolojilerden bilgi alabiliriz. Bu bilgiler, Bizifest Freelance Platformu'nun sunduğu hizmetler sırasında deneyiminizi geliştirmek, kullanıcı etkinliğini artırmak, içerikleri veya reklamları kişiselleştirmek, hizmet kalitesini iyileştirmek amacıyla kullanılabilir. Örneğin, saat diliminizi tutmak ve buna göre tarih ve saatleri göstermek amacıyla bu bilgileri kullanabiliriz.</p>` +
  "<p><br></p>" +
  "<p><u>Bağlantılı Hizmetler:</u></p>" +
  `<p>Bizifest Freelance Platformu hesabınıza erişim sağlamak için üçüncü taraf yetkilendirme sağlayıcılar tarafından sunulan özellikleri kullanarak hesabınızla ilişkilendirilmiş bilgileri saklayabiliriz. Örneğin, Google, Facebook, Apple gibi sağlayıcılara yetkilendirme vererek Bizifest Freelance Platformu'na kaydolabilir ve bu hesaplarınızı kullanarak Bizifest Freelance Platformu'na giriş yapabilirsiniz.</p>` +
  "<p><br></p>" +
  "<p><u>Hesabınızı Silme:</u></p>" +
  "<p>Hesap bilgilerinizi istediğiniz zaman, platforma giriş yaptıktan sonra destek talebi oluşturarak silebilirsiniz. Oluşturulan talep, Bizifest Freelance Platformu Destek ekibine ulaştıktan sonra talebinizle ilgili aksiyon alacak veya olası bir sorun olduğunda formda paylaştığınız iletişim bilgileri üzerinden geri dönüş yapacaktır.</p>" +
  "<p><br></p>" +
  "<p>Hesabınız silindiğinde, profiliniz diğer kullanıcılar tarafından görüntülenemez hale gelir ve o hesap altında yayınladığınız içerikler anonimleştirilir.</p>" +
  "<p><br></p>" +
  "<p>Hesabınız silindikten sonra, yasaların gerektirdiği şekilde veya kanunların meşru kıldığı amaçlar için sizinle ilgili belirli bilgileri saklamak zorunda kalabiliriz.</p>";

export const _invite_policy =
  "<p><strong>Davetiye Kullanım Şartları</strong></p>" +
  "<p>Davetiye Sistemi; Bizifest Freelance Platformu’na katkı sağlamak isteyen üyeleri, emekleri karşılığında ödüllendirmeyi amaçlayan bir sistemdir. Bu sistem ile üyelerin sisteme yeni kullanıcılar kazandırması amaçlanmaktadır. </p>" +
  "<p><br></p>" +
  "<p>Davetiye sistemini kullanan üyeler; </p>" +
  "<p><br></p>" +
  `<li>Davetiye kodlarını kendi yan ve ilişkili hesaplarında kullanmayacağını,</li></br>` +
  `<li>Multi hesap, sahte hesap ya da geçici hesaplar üzerinden davetiye kodu kullanmayacağını,</li></br>` +
  `<li>Başka bir kullanıcı adına davet kodu kullanımı yapmayacağını,</li></br>` +
  `<li>Davetiye sisteminin gerçekten Bizifest Freelance Platformu’na katkı sağlayacağını düşündüğü şekilde dağıtacağını,</li></br>` +
  `<li>Herhangi bir fiziki ya da dijital mecrada “karşılıklı çıkar” ilişkisi teminatı vererek davetiye kodu kullanımı yaptırmayacağını,</li></br>` +
  `<li>Davet edilen üyenin kayıt sırasında veya devamında e-posta doğrulaması yapması halinde Bizifest sisteminde “başarılı davet” olarak gözükeceğini, </li></br>` +
  `<li>Sistem tarafından yalnızca “başarılı davet”lerin ödüllendirileceğini, “doğrulama bekleyen üye” statüsündeki kayıtlar için ödüllendirme yapılmayacağını </li></br>` +
  "<p>kabul eder.</p>" +
  "<p><br></p>" +
  "<p>Bizifest Ekibi tarafından bütün davetiye kodları ve davetliler detaylı olarak incelenmektedir. Herhangi bir şekilde yukarıda bahsi geçen kuralları, forum genel kurallarını ve KVKK kurallarının ihlali halinde; ilgili davetiyeyi tamamen devre dışı bırakma, silme ya da statüsünü geçersiz hale getirme hakkını saklı tutar. Ayrıca devam eden kötüye kullanım tespiti halinde, Bizifest Ekibi tarafından ilgili üye sistemden geçici ya da kalıcı olarak uzaklaştırabilir. Davetiye sistemini herhangi bir şekilde kötüye kullanan ya da yanıltıcı davranan üyelerin bakiyeleri Bizifest sistemi tarafından durdurulabilir ya da sıfırlanabilir. Kazanilan bakiyeler Bizifest sisteminin uygun gördüğü şekilde hediye kuponu olarak tanımlanır.</p>" +
  "<p><br></p>" +
  "<p>Davetiye sistemi Bizifest içerisinde kaliteli ve yenilikçi bir topluluk oluşturmayı teşvik etmek amacıyla hayata geçirilmiştir. Üyelerin kendi çevrelerine ve ilgili topluluklarına, art niyet ve kural çiğnemek maksadı taşımadan, sistem yararına davetlerde bulunması teşvik edilmektedir.</p>" +
  "<p><br></p>" +
  "<p>Başarılı olarak nitelendirilen davetiyelerin ödüllendirme kriterleri Bizifest Freelance Platform ekibi tarafından saklanmaktadır. Platform tarafından ödüllendirme sistemindeki her türlü ödülü değiştirebilir, kaldırabilir ya da güncelleyebilir.</p>" +
  "<p><br></p>" +
  "<p>Davetiye sistemini kullanan üyelerin tamamı; yukarıda bahsedilen şartlara ve kurallara uymayı kabul eder.</p>";
