import { m } from "framer-motion";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { paths } from "../../routes/paths";
import { useRouter } from "../../routes/hook";
import { useAuthContext } from "../../auth/hooks";
import { varFade, varHover } from "../../components/animate";
import CustomPopover, { usePopover } from "../../components/custom-popover";
import { useTranslate } from "../../locales";
import Iconify from "../../components/iconify";
import React from "react";
import { fGetImageFromStore } from "../../utils/format-string";
import { USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM } from "../../utils/constants/profile-constants";
import { Player } from "@lottiefiles/react-lottie-player";
import { MenuList } from "@mui/material";
import SettingsThemeSwitchButton from "./settings-theme-switch-button";
import { useSettingsContext } from "../../components/settings";

const OPTIONS = [
  {
    label: "dashboard",
    linkTo: paths.dashboard.root,
  },
  {
    label: "profile",
  },
  {
    label: "myPosts",
  },
  {
    label: "myAccount",
    linkTo: paths.dashboard.user.account(
      USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.GENERAL,
    ),
  },
];

const OPTIONS_SUB = [
  {
    label: "normalPosts",
    linkTo: paths.dashboard.post.root,
  },
  {
    label: "tradePosts",
    linkTo: paths.dashboard.trade.root,
  },
];

export default function AccountPopover() {
  const { t } = useTranslate();
  const router = useRouter();
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const subPopover = usePopover();
  const settings = useSettingsContext();
  const { user } = useAuthContext();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace(paths.whatIsBizifest);
    } catch (error: any) {
      localStorage.removeItem("accessToken");
      window.location.href = paths.auth.jwt.login;
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const handleClickSubItem = (path: string) => {
    subPopover.onClose();
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          ml: 1,
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={user?.userName}
          src={user?.avatar ? fGetImageFromStore("thumbnail", user.avatar) : ""}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.userName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          <MenuList dense>
            {OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                onClick={(event) => {
                  if (option.label === "myPosts") {
                    subPopover.onOpen(event);
                  } else if (option.label === "profile") {
                    handleClickItem(
                      paths.dashboard.user.profile(user?.userName),
                    );
                  } else {
                    handleClickItem(
                      option.linkTo ? option.linkTo : paths.dashboard.root,
                    );
                  }
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  ...(option.label === "giveaway" && {
                    color:
                      theme.palette.mode === "dark" ? "orange" : "darkorange",
                    fontWeight: "bold",
                  }),
                }}
              >
                {t(option.label)}
                {option.label === "myPosts" && (
                  <Iconify
                    icon="material-symbols:arrow-forward-ios-rounded"
                    width={16}
                    height={16}
                  />
                )}
              </MenuItem>
            ))}

            <Divider sx={{ borderStyle: "dashed", marginY: 1 }} />

            <MenuItem
              onClick={(event) => {
                handleClickItem(
                  paths.dashboard.user.account(
                    USER_ACCOUNT_MAIN_TABS_LIST_ITEMS_ENUM.INVITE,
                  ),
                );
              }}
              sx={{
                display: "flex",
                width: "100%",
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.warning.light
                    : "orange",
                fontWeight: "bold",
              }}
            >
              <m.div variants={varFade().in}>
                <Player
                  src={
                    isLight
                      ? "/assets/lottie/invite_animation.json"
                      : "/assets/lottie/invite_animation_dark.json"
                  }
                  className="player"
                  loop
                  style={{ height: "24px", width: "24px", marginRight: "8px" }}
                  autoplay
                />
              </m.div>
              {t("invite")}
            </MenuItem>

            <MenuItem
              onClick={(event) => {
                handleClickItem(paths.dashboard.giveaway);
              }}
              sx={{
                display: "flex",
                width: "100%",
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.warning.light
                    : "orange",
                fontWeight: "bold",
              }}
            >
              <m.div variants={varFade().in}>
                <Player
                  src={
                    isLight
                      ? "/assets/lottie/giveaway.json"
                      : "/assets/lottie/giveaway_dark.json"
                  }
                  className="player"
                  loop
                  style={{ height: "24px", width: "24px", marginRight: "8px" }}
                  autoplay
                />
              </m.div>
              {t("giveaway")}
            </MenuItem>
          </MenuList>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() =>
            settings.onUpdate(
              "themeMode",
              settings.themeMode === "dark" ? "light" : "dark",
            )
          }
          sx={{
            mt: 0.5,
            mx: 1,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            px={1}
          >
            <SettingsThemeSwitchButton
              includeClickAction={false}
              iconSize={24}
            />
            {t("theme")}
          </Stack>
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}
        >
          {t("logout")}
        </MenuItem>
      </CustomPopover>

      <CustomPopover
        open={subPopover.open}
        onClose={subPopover.onClose}
        sx={{ width: 200, p: 0 }}
        arrow="right-center"
      >
        <Stack sx={{ p: 1 }}>
          {OPTIONS_SUB.map((optionSub) => (
            <MenuItem
              key={optionSub.label}
              onClick={() => {
                handleClickSubItem(optionSub.linkTo);
              }}
            >
              {t(optionSub.label)}
            </MenuItem>
          ))}
        </Stack>
      </CustomPopover>
    </>
  );
}
