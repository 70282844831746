import { paths } from "./routes/paths";

export const AUTH0_API = {
  clientId: "",
  domain: "",
  callbackUrl: "",
};
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || "";
export const OAUTH_TYPES = process.env.REACT_APP_OAUTH_TYPES || "google";
export const GOOGLE_ANALYTIC_KEY =
  process.env.REACT_APP_GOOGLE_ANALYTIC_KEY || "";
export const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN || "";
export const ROLE_MODERATOR = process.env.REACT_APP_ROLE_MODERATOR || "";
export const ROLE_USER = process.env.REACT_APP_ROLE_USER || "";

export const STORE_API =
  process.env.REACT_APP_VITE_STORE_API || "https://store.bizifest.com/";
export const ASSETS_API = process.env.REACT_APP_VITE_ASSETS_API || "";
export const PROFILE = process.env.REACT_APP_PROFILE || "";

export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const PATH_AFTER_SIGN_UP = paths.auth.jwt.verifyMail; // as '/dashboard'
