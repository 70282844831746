import Button from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material/styles";
import { useTranslate } from "../../locales";
import { paths } from "../../routes/paths";
import Iconify from "../../components/iconify";
import React from "react";
import { useResponsive } from "../../hooks/use-responsive";
import IconButton from "@mui/material/IconButton";
import { logEvent } from "../../analytics";
import { GOOGLE_ANALYTIC_EVENT_NAME } from "../../utils/constants/constants";
import { useRouter } from "../../routes/hook";
import { useLocation } from "react-router-dom";

type Props = {
  sx?: SxProps<Theme>;
};

export default function LoginButton({ sx }: Props) {
  const { t } = useTranslate();
  const mdUp = useResponsive("up", "md");
  const router = useRouter();
  const location = useLocation();

  const handleLogin = () => {
    router.push(paths.auth.jwt.login);
    logEvent(GOOGLE_ANALYTIC_EVENT_NAME.LOGIN, {
      click_location: location.pathname,
    });
  };

  return (
    <>
      {mdUp ? (
        <Button
          onClick={() => handleLogin()}
          variant="outlined"
          sx={{ mr: 1, ...sx }}
        >
          {t("login")}
        </Button>
      ) : (
        <IconButton
          color="inherit"
          size="large"
          onClick={() => handleLogin()}
          sx={{ borderColor: "text.primary" }}
        >
          <Iconify icon="material-symbols:login" width={24} />
        </IconButton>
      )}
    </>
  );
}
