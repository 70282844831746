import useSWR from "swr";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios, { endpoints, fetcher } from "../utils/axios";
import { IUserProfileFollower } from "../types/user";
import { useTranslate } from "../locales";
import { useSnackbar } from "notistack";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetAllFollowInfoByUserId(
  userId: string,
  isFollowers: boolean,
) {
  const URL = isFollowers
    ? `${endpoints.follower.getAllByUserId}${userId}`
    : `${endpoints.follower.getAllByFollowedUserId}${userId}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  return useMemo(
    () => ({
      followers: (data as IUserProfileFollower[]) || [],
      followersLoading: isLoading,
      followersError: error,
      followersValidating: isValidating,
      followersEmpty: !isLoading && data?.length <= 0,
    }),
    [data, error, isLoading, isValidating],
  );
}

export function useGetFollowedByUserId(userId: string, authenticated: boolean) {
  if (!authenticated) {
    return {
      follower: false,
      followerLoading: false,
      followerError: false,
      followerValidating: false,
    };
  }
  const URL = `${endpoints.follower.getByUserId}${userId}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  return useMemo(
    () => ({
      follower: data || false,
      followerLoading: isLoading,
      followerError: error,
      followerValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
}

export const useFollowAction = (userId: string) => {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  const [followed, setFollowed] = useState(false);
  const { follower, followerLoading } = useGetFollowedByUserId(userId, true);

  useEffect(() => {
    setFollowed(follower);
  }, [follower]);

  const handleFollowedClick = useCallback(async () => {
    try {
      if (followed) {
        await axios.delete(`${endpoints.follower.delete}${userId}`);
        enqueueSnackbar(t("unfollowSnackbar"), { variant: "success" });
      } else {
        await axios.post(endpoints.follower.create, { userId: userId });
        enqueueSnackbar(t("followSnackbar"), { variant: "success" });
      }
      setFollowed(!followed);
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      enqueueSnackbar(errMsg, { variant: "error" });
    }
  }, [followed, userId, enqueueSnackbar, t]);

  return {
    followed,
    followerLoading,
    handleFollowedClick,
  };
};
