import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import axios, { endpoints } from "../../../utils/axios";
//
import { AuthContext } from "./auth-context";
import { isValidToken, setSession } from "./utils";
import { ActionMapType, AuthStateType, AuthUserType } from "../../types";
import { useSWRConfig } from "swr";
import { useSnackbar } from "../../../components/snackbar";
import { useLocation } from "react-router-dom";
import { ErrPleaseValidateYourAccount } from "../../../utils/constants/constants";
import { PATH_AFTER_LOGIN, PATH_AFTER_SIGN_UP } from "../../../config";
import { useRouter, useSearchParams } from "../../../routes/hook";
import { paths } from "../../../routes/paths";

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

enum Types {
  INITIAL = "INITIAL",
  UPDATE = "UPDATE",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.UPDATE]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.VALIDATE_ACCOUNT]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.UPDATE) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.VALIDATE_ACCOUNT) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const STORAGE_KEY = "accessToken";

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");

  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);

  const { pathname } = location;

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        const res = await axios.get(endpoints.auth.me);

        const user = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (pathname != paths.maintenance) {
      initialize();
    }
  }, [initialize, pathname]);

  // LOGIN
  const login = useCallback(
    async (emailOrUserName: string, password: string) => {
      const data = {
        emailOrUserName,
        password,
      };

      const res = await axios.post(endpoints.auth.login, data, {
        timeout: 20000,
      });
      if (res && res.data === ErrPleaseValidateYourAccount) {
        sessionStorage.setItem("email-recovery", emailOrUserName);
        router.push(PATH_AFTER_SIGN_UP);
        return;
      }
      const { token, user } = res.data;
      setSession(token);
      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
      router.push(returnTo || PATH_AFTER_LOGIN);
    },
    [],
  );
  // LOGIN
  const oauthLogin = useCallback(async (user: AuthUserType, token: string) => {
    setSession(token);
    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
  }, []);

  const closeAuthDialog = useCallback(() => {
    setIsAuthDialogOpen(false);
  }, []);

  const openAuthDialog = useCallback(() => {
    setIsAuthDialogOpen(true);
  }, []);

  // UPDATE AVATAR/COVER
  const updateAvatarAndCover = useCallback(
    async (bucket: string, formData: FormData) => {
      await axios.post(`/user/upload-${bucket}`, formData, {
        timeout: 20000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const res = await axios.get(endpoints.auth.me);
      const user = res.data;
      dispatch({
        type: Types.UPDATE,
        payload: {
          user,
        },
      });
    },
    [],
  );

  // REGISTER
  const validateAccount = useCallback(async (email: string, code: string) => {
    const data = {
      email,
      code,
    };
    const res = await axios.post(endpoints.auth.validateAccount, data);

    const { token, user } = res.data;

    localStorage.setItem(STORAGE_KEY, token);

    dispatch({
      type: Types.VALIDATE_ACCOUNT,
      payload: {
        user,
      },
    });
  }, []);

  // UPDATE
  const update = useCallback(async (data: any) => {
    const res = await axios.put(endpoints.auth.update, data);

    const { user } = res.data;

    dispatch({
      type: Types.UPDATE,
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await axios.get(endpoints.auth.logout);
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
    await clearCache();
  }, []);

  const clearCache = () => mutate(() => true, undefined, { revalidate: false });
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";
  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "jwt",
      loading: pathname != paths.maintenance && status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      oauthLogin,
      validateAccount,
      updateAvatarAndCover,
      logout,
      update,
      isAuthDialogOpen,
      openAuthDialog,
      closeAuthDialog,
    }),
    [
      login,
      logout,
      oauthLogin,
      validateAccount,
      updateAvatarAndCover,
      update,
      state.user,
      status,
      isAuthDialogOpen,
      openAuthDialog,
      closeAuthDialog,
    ],
  );

  // @ts-ignore
  return pathname == paths.maintenance ? (
    <>{children}</>
  ) : (
    // Eğer engellenen yoldaysa, children'ı doğrudan render etme veya başka bir şey yapabilirsiniz
    // @ts-ignore
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
