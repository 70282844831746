import { IChatConversation } from "../../../types/chat";
import { useTranslate } from "../../../locales";

type Props = {
  currentUserId: string;
  conversation: IChatConversation;
};

export default function useGetNavItem({ currentUserId, conversation }: Props) {
  const { messages, participants } = conversation;
  const { t } = useTranslate();
  const participantsInConversation = participants.filter(
    (participant) => participant.id !== currentUserId,
  );

  const lastMessage = messages[messages.length - 1];

  const group = participantsInConversation.length > 1;

  const displayName = participantsInConversation
    .map((participant) => participant.name)
    .join(", ");

  const hasOnlineInGroup = group
    ? participantsInConversation.map((item) => item.status).includes("online")
    : false;

  let displayText = "";

  if (lastMessage) {
    const sender = lastMessage.senderId === currentUserId ? "Sen: " : "";
    const message =
      lastMessage.contentType === "image" ? t("sentPhoto") : lastMessage.body;

    displayText = `${sender}${message}`;
  }

  return {
    group,
    displayName,
    displayText,
    participants: participantsInConversation,
    lastActivity: lastMessage.createdAt,
    hasOnlineInGroup,
  };
}
