import axios, { endpoints, fetcher } from "../utils/axios";
import useSWR, { mutate } from "swr";
import { useMemo } from "react";
import { INotificationItem } from "../types/member-notification";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const URL = endpoints.memberNotification.getByUserId;

export function useGetAllNotificationByUserId() {
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    options,
  );

  return useMemo(
    () => ({
      notifications: (data?.notificationList as INotificationItem[]) || [],
      notificationsLoading: isLoading,
      notificationsError: error,
      notificationsValidating: isValidating,
      notificationsEmpty: !isLoading && data?.notificationList.length <= 0,
      totalUnRead: data?.notificationList.filter(
        (item: INotificationItem) => !item.view,
      ).length,
    }),
    [data?.notificationList, error, isLoading, isValidating],
  );
}

export async function markAsReadAll() {
  await axios.post(endpoints.memberNotification.markAsAllRead);

  await mutate(
    URL,
    (currentData: any) => {
      currentData.notificationList.map((notification: INotificationItem) => ({
        ...notification,
        view: false,
      }));
    },
    false,
  );
}
