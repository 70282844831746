import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { alpha, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { paths } from "../../routes/paths";
import { RouterLink } from "../../routes/components";

import { _socials } from "../../_mock";

import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import { useTranslate } from "../../locales";
import { usePathname } from "../../routes/hook";
import { FOOTER_BTK_CERTIFICATE_URL } from "../../utils/constants/constants";

const LINKS = [
  {
    headline: "Bizifest",
    children: [
      { name: "Bizifest Nedir", href: paths.whatIsBizifest },
      { name: "Yenilikler", href: paths.changelog },
      { name: "Rozetler", href: paths.dashboard.badges },
      { name: "Ticaret Sistemi", href: paths.tradeSystem },
      { name: "Sıkça Sorulan Sorular", href: paths.faqs },
      { name: "Blog", href: paths.dashboard.blog.root },
    ],
  },
  {
    headline: "yasal",
    children: [
      { name: "Forum Kuralları", href: paths.forumRules("forum-kurallari") },
      {
        name: "Üyelik Sözleşmesi",
        href: paths.forumRules("uyelik-sozlesmesi"),
      },
      {
        name: "KVKK ve Gizlilik Politikası",
        href: paths.forumRules("kvkk-gizlilik-politikasi"),
      },
    ],
  },
  {
    headline: "support",
    children: [
      {
        name: "İletişim",
        href: paths.contact,
      },
      { name: "destek@bizifest.com", href: "mailto:destek@bizifest.com" },
    ],
  },
];

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === paths.whatIsBizifest;
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const { t } = useTranslate();

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: "center",
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: "auto" }} />

        <Typography variant="caption" component="div">
          {t("allRightsReserved")}
          <br /> {t("bizifestFreelancePlatform")}
          <Link
            href="https://codemakerz.com/"
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
          >
            {" "}
            {t("codeMakerzYazılım")}{" "}
          </Link>
          {t("isService")}.
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        pb: 2,
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      {!isHome && (
        <Divider
          sx={{
            borderStyle: "dashed",
            borderWidth: "2px",
            marginTop: "8px",
          }}
        />
      )}

      <Container
        sx={{
          pt: { xs: 4, md: 8 },
          pb: 1,
          textAlign: { xs: "center", md: "unset" },
        }}
      >
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
        >
          <Grid xs={12} md={5} item>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{ mb: 3 }}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <Logo />
              <Typography
                variant="h3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Bizifest
              </Typography>
            </Stack>

            <Typography
              variant="body2"
              sx={{
                maxWidth: 300,
                mx: { xs: "auto", md: "unset" },
                color: theme.palette.text.secondary,
              }}
            >
              {t("bizifestFooterDesc")}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    "&:hover": {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Link href={social.path} target="_blank" rel="noopener">
                    <Iconify color={social.color} icon={social.icon}></Iconify>
                  </Link>
                </IconButton>
              ))}
            </Stack>
            <Box
              marginTop={{ xs: 0, md: 2 }}
              marginBottom={{ xs: 4, md: 1 }}
              paddingX={1}
            >
              <Link
                href={FOOTER_BTK_CERTIFICATE_URL}
                color={"text.primary"}
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  variant="caption"
                  sx={{
                    maxWidth: 300,
                    mx: { xs: "auto", md: "unset" },
                    fontStyle: "italic",
                  }}
                >
                  {t("btkLegalWarning")}
                </Typography>
              </Link>
            </Box>
          </Grid>

          <Grid xs={12} md={7} item>
            <Stack spacing={5} direction={{ xs: "column", md: "row" }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: "center", md: "flex-start" }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {t(list.headline)}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="body2"
          component="div"
          sx={{ mt: 8 }}
          textAlign={"center"}
        >
          {t("allRightsReserved")}
          <br /> {t("bizifestFreelancePlatform")}
          <Link
            href="https://codemakerz.com/"
            target="_blank"
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
          >
            {" "}
            {t("codeMakerzYazılım")}{" "}
          </Link>
          {t("isService")}.
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? simpleFooter : mainFooter;
}
