import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box, { BoxProps } from "@mui/material/Box";
import { paths } from "../../routes/paths";
import { useAuthContext } from "../../auth/hooks";
import { RouterLink } from "../../routes/components";

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoBizifestText = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === "light";
    const { user } = useAuthContext();

    const logoForDark = (
      <Box sx={{ width: 110, height: "80%", ...sx }}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 964 226"
        >
          <defs>
            <style>{".cls-1{fill:#fff;}"}</style>
          </defs>
          <path
            className="cls-1"
            d="M145.85,115a2,2,0,0,0,.79,3.19c19.87,8.45,30,27.08,30,51.05,0,45.59-36.71,61.7-78.92,61.7H15.32a2.07,2.07,0,0,1-2.07-2.07V23.28a2.07,2.07,0,0,1,2.07-2.07H94.24c35.68,0,65.2,20.22,65.2,55.87a59.17,59.17,0,0,1-13.59,38M89.43,58.91H64.05A2.07,2.07,0,0,0,62,61V99a2.07,2.07,0,0,0,2.07,2.07H87.72c14.41,0,25.73-5.48,25.73-20.91,0-15.08-11-21.25-24-21.25m12,82.26H64.05A2.07,2.07,0,0,0,62,143.24v47.27a2.07,2.07,0,0,0,2.07,2.07h37.39c13.73,0,24.71-12,24.71-26.39,0-13.71-10.29-25-24.71-25"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M225.81,53.81c-12.75,0-24-10-24-23.06S213.06,8,225.81,8s24,9.71,24,22.75-11.24,23.06-24,23.06M246.16,231h-41V72.31h41Z"
            transform="translate(-13.25 -8)"
          />
          <polygon
            className="cls-1"
            points="381.44 98.89 311.88 187.17 382.96 187.17 382.96 222.97 262.98 222.97 262.98 187.17 333.15 99.2 268.75 99.2 268.75 64.61 381.44 64.61 381.44 98.89"
          />
          <path
            className="cls-1"
            d="M446.63,53.81c-12.76,0-24-10-24-23.06S433.87,8,446.63,8s24,9.71,24,22.75-11.24,23.06-24,23.06M467,231H426V72.31h41Z"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M590.9,23.77l-12.76,26.7A34.36,34.36,0,0,0,563,47.13c-15.8,0-26.12,9.71-26.12,25.18h44.95V107.8H536.53V231H496.74V72.31c0-32.15,23.09-59.46,56.19-59.46,11.55,0,28.55,3,38,10.92"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M728.49,142.39c0,3.33,0,10.92-.3,18.2H633.12c0,21.84,15.79,37,37.06,37,15.18,0,32.19-7.28,41.3-19.11L727,209.43C715.13,226.11,689.31,234,668.05,234c-45.56,0-77.15-37-77.15-84.33,0-42.17,25.21-80.39,71.68-80.39,40.7,0,65.91,29.73,65.91,73.11m-93.55-12.14h55.59c0-15.77-12.15-27.3-27-27.3-15.49,0-28.55,12.13-28.55,27.3"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M801.39,234c-17.31,0-43.13-5.16-58.92-15.77l16.09-35.8c9.72,7.28,24.61,13.65,36.15,13.65,9.41,0,20.35-4.25,20.35-13.65,0-22.14-61.36-12.44-61.36-63.4C753.7,85.35,780.43,69,810.81,69c15.49,0,40.7,4.25,51,16.69l-12.45,28.82c-7.29-6.07-20.35-9.1-28.85-9.1s-20.66,2.73-20.66,12.74c0,22.75,63.79,14.86,63.79,64,0,33.67-29.47,51.87-62.27,51.87"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M892.51,179.09V32.27H932.6v40h44.65V107.8H932.6v57c0,11.23-1.52,31.25,13.06,31.25,5.47,0,10.94-2.73,14.28-5.46l13.66,30.94C962.37,229.45,953,233.7,939,233.7c-32.8,0-46.47-24.27-46.47-54.61"
            transform="translate(-13.25 -8)"
          />
        </svg>
      </Box>
    );

    const logoForLight = (
      <Box sx={{ width: 110, height: "80%", ...sx }}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 964 226"
        >
          <defs>
            <style>{".cls-1{fill:#23272a;}"}</style>
          </defs>
          <title>{"bizifest-text_dark"}</title>
          <path
            className="cls-1"
            d="M145.85,115a2,2,0,0,0,.79,3.19c19.87,8.45,30,27.08,30,51.05,0,45.59-36.71,61.7-78.92,61.7H15.32a2.07,2.07,0,0,1-2.07-2.07V23.28a2.07,2.07,0,0,1,2.07-2.07H94.24c35.68,0,65.2,20.22,65.2,55.87a59.17,59.17,0,0,1-13.59,38M89.43,58.91H64.05A2.07,2.07,0,0,0,62,61V99a2.07,2.07,0,0,0,2.07,2.07H87.72c14.41,0,25.73-5.48,25.73-20.91,0-15.08-11-21.25-24-21.25m12,82.26H64.05A2.07,2.07,0,0,0,62,143.24v47.27a2.07,2.07,0,0,0,2.07,2.07h37.39c13.73,0,24.71-12,24.71-26.39,0-13.71-10.29-25-24.71-25"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M225.81,53.81c-12.75,0-24-10-24-23.06S213.06,8,225.81,8s24,9.71,24,22.75-11.24,23.06-24,23.06M246.16,231h-41V72.31h41Z"
            transform="translate(-13.25 -8)"
          />
          <polygon
            className="cls-1"
            points="381.44 98.89 311.88 187.17 382.96 187.17 382.96 222.97 262.98 222.97 262.98 187.17 333.15 99.2 268.75 99.2 268.75 64.61 381.44 64.61 381.44 98.89"
          />
          <path
            className="cls-1"
            d="M446.63,53.81c-12.76,0-24-10-24-23.06S433.87,8,446.63,8s24,9.71,24,22.75-11.24,23.06-24,23.06M467,231H426V72.31h41Z"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M590.9,23.77l-12.76,26.7A34.36,34.36,0,0,0,563,47.13c-15.8,0-26.12,9.71-26.12,25.18h44.95V107.8H536.53V231H496.74V72.31c0-32.15,23.09-59.46,56.19-59.46,11.55,0,28.55,3,38,10.92"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M728.49,142.39c0,3.33,0,10.92-.3,18.2H633.12c0,21.84,15.79,37,37.06,37,15.18,0,32.19-7.28,41.3-19.11L727,209.43C715.13,226.11,689.31,234,668.05,234c-45.56,0-77.15-37-77.15-84.33,0-42.17,25.21-80.39,71.68-80.39,40.7,0,65.91,29.73,65.91,73.11m-93.55-12.14h55.59c0-15.77-12.15-27.3-27-27.3-15.49,0-28.55,12.13-28.55,27.3"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M801.39,234c-17.31,0-43.13-5.16-58.92-15.77l16.09-35.8c9.72,7.28,24.61,13.65,36.15,13.65,9.41,0,20.35-4.25,20.35-13.65,0-22.14-61.36-12.44-61.36-63.4C753.7,85.35,780.43,69,810.81,69c15.49,0,40.7,4.25,51,16.69l-12.45,28.82c-7.29-6.07-20.35-9.1-28.85-9.1s-20.66,2.73-20.66,12.74c0,22.75,63.79,14.86,63.79,64,0,33.67-29.47,51.87-62.27,51.87"
            transform="translate(-13.25 -8)"
          />
          <path
            className="cls-1"
            d="M892.51,179.09V32.27H932.6v40h44.65V107.8H932.6v57c0,11.23-1.52,31.25,13.06,31.25,5.47,0,10.94-2.73,14.28-5.46l13.66,30.94C962.37,229.45,953,233.7,939,233.7c-32.8,0-46.47-24.27-46.47-54.61"
            transform="translate(-13.25 -8)"
          />
        </svg>
      </Box>
    );

    return (
      <Link
        component={RouterLink}
        href={paths.dashboard.root}
        sx={{ display: "contents" }}
      >
        {isLight ? logoForLight : logoForDark}
      </Link>
    );
  },
);

export default LogoBizifestText;
