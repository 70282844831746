import * as Yup from "yup";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { useBoolean } from "../../../hooks/use-boolean";
import { paths } from "../../../routes/paths";
import { RouterLink } from "../../../routes/components";
import { useRouter } from "../../../routes/hook";
import { HOST_API, OAUTH_TYPES, PATH_AFTER_SIGN_UP } from "../../../config";
import Iconify from "../../../components/iconify";
import FormProvider, {
  RHFCheckbox,
  RHFCode,
  RHFSwitch,
  RHFTextField,
} from "../../../components/hook-form";
import { useTranslate } from "../../../locales";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  ErrPleaseValidateYourAccount,
  GOOGLE_ANALYTIC_EVENT_NAME,
  JWT_DIALOG_VIEW_FORM_NAMES,
  PASSWORD_REGEX,
  USER_EMAIL_MAX_CHARACTERS,
  USER_FIRST_NAME_MAX_CHARACTERS,
  USER_FIRST_NAME_MIN_CHARACTERS,
  USER_LAST_NAME_MAX_CHARACTERS,
  USER_LAST_NAME_MIN_CHARACTERS,
  USER_NAME_ONLY_CHARACTERS,
  USER_PASSWORD_MAX_CHARACTERS,
  USER_PASSWORD_MIN_CHARACTERS,
  USERNAME_CHARACTER_REGEX,
  USERNAME_MAX_CHARACTERS,
  USERNAME_MIN_CHARACTERS,
  USERNAME_WITHOUT_SPACE_REGEX,
} from "../../../utils/constants/constants";
import axios, { endpoints } from "../../../utils/axios";
import { JwtDialogViewProps } from "../../../types/user";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../../auth/hooks";
import { logEvent } from "../../../analytics";

const oauthTypes = OAUTH_TYPES.split(",");

type Props = JwtDialogViewProps & {
  code?: string;
};
export default function JwtRegisterView({ handleFormChange, code }: Props) {
  const { t } = useTranslate();
  const { isAuthDialogOpen } = useAuthContext();
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState("");
  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    invitationCode: Yup.string(),
    userName: Yup.string()
      .required(t("user_name_is_required"))
      .matches(USERNAME_WITHOUT_SPACE_REGEX, t("userNotWhitespaceCharacter"))
      .matches(USERNAME_CHARACTER_REGEX, t("userNotSpecialCharacter"))
      .min(USERNAME_MIN_CHARACTERS, t("userNameMinCharacter"))
      .max(USERNAME_MAX_CHARACTERS, t("userNameMaxCharacter")),
    firstName: Yup.string()
      .required(t("first_name_is_required"))
      .matches(USER_NAME_ONLY_CHARACTERS, t("userNameOnlyMaxCharacter"))
      .min(USER_FIRST_NAME_MIN_CHARACTERS, t("userFirstNameMinCharacter"))
      .max(USER_FIRST_NAME_MAX_CHARACTERS, t("userFirstNameMaxCharacter")),
    lastName: Yup.string()
      .required(t("last_name_is_required"))
      .matches(USER_NAME_ONLY_CHARACTERS, t("userNameOnlyMaxCharacter"))
      .min(USER_LAST_NAME_MIN_CHARACTERS, t("userLastNameMinCharacter"))
      .max(USER_LAST_NAME_MAX_CHARACTERS, t("userLastNameMaxCharacter")),
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("email_must_be_valid"))
      .max(USER_EMAIL_MAX_CHARACTERS, t("userMailMaxCharacter")),
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(PASSWORD_REGEX, t("passwordHelperText"))
      .min(USER_PASSWORD_MIN_CHARACTERS, t("userPasswordMinCharacter"))
      .max(USER_PASSWORD_MAX_CHARACTERS, t("userPasswordMaxCharacter")),
    invitationCodeExists: Yup.boolean().notRequired(),
    policyCheckbox: Yup.boolean()
      .required(t("requiredMembershipAgreement"))
      .oneOf([true], t("requiredMembershipAgreement")),
  });

  const defaultValues = {
    userName: "",
    invitationCode: code ? code : "",
    invitationCodeExists: code ? true : false,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    policyCheckbox: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const createData = {
        userName: data.userName,
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        invitationCode: data.invitationCode ? data.invitationCode : "",
      };
      const res = await axios.post(endpoints.auth.register, createData);
      if (res && res.data === ErrPleaseValidateYourAccount) {
        sessionStorage.setItem("email-recovery", data.email);
        {
          handleFormChange
            ? handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.VERIFY_EMAIL)
            : router.push(PATH_AFTER_SIGN_UP);
        }
      }
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.SIGNUP, { method: "email" });
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      reset();
      enqueueSnackbar(errMsg, { variant: "error" });
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.SIGNUP, {
        method: "email",
        error: "error = " + errMsg,
      });
    }
  });

  const handleLogin = async (provider: string) => {
    try {
      window.location.href = HOST_API + "auth/auth/" + provider;
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.SIGNUP, { method: provider });
    } catch (error) {
      console.error("Failed to initiate OAUTH login:", error);
      logEvent(GOOGLE_ANALYTIC_EVENT_NAME.SIGNUP, {
        method: provider,
        error: "error = " + error,
      });
    }
  };

  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  const renderHead = (
    <Stack
      spacing={2}
      sx={{
        mb: isAuthDialogOpen ? 1 : 5,
        position: "relative",
      }}
    >
      <Typography variant="h4">{t("registerBizifestNow")}</Typography>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography variant="body2">{t("already_have_an_account")} </Typography>

        {handleFormChange ? (
          <Button
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
            onClick={() => handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.LOGIN)}
          >
            {" "}
            {t("sign_in")}{" "}
          </Button>
        ) : (
          <Link
            href={paths.auth.jwt.login}
            component={RouterLink}
            variant="subtitle2"
            sx={{
              color: isLight
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            }}
          >
            {t("sign_in")}
          </Link>
        )}
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Stack direction={"row"}>
      <Typography
        component="div"
        sx={{
          color: "text.secondary",
          typography: "caption",
        }}
      >
        {t("by_signing_up_i_agree_to")}
        <Link
          href={paths.forumRules("uyelik-sozlesmesi")}
          underline="always"
          color="text.primary"
        >
          {t("membershipAgreement")}
        </Link>
        {t("and")}
        <Link
          href={paths.forumRules("kvkk-gizlilik-politikasi")}
          underline="always"
          color="text.primary"
        >
          {t("privacy_policy")}'nı
        </Link>
        {t("i_agree_to")}
      </Typography>
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        <RHFTextField name="userName" label={t("user_name")} />

        <RHFSwitch
          disabled={!!code}
          name="invitationCodeExists"
          label={t("doYouHaveInvitationCode")}
        />
        {values.invitationCodeExists && <RHFCode name="invitationCode" />}

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField name="firstName" label={t("first_name")} />
          <RHFTextField name="lastName" label={t("last_name")} />
        </Stack>

        <RHFTextField name="email" label={t("email_address")} />

        <RHFTextField
          name="password"
          label={t("password")}
          helperText={t("passwordHelperText")}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify
                    icon={
                      password.value
                        ? "solar:eye-bold"
                        : "solar:eye-closed-bold"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFCheckbox
          key="policyCheckbox"
          name="policyCheckbox"
          label={renderTerms}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t("create_account")}
        </LoadingButton>

        {oauthTypes.map((provider, index) => (
          <Button
            key={`${provider}-${index}`}
            fullWidth
            color="inherit"
            size="medium"
            variant="outlined"
            startIcon={
              index === 0 ? (
                <Iconify icon="flat-color-icons:google" />
              ) : undefined
            }
            onClick={() => handleLogin(provider)}
          >
            {`${provider.charAt(0).toUpperCase() + provider.slice(1)} ile Üye Ol`}
          </Button>
        ))}
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}
      {renderForm}
    </>
  );
}
