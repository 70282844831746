import Box from "@mui/material/Box";

import { useBoolean } from "../../hooks/use-boolean";
import { useResponsive } from "../../hooks/use-responsive";

import { useSettingsContext } from "../../components/settings";

import Main from "./main";
import NavMini from "./nav-mini";
import NavVertical from "./nav-vertical";
import NavHorizontal from "./nav-horizontal";
import { useAuthContext } from "../../auth/hooks";
import { fUserRoleAdminOrModerator } from "../../utils/authorization";
import HeaderWithMargin from "./header-with-margin";
import Footer from "../main/footer";
import Header from "../main/header";

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();
  const { user } = useAuthContext();

  const lgUp = useResponsive("up", "lg");

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === "horizontal";

  const isMini = settings.themeLayout === "mini";

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = (
    <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  if (isHorizontal) {
    return (
      <>
        {user ? <HeaderWithMargin onOpenNav={nav.onTrue} /> : <Header />}

        {fUserRoleAdminOrModerator(user) &&
          (lgUp ? renderHorizontal : renderNavVertical)}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        {user ? <HeaderWithMargin onOpenNav={nav.onTrue} /> : <Header />}

        <Box
          sx={{
            minHeight: 1,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          {fUserRoleAdminOrModerator(user) &&
            (lgUp ? renderNavMini : renderNavVertical)}

          <Main>{children}</Main>
        </Box>

        <Footer />
      </>
    );
  }

  return (
    <>
      {user ? <HeaderWithMargin onOpenNav={nav.onTrue} /> : <Header />}

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        {fUserRoleAdminOrModerator(user) && renderNavVertical}

        <Main>{children}</Main>
      </Box>
      <Footer />
    </>
  );
}
