import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Badge, { badgeClasses } from "@mui/material/Badge";

import { paths } from "../../routes/paths";

import { useOffSetTop } from "../../hooks/use-off-set-top";
import { useResponsive } from "../../hooks/use-responsive";

import { bgBlur } from "../../theme/css";

import Logo from "../../components/logo";
import Label from "../../components/label";

import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";
import { HEADER } from "../config-layout";
import { navConfig } from "./config-navigation";
import LoginButton from "../common/login-button";
import HeaderShadow from "../common/header-shadow";
import { useTranslate } from "../../locales";
import LogoBizifestText from "../../components/logo/logo-bizifest-text";
import { useRouter } from "../../routes/hook";
import { logEvent } from "../../analytics";
import { GOOGLE_ANALYTIC_EVENT_NAME } from "../../utils/constants/constants";
import { useLocation } from "react-router-dom";

export default function Header() {
  const theme = useTheme();
  const { t } = useTranslate();
  const mdUp = useResponsive("up", "md");
  const lgUp = useResponsive("up", "lg");
  const router = useRouter();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const location = useLocation();

  const handleSignUp = () => {
    router.push(paths.auth.jwt.register);
    logEvent(GOOGLE_ANALYTIC_EVENT_NAME.SIGNUP, {
      click_location: location.pathname,
    });
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            sx={{ display: "flex" }}
          >
            {lgUp && <Logo />}

            <Badge
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  top: 8,
                  right: -16,
                },
              }}
              badgeContent={
                <Link
                  href={paths.changelog}
                  rel="noopener"
                  underline="none"
                  sx={{ ml: 5 }}
                >
                  <Label
                    color="primary"
                    sx={{ textTransform: "unset", height: 22, px: 0.5 }}
                  >
                    {t("versionNumber")}
                  </Label>
                </Link>
              }
            >
              {lgUp ? <LogoBizifestText sx={{ mt: 0.5 }} /> : <Logo />}
            </Badge>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack
            alignItems="center"
            direction={{ xs: "row", md: "row-reverse" }}
            spacing={2}
          >
            <Stack
              alignItems="center"
              direction={{ xs: "row-reverse", md: "row-reverse" }}
              spacing={2}
            >
              <LoginButton />

              <Button variant="contained" onClick={() => handleSignUp()}>
                {t("signup")}
              </Button>
            </Stack>

            {mdUp && (
              <Box
                sx={{
                  ml: { xs: 1, md: 0 },
                  mr: { md: 2 },
                }}
              ></Box>
            )}

            {!mdUp && <NavMobile data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
