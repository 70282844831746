import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";

import { paths } from "../../routes/paths";
import { RouterLink } from "../../routes/components";

import { useOffSetTop } from "../../hooks/use-off-set-top";

import { bgBlur } from "../../theme/css";

import Logo from "../../components/logo";

import { HEADER } from "../config-layout";
import HeaderShadow from "./header-shadow";
import SettingsButton from "./settings-button";
import { useTranslate } from "../../locales";
import Container from "@mui/material/Container";

export default function HeaderSimple() {
  const theme = useTheme();
  const { t } = useTranslate();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: "space-between",
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container maxWidth={"xl"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Logo />

            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <SettingsButton/>*/}

              <Link
                href={paths.faqs}
                component={RouterLink}
                color="inherit"
                sx={{ typography: "subtitle2" }}
              >
                {t("needHelp")}
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
