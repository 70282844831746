import React, { useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
// config
import { useTranslate } from "../../../locales";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { JwtLoginView } from "./index";
import JwtRegisterView from "./jwt-register-view";
import ModernForgotPasswordView from "./modern-forgot-password-view";
import ModernVerifyNewUserView from "./modern-verify-new-user-view";
import { useAuthContext } from "../../../auth/hooks";
import { JWT_DIALOG_VIEW_FORM_NAMES } from "../../../utils/constants/constants";

export default function JwtDialogView() {
  const [currentForm, setCurrentForm] = useState<
    | JWT_DIALOG_VIEW_FORM_NAMES.LOGIN
    | JWT_DIALOG_VIEW_FORM_NAMES.REGISTER
    | JWT_DIALOG_VIEW_FORM_NAMES.FORGOT_PASSWORD
    | JWT_DIALOG_VIEW_FORM_NAMES.VERIFY_EMAIL
  >(JWT_DIALOG_VIEW_FORM_NAMES.REGISTER);
  const { closeAuthDialog, isAuthDialogOpen } = useAuthContext();
  const { t } = useTranslate();

  const onCancel = useCallback(() => {
    closeAuthDialog && closeAuthDialog();
  }, [closeAuthDialog]);

  const handleFormChange = (
    formName:
      | JWT_DIALOG_VIEW_FORM_NAMES.LOGIN
      | JWT_DIALOG_VIEW_FORM_NAMES.REGISTER
      | JWT_DIALOG_VIEW_FORM_NAMES.FORGOT_PASSWORD
      | JWT_DIALOG_VIEW_FORM_NAMES.VERIFY_EMAIL,
  ) => {
    setCurrentForm(formName);
  };

  const renderDialog = (
    <>
      {isAuthDialogOpen && closeAuthDialog && (
        <Dialog
          open={isAuthDialogOpen}
          onClose={closeAuthDialog}
          fullWidth
          maxWidth="sm"
        >
          <Stack spacing={3} paddingTop={5} paddingX={5} paddingBottom={3}>
            {currentForm === JWT_DIALOG_VIEW_FORM_NAMES.LOGIN && (
              <JwtLoginView handleFormChange={handleFormChange} />
            )}
            {currentForm === JWT_DIALOG_VIEW_FORM_NAMES.REGISTER && (
              <JwtRegisterView handleFormChange={handleFormChange} />
            )}
            {currentForm === JWT_DIALOG_VIEW_FORM_NAMES.FORGOT_PASSWORD && (
              <ModernForgotPasswordView handleFormChange={handleFormChange} />
            )}
            {currentForm === JWT_DIALOG_VIEW_FORM_NAMES.VERIFY_EMAIL && (
              <ModernVerifyNewUserView handleFormChange={handleFormChange} />
            )}
            <Button color="inherit" variant="text" onClick={onCancel}>
              {t("cancel")}
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );

  return <>{renderDialog}</>;
}
