import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";

import { useTranslate } from "../../locales";
import { varFade } from "../../components/animate";
import { Player } from "@lottiefiles/react-lottie-player";
import { m } from "framer-motion";
import LogoBizifestText from "../../components/logo/logo-bizifest-text";
import { paths } from "../../routes/paths";
import { useAuthContext } from "../../auth/hooks";
import { useResponsive } from "../../hooks/use-responsive";
import Logo from "../../components/logo";
import { bgGradient } from "../../theme/css";

const METHODS = [
  {
    id: "jwt",
    label: "Jwt",
    path: paths.auth.jwt.login,
    icon: "/assets/icons/auth/ic_jwt.svg",
  },
  {
    id: "auth0",
    label: "Auth0",
    path: paths.auth.auth0.login,
    icon: "/assets/icons/auth/ic_auth0.svg",
  },
];

type Props = {
  title?: string;
  isRegister: boolean;
  children: React.ReactNode;
};

export default function AuthClassicLayout({
  children,
  isRegister,
  title,
}: Props) {
  const { method } = useAuthContext();

  const theme = useTheme();
  const { t } = useTranslate();

  const mdUp = useResponsive("up", "md");
  const lgUp = useResponsive("up", "lg");

  const renderLogo = (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      paddingLeft={!lgUp ? 0 : 0}
      paddingRight={1.5}
      sx={{
        zIndex: 9,
        position: "absolute",
        ml: { xs: 2, md: 5 },
        mt: { xs: 2, md: 3 },
      }}
    >
      <Logo sx={{ mr: { xs: 2, md: 2.5 }, ml: { xs: 0.5, md: 0 } }} />
      <LogoBizifestText sx={{ mr: 2.5, mt: 0.5 }} />
    </Stack>
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === "light" ? 0.88 : 0.94,
          ),
          imgUrl: "/assets/background/overlay_2.jpg",
        }),
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: 480, textAlign: "center" }}>
        {!isRegister ? t("registerTitle") : t("welcomeBack2")}
      </Typography>

      <Box
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      >
        <m.div variants={varFade().in}>
          {isRegister ? (
            <Player
              src="/assets/lottie/register_animation.json"
              className="player"
              loop
              style={{ width: "60vh" }}
              autoplay
            />
          ) : (
            <Player
              src="/assets/lottie/login_animation.json"
              className="player"
              loop
              style={{ width: "60vh" }}
              autoplay
            />
          )}
        </m.div>
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: "100vh",
      }}
    >
      {renderLogo}

      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}
