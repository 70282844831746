import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import App from "./App";
import { GOOGLE_ANALYTIC_KEY } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
    <Helmet>
      {/* Google Analytics'ı yüklemek için gerekli olan script etiketi */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTIC_KEY}`}
      ></script>
      {/* Google Analytics'ı başlatmak için gerekli olan script etiketi */}
      {/* <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GOOGLE_ANALYTIC_KEY}');
        `}
            </script>*/}
    </Helmet>
  </HelmetProvider>,
);
