import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paths } from "../../../routes/paths";
import { RouterLink } from "../../../routes/components";
// assets
import { PasswordIcon } from "../../../assets/icons";
import Iconify from "../../../components/iconify";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { useTranslate } from "../../../locales";
import { useSnackbar } from "../../../components/snackbar";
import { useRouter } from "../../../routes/hook";
import axios, { endpoints } from "../../../utils/axios";
import {
  JWT_DIALOG_VIEW_FORM_NAMES,
  USER_EMAIL_MAX_CHARACTERS,
} from "../../../utils/constants/constants";
import { useResponsive } from "../../../hooks/use-responsive";
import { JwtDialogViewProps } from "../../../types/user";
import Button from "@mui/material/Button";
import React from "react";

export default function ModernForgotPasswordView({
  handleFormChange,
}: JwtDialogViewProps) {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const mdUp = useResponsive("up", "md");

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("emailValidMsg"))
      .max(USER_EMAIL_MAX_CHARACTERS, t("userMailMaxCharacter")),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      sessionStorage.setItem("email-recovery", data.email);
      const res = await axios.post(endpoints.auth.createNewPassword, data);
      if (res) {
        router.push(paths.auth.jwt.newPassword);
      }
    } catch (error: any) {
      const errMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
      enqueueSnackbar(errMsg, { variant: "error" });
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField name="email" label={t("email_address")} />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        {t("sendNewPassword")}
      </LoadingButton>

      {handleFormChange ? (
        <Button
          onClick={() => handleFormChange(JWT_DIALOG_VIEW_FORM_NAMES.LOGIN)}
        >
          {" "}
          {t("returnToSignIn")}{" "}
        </Button>
      ) : (
        <Link
          component={RouterLink}
          href={paths.auth.jwt.login}
          color="inherit"
          variant="subtitle2"
          sx={{
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          <Iconify icon="eva:arrow-ios-back-fill" width={16} />
          {t("returnToSignIn")}
        </Link>
      )}
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={3} sx={{ my: 5, textAlign: mdUp ? "inherit" : "center" }}>
        <Typography variant="h3">{t("forgotPassword")} </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("forgotPasswordDesc")}
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
