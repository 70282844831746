import { SxProps, Theme, useTheme } from "@mui/material/styles";
import Badge, { badgeClasses } from "@mui/material/Badge";
import { useSettingsContext } from "../../components/settings";
import { useTranslate } from "../../locales";
import BaseOptionsTheme from "../../components/settings/drawer/base-options-theme";
import React from "react";

type Props = {
  sx?: SxProps<Theme>;
  includeClickAction?: boolean;
  iconSize?: number;
};

//todo siteye f5 çekip yenileyince session düşmemeli
export default function SettingsThemeSwitchButton({
  sx,
  iconSize,
  includeClickAction = true,
}: Props) {
  const settings = useSettingsContext();
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const { t } = useTranslate();
  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!settings.canReset}
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: 8,
          right: 8,
        },
        ...sx,
      }}
    >
      <BaseOptionsTheme
        title="Mode"
        selected={settings.themeMode === "dark"}
        onClick={
          includeClickAction
            ? () =>
                settings.onUpdate(
                  "themeMode",
                  settings.themeMode === "dark" ? "light" : "dark",
                )
            : undefined
        }
        icons={["carbon:asleep", "carbon:asleep-filled"]}
        iconSize={iconSize}
      />
    </Badge>
  );
}
