import { m } from "framer-motion";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

import { ForbiddenIllustration } from "../../assets/illustrations";
import { useAuthContext } from "../hooks";
import { useTranslate } from "../../locales";
import { MotionContainer, varBounce } from "../../components/animate";

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
  sx,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuthContext();

  // const currentRole = 'user';
  const currentRole = user?.roleId; // admin;
  const { t } = useTranslate();

  if (typeof roles !== "undefined" && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container
        component={MotionContainer}
        sx={{ textAlign: "center", ...sx }}
      >
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {t("permissionDenied")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            {t("doNotHavePermission")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
