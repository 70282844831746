import { memo } from "react";
import { useTheme } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

//

function SeoIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 480 360"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <image
        href={
          isLight
            ? "/assets/images/notifications/notifications.png"
            : "/assets/images/notifications/notifications_dark.png"
        }
        height="300"
      />
    </Box>
  );
}

export default memo(SeoIllustration);
