import { useMemo } from "react";

import { paths } from "../../routes/paths";

import { useTranslate } from "../../locales";
import SvgColor from "../../components/svg-color";

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

export function useNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t("overview"),
        items: [
          /*todo coinler gelince açılacak
                                {
                                title: t('financialAnalysis'),
                                path: paths.dashboard.general.app,
                                icon: ICONS.ecommerce,
                                caption: t('financialAnalysisDesc'),
                                children: [
                                  {
                                    title: t('premiumAnalysis'),
                                    path: paths.dashboard.general.ecommerce,
                                  },
                                  {
                                    title: t('coinAnalysis'),
                                    path: paths.dashboard.general.banking,
                                  },
                                ],
                              },
                    */
          /*{ title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },*/
          /*{ title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },*/
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t("management"),
        items: [
          // User Actions
          {
            title: t("member"),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            caption: t("memberAdministrator"),
            children: [
              {
                title: t("createNewMember"),
                path: paths.dashboard.user.new,
                caption: t("createNewMemberDesc"),
              },
              {
                title: t("editMember"),
                path: paths.dashboard.user.demo.edit,
                caption: t("editMemberDesc"),
              },
              {
                title: t("membersList"),
                path: paths.dashboard.user.list,
                caption: t("membersListDesc"),
              },
              {
                title: t("memberStatistic"),
                path: "",
                caption: t("memberStatisticDesc"),
              },
            ],
          },
          // User Actions
          {
            title: t("blog"),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            caption: t("blogAdministrator"),
            children: [
              {
                title: t("createNewBlog"),
                path: paths.dashboard.blog.new,
                caption: t("createNewBlogDesc"),
              },
              {
                title: t("blogList"),
                path: paths.dashboard.blog.root,
                caption: t("blogListDesc"),
              },
            ],
          },
          // Support Actions
          {
            title: t("support"),
            path: paths.dashboard.sss.root,
            icon: ICONS.mail,
            caption: t("supportHelpDesc"),
            children: [
              { title: t("helpRequestList"), path: paths.dashboard.sss.list },
            ],
          },
          // Notification Actions
          /*
                    {
                      title: t('notifications'),
                      path: '',
                      icon: ICONS.mail,
                      caption: t('notificationPanelDesc'),
                      children: [
                        { title: t('notificationPanelSubTitle1'), path: '' },
                        { title: t('notificationPanelSubTitle2'), path: '' },
                        { title: t('notificationPanelSubTitle3'), path: '' },
                        { title: t('notificationPanelSubTitle4'), path: '' },
                      ],
                      info: <Label color="info">{t('soon')}</Label>,
                    },
                    // Category Actions
                    {
                      title: t('categories'),
                      path: '',
                      icon: ICONS.mail,
                      caption: t('categoriesDesc'),
                      children: [
                        { title: t('categoriesPanelTitle1'), path: '', caption: t('categoriesPanelDesc1') },
                        { title: t('categoriesPanelTitle2'), path: '', caption: t('categoriesPanelDesc2') },
                        { title: t('categoriesPanelTitle3'), path: '', caption: t('categoriesPanelDesc3') },
                        { title: t('categoriesPanelTitle4'), path: '', caption: t('categoriesPanelDesc4') },
                      ],
                      info: <Label color="info">{t('soon')}</Label>,
                    },
                      // Inviting Actions
                    {
                      title: t('inviting'),
                      path: '',
                      icon: ICONS.mail,
                      caption: t('invitingDesc'),
                      children: [
                        { title: t('invitingPanelTitle1'), path: '', caption: t('invitingPanelDesc1') },
                        { title: t('invitingPanelTitle2'), path: '', caption: t('invitingPanelDesc2') },
                        { title: t('invitingPanelTitle3'), path: '', caption: t('invitingPanelDesc3') },
                        { title: t('invitingPanelTitle4'), path: '', caption: t('invitingPanelDesc4') },
                      ],
                      info: <Label color="info">{t('soon')}</Label>,
                    },
          */

          // PRODUCT
          /* {
                                title: t('product'),
                                path: paths.dashboard.product.root,
                                icon: ICONS.product,
                                children: [
                                  { title: t('list'), path: paths.dashboard.product.root },
                                  { title: t('details'), path: paths.dashboard.product.demo.details },
                                  { title: t('create'), path: paths.dashboard.product.new },
                                  { title: t('edit'), path: paths.dashboard.product.demo.edit },
                                ],
                              },
                    */
          // ORDER todo coinler gelince açılacak
          /*  {
                                title: t('order'),
                                path: paths.dashboard.order.root,
                                icon: ICONS.order,
                                caption: t('orderDescription'),
                                children: [
                                  { title: t('coinOrderList'), path: paths.dashboard.order.root },
                                  { title: t('premiumOrderList'), path: paths.dashboard.order.root },
                                  /!*  { title: t('details'), path: paths.dashboard.order.demo.details },*!/
                                ],
                              },
                    */
          // REPORT
          {
            title: t("reportAndPunishment"),
            path: paths.dashboard.report.root,
            icon: ICONS.order,
            caption: t("Rapor ve Cezalar Burada"),
            children: [
              {
                title: t("report"),
                path: paths.dashboard.report.root,
                caption: t("reportDesc"),
              },
              {
                title: t("punishment"),
                path: paths.dashboard.report.punishment,
                caption: t("punishmentDesc"),
              },
            ],
          },

          {
            title: t("badge"),
            path: paths.badge.root,
            icon: ICONS.order,
            caption: t("badgeCaption"),
            children: [
              {
                title: t("createNewBadge"),
                path: paths.badge.new,
                caption: t("createNewBadgeCaption"),
              },
              {
                title: t("badgeList"),
                path: paths.badge.list,
                caption: t("badgeListCaption"),
              },
            ],
          },

          {
            title: t("category"),
            path: paths.category.list,
            icon: ICONS.order,
            caption: t("categoryCaption"),
            children: [
              {
                title: t("createNewCategory"),
                path: paths.category.new,
                caption: t("createNewCategoryCaption"),
              },
              {
                title: t("categoryList"),
                path: paths.category.list,
                caption: t("categoryListCaption"),
              },
            ],
          },
          {
            title: t("systemNotification"),
            path: paths.systemNotification.list,
            icon: ICONS.order,
            caption: t("systemNotificationCaption"),
            children: [
              {
                title: t("createNewSystemNotification"),
                path: paths.systemNotification.new,
                caption: t("createNewSystemNotificationCaption"),
              },
              {
                title: t("systemNotificationList"),
                path: paths.systemNotification.list,
                caption: t("systemNotificationListCaption"),
              },
            ],
          },
          {
            title: t("inviteReceiptTitle"),
            path: paths.dashboard.receipt.root,
            icon: ICONS.order,
            caption: t("inviteReceiptDesc"),
            children: [
              {
                title: t("inviteNewReceiptTitle"),
                path: paths.dashboard.receipt.new,
                caption: t("inviteNewReceiptDesc"),
              },
              {
                title: t("inviteReceiptListTitle"),
                path: paths.dashboard.receipt.root,
                caption: t("inviteReceiptListDesc"),
              },
              {
                title: t("receiptStatisticTitle"),
                path: paths.dashboard.receipt.statistic,
                caption: t("receiptStatisticDesc"),
              },
            ],
          },

          // INVOICE todo coinler gelince açılacak
          /*   {
                                title: t('receipt'),
                                path: paths.dashboard.receipt.root,
                                icon: ICONS.receipt,
                                caption: t('invoiceDescription'),
                                children: [
                                  { title: t('list'), path: paths.dashboard.receipt.root },
                                  { title: t('details'), path: paths.dashboard.receipt.demo.details },
                                  { title: t('create'), path: paths.dashboard.receipt.new },
                                  { title: t('edit'), path: paths.dashboard.receipt.demo.edit },
                                ],
                              },
                    */
          // BLOG
          /* {
                                title: t('blog'),
                                path: paths.dashboard.post.root,
                                icon: ICONS.blog,
                                children: [
                                  { title: t('list'), path: paths.dashboard.post.root },
                                  { title: t('details'), path: paths.dashboard.post.demo.details },
                                  { title: t('create'), path: paths.dashboard.post.new },
                                  { title: t('edit'), path: paths.dashboard.post.demo.edit },
                                ],
                              },*/

          // TRADE
          /*     {
                                title: t('commercial'),
                                path: paths.dashboard.trade.root,
                                icon: ICONS.job,
                                children: [{ title: t('myTradePosts'), path: paths.dashboard.trade.root }],
                              },*/

          // JOB
          /* {
                                title: t('job'),
                                path: paths.dashboard.job.root,
                                icon: ICONS.job,
                                children: [
                                  { title: t('list'), path: paths.dashboard.job.root },
                                  { title: t('details'), path: paths.dashboard.job.demo.details },
                                  { title: t('create'), path: paths.dashboard.job.new },
                                  { title: t('edit'), path: paths.dashboard.job.demo.edit },
                                ],
                              },
                    */
          // TOUR
          /* {
                                title: t('tour'),
                                path: paths.dashboard.tour.root,
                                icon: ICONS.tour,
                                children: [
                                  { title: t('list'), path: paths.dashboard.tour.root },
                                  { title: t('details'), path: paths.dashboard.tour.demo.details },
                                  { title: t('create'), path: paths.dashboard.tour.new },
                                  { title: t('edit'), path: paths.dashboard.tour.demo.edit },
                                ],
                              },
                    */
          // FILE MANAGER
          /*  {
                                title: t('file_manager'),
                                path: paths.dashboard.fileManager,
                                icon: ICONS.folder,
                              },*/

          // MAIL
          /*     {
                                title: t('mail'),
                                path: paths.dashboard.mail,
                                icon: ICONS.mail,
                                info: <Label color="error">+32</Label>,
                              },*/

          // CHAT
          /*   {
                                title: t('chat'),
                                path: paths.dashboard.chat,
                                icon: ICONS.chat,
                              },*/
          /*
                              {
                                title: t('badges'),
                                path: paths.dashboard.badges,
                                icon: ICONS.chat,
                              },*/

          // CALENDAR
          /* {
                                title: t('calendar'),
                                path: paths.dashboard.calendar,
                                icon: ICONS.calendar,
                              },*/

          // KANBAN
          /*  {
                                title: t('kanban'),
                                path: paths.dashboard.kanban,
                                icon: ICONS.kanban,
                              },*/
        ],
      },

      // DEMO MENU STATES
      /*{
                    subheader: t(t('other_cases')),
                    items: [
                      {
                        // default roles : All roles can see this entry.
                        // roles: ['user'] Only users can see this item.
                        // roles: ['admin'] Only admin can see this item.
                        // roles: ['admin', 'manager'] Only admin/manager can see this item.
                        // Reference from '../guards/RoleBasedGuard'.
                        title: t('item_by_roles'),
                        path: paths.dashboard.permission,
                        icon: ICONS.lock,
                        roles: ['admin', 'manager'],
                        caption: t('only_admin_can_see_this_item'),
                      },
                      {
                        title: t('menu_level'),
                        path: '#/dashboard/menu_level',
                        icon: ICONS.menuItem,
                        children: [
                          {
                            title: t('menu_level_1a'),
                            path: '#/dashboard/menu_level/menu_level_1a',
                          },
                          {
                            title: t('menu_level_1b'),
                            path: '#/dashboard/menu_level/menu_level_1b',
                            children: [
                              {
                                title: t('menu_level_2a'),
                                path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
                              },
                              {
                                title: t('menu_level_2b'),
                                path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
                                children: [
                                  {
                                    title: t('menu_level_3a'),
                                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
                                  },
                                  {
                                    title: t('menu_level_3b'),
                                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: t('item_disabled'),
                        path: '#disabled',
                        icon: ICONS.disabled,
                        disabled: true,
                      },
                      {
                        title: t('item_label'),
                        path: '#label',
                        icon: ICONS.label,
                        info: (
                          <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                            NEW
                          </Label>
                        ),
                      },
                      {
                        title: t('item_caption'),
                        path: '#caption',
                        icon: ICONS.menuItem,
                        caption:
                          'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
                      },
                      {
                        title: t('item_external_link'),
                        path: 'https://www.google.com/',
                        icon: ICONS.external,
                      },
                      {
                        title: t('blank'),
                        path: paths.dashboard.blank,
                        icon: ICONS.blank,
                      },
                    ],
                  },*/
    ],
    [t],
  );

  return data;
}
